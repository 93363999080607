//韩语
export default {
	"830753-0": "충전",
	"830753-1": "자산을 빠르게 충전",
	"830753-2": "증빙서류를 업로드해주세요",
	"830753-3": "충전 확인",
	"830753-4": "서버 응답 데이터가 올바르지 않습니다:",
	"830753-5": "이미지 업로드에 실패했습니다:",
	account: {
		Start_making_money_plan: "돈 버는 계획을 시작하세요",
		Market: "시장",
		Migital_currency: "미지탈 화폐",
		Foreign_exchange: "외환",
		Noble_metal: "귀금속",
		Send_Cryop_Now: "지금 Cryop 보내기",
		Send: "보내다",
		Receive: "받다",
		Buy: "구입하다",
		Currency_account: "통화 계정",
		Contract_account: "계약 계정",
		Transfer: "옮기다",
		Select_a_wallet: "지갑을 선택하세요",
		Select_Coin: "동전 선택",
		Confirm: "확인하다",
		Wallet: "지갑",
		Availavle: "사용 가능",
		Frozen: "겨울 왕국",
		Convert: "전환하다",
		Receiving_Address: "수신 주소",
		Amount: "양",
		Max: "맥스",
		Send_Now: "지금 보내",
		Please_check_if: "자산 손실을 방지하려면 보내기 전에 배송 주소가 올바른지 확인하십시오.",
		new1:'암호화폐',
		new2:'색인',
		new3:'대부분',
		new4:'외환',
		new5:'총자산',
	},
	"314962-0": "Google 2FA",
  "314962-1": "Google Authenticator를 다운로드하여 연결하세요",
  "314962-2": "인증 코드 복사",
  "314962-3": "인증 코드 입력",
  "314962-4": "요청 실패:",
  "314962-5": "인증 코드가 복사되었습니다",
  "314962-6": "텍스트를 복사할 수 없습니다:",
  "314962-7": "복사 실패, 다시 시도해 주세요",
  "199917-0": "설정",
  "199917-1": "알림",
  "199917-2": "Google 2FA",
  "199917-3": "이메일",
  "199917-4": "언어",
  "199917-5": "버전",
  "199917-6": "로그아웃",
  "516529-0": "계정 설정",
  "516529-1": "계정 정보 이해하기",
  "516529-2": "온라인 지원",
  "516529-3": "어플리케이션 공유하기",
  "516529-4": "기본 설정",
  "516529-5": "이메일 설정",
  "516529-6": "언어 변경",
  "516529-7": "가격 알림",
  "516529-8": "법률 및 정보",
  "516529-9": "서비스 이용약관",
  "516529-10": "세탁방지 정책",
  "516529-11": "쿠키 정책",
  "516529-12": "도움과 답변 얻기",
  "516529-13": "시작하기",
  "516529-14": "토큰 교환",
  "516529-15": "문제 해결",
  "516529-16": "친구에게 추천하기",
  "516529-17": "친구와 공유하고 추천 수수료 받기",
  "516529-18": "링크 복사하기",
  "516529-19": "시장 업데이트를 받기 위해 이메일 설정하기",
  "516529-20": "즉시 확인하기",
  "516529-21": "이메일 입력...",
  "516529-22": "확인 코드 입력...",
  "516529-23": "구독 이메일은 오직 우리의 서비스와 관련된 유용한 정보나 안내를 받기 위한 것일 뿐, 남용되지 않습니다!",
  "516529-24": "저장",
  "516529-25": "웹사이트 언어 선택",
  "516529-26": "데이터 보안과 개인 정보 보호에 최선을 다하고 있습니다",
  "516529-27": "중국어 전통",
  "516529-28": "일본어",
  "516529-29": "확인 코드 보내기",
  "516529-30": "성공적으로 복사되었습니다!",
  "516529-31": "다시 보내기",
    home:{
        USDTAccount: 'USDT 계좌',
        USTD: 'USDT',
        USDT: 'USDT',
        USTC: 'USDC',
        open: '승인하다',
        ETHTodayProfit: '오늘 ETH 수익',
        totalRevenue: '총 수익',
        portfolio: '투자하다',
        reliableSecurity: '믿을 수 있는 보안 보장',
        stableReliable: '안정적이고 믿음직한 투자',
        convenienEasy: '편리하고 쉬운 조작',
        pleaseLookForward: '기대해주세요...',
        rateOfReturn: '응답률',
		walletnot: 'MetaMask 또는 Ethereum 확장 지갑을 설치하세요.',
		wallettip: '경험에 영향을 미치지 않도록 주소를 연결하십시오.',
    },
	"ConnectW":"지갑 연결",
	"dappbrowser":"이 웹사이트는 분산형 지갑 DAPP 브라우저에서의 액세스만 허용합니다.",
    "Coins POS": "CoinB Pos",
    "9dcf43fa47": "9dcf43fa47",
    "Earn Interest": "이자를 벌다",
    "$": "$",
    "ETH Today's Profit": "오늘 ETH 수익",
    "Wallet": "지갑",
    "Record": "주문하다",
    "Support": "고객 서비스",
    "General": "포괄적인",
    "Notifications": "통지하다",
    "Invite Friends": "친구를 초대",
    "FAQ": "일반적인 문제",
    "Privacy & Security": "개인 정보 보호 및 보안",
    "Legality": "개인 정보 정책",
	"Authenticator":"확인됨",
	"Settings":"설정",
	"Change Language":"언어 변경",
	"Daytime mode":"주간 모드",
	"Black mode":"야간 모드",
    "BTC": "BTC",
    "Bitcoin": "Bitcoin",
    "ETH": "ETH",
    "ethereum": "ethereum",
    "USDT": "USDT",
    "tether": "tether",
    "USDC": "USDC",
    "usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'열리는',
    'High':'제일 높은',
    'Low':'가장 낮은',
    'Close':'닫은',
    'Up':'구매',
    'Down':'구매 또는 판매',
    'Time':'시간',
    'TransactionFee':'수수료',
    'AvailableBalance':'사용 가능한 잔액',
    'submit':'신청',
    'Direction':'방향',
    'Profit':'이익',
    'Quantity':'수량',
    'Price':'가격',
    'different':'최소 한도를 보려면 다양한 척도를 클릭하세요.',
    'lessthan':'적어도 이상',
    'least':'최소:',
    'Transactionhistory':'거래기록',
    'Transactionrecords':'거래기록',
    'Number':'수량',
    'Profitloss':'이익의 손실',
	'Ror':'반품',
	'operate':'작동하다',





    //钱包
    TransactiónDetails:'상세 거래 내역',
	dealdetails:'상세 거래 내역',
    ViewTransactionHistory:'거래 내역 보기',
    Deposit:'충전하다',
    Rechargeaddress:'충전 주소:',
    DepositAmount:'입금 금액:',
    EnterDepositAmount:'입금액을 입력하세요',
    EnterHash:'해시 값을 입력하세요',
    Hash:'해시 값:',
    Voucherimage:'바우처 이미지:',
    Continue:'계속하다',
    Withdraw:'철회하다',
    Withdrawal:'검색하다:',
    EnterAmounttoWithdraw:'인출금액을 입력하세요',
    Available:"쓸 수 있는:",
    ReceiveAddress:'수신 주소:',
    EnterWalletAddress:'지갑 주소를 입력하세요',
    WithdrawalDesc:'영어로 출금하려면 1%의 처리 수수료가 필요하며 입금되기 전에 네트워크 노드의 확인이 필요합니다. 암호화폐를 낯선 사람에게 양도하지 마십시오.',
    Exchange:'교환',
    Send:'순간이동:',
    Max:'최고:',
    rechargeAmount:'충전금액을 입력해주세요',
    hashcode:'해시 값과 스크린샷은 동시에 비워둘 수 없습니다.',
    correcthashcode:'올바른 해시 값을 입력하세요.',
    maximumwithdrawalamount:'최대 인출 금액은',
    Incorrectamountformat:'금액 형식이 잘못되었습니다.',
	copy:'복사',


	//tab2
	"Total Profit":"총 이익",
	"Today's Profit":"오늘의 수익",
	"What is Automated Trading?":"자동거래란 무엇인가요?",
	"Learn about earning":"소득에 대해 알아보기",
	"Staking Period":"공약기간",
	"Staking Amount":"약속 수량",
	"Yield":"혜택",
	"Limit":"한계",
	"Subscribe":"신청",

	//tab3
	"Proof of Stake":"지분 증명",
	"ETH Today is Price":"ETH 오늘 가격",
	"ETH Today's Increase":"오늘 ETH의 상승",
	"Joint Staking":"공동서약",
	"Individual Staking":"개인 서약",
	"tab3long1":"참가자들은 스마트 계약을 통해 짝을 이루어 공동 베팅을 실현하며 총 베팅 금액은 32 ETH에 이릅니다.",
	"tab3long2":"독립적인 베팅에서는 참가자를 일치시키기 위해 스마트 계약이 필요하지 않습니다.  개인 배팅은 배팅 금액이 32ETH에 도달하면 완료될 수 있습니다.",


	//web_view
	"webviewlong1":"암호화폐 에어드랍은 신흥 블록체인 기반 프로젝트의 성장을 돕습니다.  그들의 뿌리는 보상과 공유 목표를 통해 커뮤니티 구성원을 연결합니다.  성공적인 에어드랍의 결과는 더욱 강력한 커뮤니티, 더 나은 프로젝트, 최종 사용자를 위한 보상입니다.  수년에 걸쳐 우리는 작업 증명(POW) 블록체인에서 수행되는 많은 에어드롭을 보아왔습니다.  지분 증명(POS) 이더리움 체인은 이제 수십 개의 네트워크에 걸쳐 수백 개의 프로젝트에 대한 암호화폐 에어드랍을 지원합니다.  PoS가 지배적인 합의 패러다임이 되면서 이더리움 네트워크의 에어드롭 수는 줄어들 기미를 보이지 않습니다.",
	"webviewlong2":"암호화폐를 스테이킹하면 스테이킹한 암호화폐의 양에 따라 귀중한 에어드랍 보상을 받을 수 있습니다.",
	"webviewlong3":"보상 지분은 지분 보유자에게만 제공되며 수백 개의 다양한 프로젝트 에어드롭에서 나오며 단일 독립 에어드롭보다 수익률이 훨씬 높습니다.",
	"webviewlong4":"거래소에 입금하는 암호화폐 소유자에게는 에어드랍 보상이 어려운 경우가 많으며, 일부 관리인은 에어드랍을 전혀 지원하지 않습니다.  이것이 바로 자신의 암호화폐를 관리하는 것이 중요한 이유입니다.  이더리움은 비수탁 실체이며 모든 주요 네트워크에 스테이킹될 수 있습니다.",
	"Introduction":"소개하다",
	"webviewlong5":"대표적인 블록체인 플랫폼인 이더리움은 이더리움 2.0 업그레이드를 통해 작업 증명(PoW) 합의 메커니즘에서 지분 증명(PoS) 메커니즘으로 전환하고 있습니다. PoS 스테이킹은 이러한 변화의 중요한 부분으로, 사용자에게 잠재적으로 보상을 획득하면서 네트워크 보안 및 운영을 지원할 수 있는 기회를 제공합니다.  이 글은 이더리움 PoS 스테이킹과 그 이점에 대한 개요를 제공합니다.",
	"Ethereum 2.0 and Proof of Stake (PoS)":"이더리움 2.0과 지분증명(PoS)",
	"webviewlong6":'Ethereum 2.0은 확장성, 보안 및 탄력성을 향상하도록 설계된 주요 네트워크 업그레이드입니다.  주요 변화 중 하나는 에너지 집약적인 PoW 합의 메커니즘에서 보다 환경 친화적인 PoS 메커니즘으로의 전환입니다.  PoS에서는 검증인이 선택되어 새로운 블록을 생성하고 자신이 보유하고 있는 암호화폐의 양을 기반으로 거래를 확인하며 담보로 "스테이킹"할 의향이 있습니다.',
	"The Staking Process":"서약 절차",
	"webviewlong7":"이더리움 PoS 스테이킹에 참여하려면 사용자는 이더리움 2.0 예금 계약에 예치하여 최소 32 ETH를 스테이킹해야 합니다.  일단 스테이킹된 ETH는 일정 기간 동안 잠겨 있으며 네트워크 보안을 보장하기 위한 담보 역할을 합니다.  검증인은 스테이킹된 ETH 금액과 온라인 활동을 기준으로 선택됩니다.  그들은 새로운 블록을 제안하고 검증하는 것뿐만 아니라 네트워크에서 거래를 확인하는 일을 담당합니다.",
	"Staking Rewards and Risks":"스테이킹 보상 및 위험",
	"webviewlong8":"PoS 스테이킹에 참여함으로써 사용자는 새로 발행된 ETH와 거래 수수료 형태로 보상을 받을 수 있습니다.  보상은 스테이킹된 ETH의 양과 전체 네트워크 활동을 기반으로 합니다.  그러나 스테이킹에는 검증인이 악의적으로 행동하거나 지속적으로 온라인 상태를 유지하지 못할 경우 처벌을 받을 수 있는 등의 위험이 따릅니다.  또한 스테이킹된 ETH는 일정 기간 동안 잠겨 있어 비유동성이고 가격 변동에 취약합니다.",
	"Joint Staking and Individual Staking":"공동서약과 개별서약",
	"webviewlong9":"개별적으로 스테이킹할 만큼 32 ETH가 부족한 사용자에게는 공동 스테이킹이 또 다른 옵션을 제공합니다.  공동 스테이킹에서는 여러 사용자가 ETH를 함께 모아 필요한 스테이킹 보증금에 도달합니다.  이러한 풀링은 스마트 계약이나 스테이킹 서비스를 통해 촉진되는 경우가 많으므로 사용자는 더 적은 양의 ETH로 이더리움 PoS 스테이킹에 참여할 수 있습니다.",
	"Conclusion":"결론적으로",
	"webviewlong10":"이더리움 PoS 스테이킹은 이더리움 2.0 업그레이드의 중요한 측면으로, 사용자에게 네트워크 보안을 지원하고 분산화를 개선하며 보상을 받을 수 있는 기회를 제공합니다.  스테이킹 프로세스, 보상 및 위험을 이해함으로써 사용자는 이더리움 네트워크의 중요한 발전에 참여하는 것에 대해 정보에 입각한 결정을 내릴 수 있습니다.",


	//mywallet
	"Total":"일시금",

	//record
	"Account":"계정",
	"interest-bearing":"관심",
	"POS Staking":"POS 서약",
	"convert":"교환",
	"transaction":"거래",
	"pledge":"저당",
	"recordno":"당신은 아직 아무것도 듣지 못했습니다.  목록이 비어 있습니다.",
	"Oops":"이런",
	"recharge":"충전하다",
	"payment":"지불하다",
	"staking":"약속",
	"income":"소득",
	"Pledge quantity":"약속 수량",
	"cycle":"주기",
	"Days Remaining":"남은 일수",
	"cumulative gain":"누적 이득",
	"Price increase":"가격 인상",
	"Price decrease":"가격 하락",
	"Opening price":"개장 가격",
	"Closing price":"종가",
	"loss":"손실",
	"pfofit":"이익",
	"amount":"수량",

	//notifiCations
	"notifiCationslong1":"참고: VIP 등록은 해당 전체 계정의 저장된 가치에 따라 레벨을 결정합니다.  이 범위에서는 기존 계좌에서 탈퇴 후 총 보유금액을 차감하여 회원등급을 결정합니다.",
	"notifiCationslong2":"회원가입 기간도 등급에 따라 구분됩니다.  멤버십을 유지하려면 지정된 시간 내에 매번 최소 5,000 USDT를 충전해야 멤버십 혜택을 누릴 수 있습니다.  멤버십을 충전할 때마다 주기가 ​​다시 계산되어 누적될 수 있습니다.",
	"notifiCationslong3":"고객 서비스는 회원 등급에 따라 구분됩니다.  공식 고객 서비스는 회원들에게 통일된 서비스를 제공하기 위해 APP 직원이 제공합니다.  더 나은 서비스 제공을 위해 전담 비서의 수를 제한하고 있습니다.  전담 개인 비서가 사용자에게 전담 리셉션 서비스와 재무 관리 조언을 제공합니다.",
	"notifiCationslong4":"충전 예약을 위해 사전에 고객센터에 문의하세요.  지정된 시간 내에 충전을 완료하시면, 해당 멤버십 등급에 따라 추가 예약 충전 보상을 받으실 수 있습니다.",
	"notifiCationslong5":"VIP1: UID 계정 기준으로 누적 금액이 10,000 USD일 경우 177 USDT 보상을 받을 수 있습니다.  VIP 레벨에 도달하고 일반 회원 메달을 획득한 후 포인트가 계정에 에어드롭됩니다.",
	"notifiCationslong6":"VIP2: UID 계정을 기준으로 누적 저장 가치가 30,000 USD에 도달하면 777 USDT를 받게 됩니다.  보너스 금액은 VIP 레벨에 도달하고 브론즈 멤버십 배지를 받은 후 즉시 계정에 입금됩니다.",
	"notifiCationslong7":"VIP3: UID 계정에 따르면 누적 금액이 70,000 US 달러인 경우 1,777 USDT를 받게 됩니다.  VIP 레벨에 도달하고 실버 회원 메달을 받은 후 포인트가 계정에 에어드롭됩니다.  또한 다음 예약 금액에 대해 추가로 1.5% 보너스를 받으실 수 있습니다.",
	"notifiCationslong8":"VIP4: UID 계정에 따르면 누적 저장 가치는 150,000 US 달러이며 2,777 USDT가 지급됩니다.  VIP 레벨에 도달하고 골드 회원 메달을 받은 후 포인트가 계정에 에어드롭됩니다.  또한 다음 예약 시 충전 금액에 대해 추가로 2% 보너스를 받으실 수 있습니다.",
	"notifiCationslong9":"VIP5: UID 계정을 기준으로 누적 저장 가치가 300,000 USD에 도달하면 4777 USDT를 선물로 제공합니다.  VIP 레벨에 도달하고 다이아몬드 VIP 배지를 받은 후 포인트가 계정에 에어드랍됩니다.  또한 다음 예약 시 저장된 금액에 대해 추가로 2.5% 보너스를 받으실 수 있습니다.",
	"notifiCationslong10":"VIP6: UID 계정을 기준으로 누적 저장 가치는 800,000 USD에 도달하고 16,888 USDT를 선물로 제공합니다.  보너스 금액은 VIP 레벨 달성 및 블랙다이아몬드 멤버십 뱃지 획득 후 즉시 계정으로 에어드랍되며, 다음 사전등록 충전금액의 3%를 추가로 적립해 드립니다.  재정적 조언을 제공하거나 세금 문제를 처리하는 전담 개인 비서를 갖게 됩니다.",
	"notifiCationslong11":"VIP7: UID 계정 기준, 누적 보관 금액이 US$3백만에 도달하면 15일간의 유럽 럭셔리 여행(할인 포함)을 제공합니다.  코인비포스(CoinBpos) 글로벌 평생 명예 VIP상을 수여하고 크라운 멤버십 메달을 받았습니다.  다음 예약 충전시 3.5% 추가 보너스를 받으실 수 있습니다.",
	"notifiCationslong12":"VIP8: UID 계정에 따르면 누적 저장 금액이 미화 800만 달러에 도달하면 CoinBpos 모로코 연간 연회 상품권을 받을 수 있습니다.  보상: 1 BTC 및 CoinBpos 글로벌 평생 명예 VIP를 받으세요.  다음 예약 충전 시 추가 4% 보너스를 받으실 수 있습니다.",
	"notifiCationslong13":"VIP9: UID 계정 기준으로 총 3천만 달러를 입금하면 CoinBpos 지분 3%를 획득할 수 있습니다.  그리고 연간 3%의 이익 배당금을 누리세요.  무료 Cronix 순금 기념 핀.",

	//invite
	"Refer and Earn":"추천하고 적립하세요",
	"Copy Link":"링크 복사",

	//faq
	"Frequently Asked Questions":"자주 묻는 질문",

	//authenticator
	"mailbox":"우편",
	"Front photo":"초상화 사진",
	"Back photo":"뒷면 사진",
	"Handheld ID photo":"증명사진을 들고 있는 모습",
	"define":"확신하는",

	//legality
	"legalitylong1":"Coinbpos는 암호화폐 커뮤니티가 커뮤니티 안정성을 생성, 성장 및 유지하는 데 도움이 되는 도구를 함께 제공하고 수천 개의 분산형 애플리케이션을 지원하며 암호화폐의 미래를 주도하고 전통 통화의 디지털화 및 글로벌 경제 촉진을 촉진하는 여러 암호화폐 회사로 구성됩니다. 번영.",
	"legalitylong2":"Coinbpos 내의 각 회사는 서비스와 관련하여 개인 데이터 처리를 위한 데이터 컨트롤러 역할을 하며, 각 서비스의 데이터 컨트롤러는 다음과 같습니다.",
	"legalitylong3":"1. 블록체인 기술 - 이더리움 블록체인.",
	"legalitylong4":"2. 탈중앙화 지갑 - 트러스트 지갑, 메타마스크, 암호화폐 지갑.",
	"legalitylong5":"3. 거래 기술 - Coinbase, Crypto, linch, Binance.",
	"legalitylong6":"개인 정보 보호 정책은 유럽 연합의 일반 데이터 보호 규정(GDPR)을 포함한 관련 데이터 보호법에 따른 의무에 따라 Coinbpos가 개인 데이터를 처리하는 방법을 자세히 설명합니다.",
	"legalitylong7":'캘리포니아 소비자 개인 정보 보호법(CCPA) 및 기타 일반 법률(통칭하여 "데이터 보호법"*).',

	//language
	"language":"언어",
	"Confirm":"확인하다",

	"newtext1":"그만두다",
	"newtext2":"취소",
	"newtext3":"정말로 이 계정에서 로그아웃하시겠습니까?",

	"newtext4":"中文简体",
	"newtext5":"中文繁体",
	"newtext6":"English",
	"newtext7":"日本語",

	"newtext8":"로그인",
	"newtext9":"사용자 이름",
	"newtext10":"전화 번호",
	"newtext11":"사용자 이름을 입력하세요",
	"newtext12":"전화번호를 입력해주세요",
	"newtext13":"비밀번호",
	"newtext14":"비밀번호를 입력 해주세요",
	"newtext15":"아직 계정이 없습니다",
	"newtext16":"등록하다",
	"newtext17":"국가",
	"newtext18":"국가를 선택하세요",
	"newtext19":"휴대폰번호 인증코드",
	"newtext20":"인증번호를 입력해주세요",
	"newtext21":"이메일 주소",
	"newtext22":"이메일 주소를 입력해주세요",
	"newtext23":"비밀번호 확인",
	"newtext24":"확인 비밀번호를 입력해주세요",
	"newtext25":"계정을 갖고있다",
	"newtext26":"인증 코드 보내기",
	"newtext27":"휴대폰 번호가 올바르지 않습니다.",
	"newtext28":"성공적으로 보냈습니다",
	"newtext29":"등록 성공",

	"newtext30":'통화',
	"newtext31":'외환',
	"newtext32":'귀금속',

	"newtext33":'계정',
	"newtext34":'플랫폼 활동',
	"newtext35":'고객 서비스',
	"newtext36":'초대',
	"newtext37":'KYC 인증',
	"newtext38":'설정',
	wallet_p:{
		text1: "주소 복사",
		text2: '확인',
		text3: '교환 시작',
		text4: "교환 대상",
		text5: "최대",
		text6: "초대하기",
		text7: '추천 보상 받기',
		text8: "당신의 친구들이 AI 중재에 참여하고 마이닝 머신을 대여할 때, 당신은 추천 보상을 받을 수 있습니다",
		text9: "복사",
		text10: '링크 공유',
		text11: "KYC 인증",
		text12: "국가",
		text13: "국가를 선택하세요",
		text14: "이름",
		text15: "당신의 이름을 입력하세요",
		text16: "성",
		text17: "당신의 성을 입력하세요",
		text18: "증명서 유형",
		text19: "증명서 유형을 선택하세요",
		text20: "증명서 번호",
		text21: "증명서 번호를 입력하세요",
		text22: "당신의 신분증의 전면 사진을 업로드하세요",
		text23: "당신의 신분증의 뒷면 사진을 업로드하세요",
		text24: "당신의 여권의 전면 사진을 업로드하세요",
		text25: "당신의 여권의 뒷면 사진을 업로드하세요",
		text26: "이메일",
		text27: "당신의 이메일을 입력하세요",
		text28: "전송",
		text29: "인증 코드",
		text30: "당신의 인증 코드를 입력하세요",
		text31: "신분증",
		text32: "여권",
		text33: "취소",
		text34: "국가/지역",
		text35: "인증을 위해 올바른 정보를 입력하세요",
		text36: "성공적으로 보냈습니다!",
		text37: "이메일 형식 오류!",
		text38: "운전면허증",
		text39: "운전면허증 앞면 사진을 업로드해주세요.",
		text40: "운전면허증 뒷면 사진을 업로드해주세요.",
		
		text41: "ID 번호",
		text42: "귀하의 ID 번호를 입력해주세요",
		text43: "여권번호",
		text44: "여권번호를 입력해주세요",
		text45: "운전면허증 번호",
		text46: "운전면허번호를 입력해주세요",
	},
	jiaoyi:{
		text1:'두 번째 계약',
		text2:'영구 계약',
		text3:'현물 거래',
		text4:'즉시 위임',
		text5:'계정 유형',
		text6:'배달 시간',
		text7:'가격 범위',
		text8:'구매 가격',
		text9:'비용',
		text10:'균형',
		text11:'적어도',
		text12:'예상',
		text13:'구매 금액',
		text14:'방향',
		text15:'구매 가격',
		text16:'실시간 가격',
		text17:'카운트다운',
		text18:'내 계약서',
		text19:'이익',
		text20:'계약',
		text21:'배송비',
		text22:'배달 시간',
		text23:'시장 가격',
		text24:'상승',
		text25:'가을',
		text26:'구매 가격',
		text27:'최대',
		text28:'이익',
		text29:'취급 수수료',
		text30:'구매',
		text31:'판매',
		text32:'구매 수량',
		text33:'실제 계정',
		text34:'가상계좌',
		text35:'아직 데이터가 없습니다',
		text36:'현재 위치',
		text37:'역사적 위원회',
		text38:'포지션 닫기',
		text39:'여백',
		text40:'손익',
		text41:'보류',
		text42:'구매 기록',
		text43:'판매 기록',
		text44:'사용 가능',
		text45:'변환됨',
		text46:'인증 성공',
		text47:'구매시간',
		text48:'읽히지 않는',
		text49:'읽다',
		text50:'호스트 순서',
		text51:'전체 차익거래',
		text58:'오늘의 수입',
		text52:'인공지능 로봇이 작동하는 방식',
		text53:'차익거래 상품',
		text54:'일',
		text55:'금액',
		text56:'일일 수입',
		text57:'통화 유형',
		text59:'AI 차익거래에 참여하세요',
		text60:'위험 없음, 빠른 수익',
		text61:'AI 차익거래',
		text62:'예상 수입',
		text63:'차익거래 코인 유형',
		text64:'에스크로 금액',
		text65:'지금 호스트하세요',
		text66:'USDT 지갑으로 일일 수입이 전송됩니다',
		text67:'투자 펀드 제로 리스크',
		text68:'언제든지 자금을 인출할 수 있습니다.',
		text69:'인공지능은 하루 24시간 작동합니다',
		text70:'누적 수입',
		text71:'만료 시간',
		text72:'모두',
		text73:'진행 중',
		text74:'완료',
		text75:'호스팅 세부정보',
		text76:'통화 선택',
		text77:'기술지원',
		text78:'이메일 보내기',
		text79:'거래 유형',
		
		text80:'로드 중...',
		text81:'더 이상',
		text82:'백서',
	},
	
	swapadd:{
		text1:'쿠키 정책',
		text2:'우리는 귀하의 데이터 보안과 개인정보를 보호하기 위해 최선을 다하고 있습니다.',
		text3:'소개',
		text4:'이 쿠키 정책은 당사의 분산형 거래소가 쿠키 및 유사한 추적 기술을 사용하는 방법을 설명합니다. Exchange에 액세스하거나 이를 사용함으로써 귀하는 본 정책에 설명된 대로 쿠키 사용에 동의하게 됩니다. ',
		text5:'쿠키란 무엇인가요? ',
		text6:'쿠키는 귀하가 웹사이트를 방문할 때 귀하의 장치(컴퓨터, 휴대폰 또는 태블릿)에 배치되는 작은 텍스트 파일입니다. 이는 검색 경험을 향상시키고 웹사이트에 개인화 기능을 제공하는 데 널리 사용됩니다. 쿠키는 세션 쿠키(임시적이며 브라우저를 닫으면 삭제됨) 또는 영구 쿠키(오랜 기간 동안 귀하의 장치에 남아 있음)일 수 있습니다. ',
		text7:'쿠키 사용 방법',
		text8: '교환에서 쿠키를 사용하는 목적은 다음을 포함하지만 이에 국한되지는 않습니다.',
		text9:'교환의 기본 기능 및 특징을 활성화합니다',
		text10:'교환의 성능과 기능을 분석하고 개선합니다',
		text11:'경험을 개인화하고 선호도를 기억하세요',
		text12:'교환소에서의 사용 패턴을 추적하고 이해하세요',
		text13:'관련 광고 및 마케팅 커뮤니케이션 제공',
		text14:'당사가 사용하는 쿠키 유형',
		text15:'a) 필수 쿠키: 이 쿠키는 거래소 운영에 필요하며 귀하가 해당 기능에 액세스하고 사용할 수 있도록 해줍니다. 이러한 쿠키가 없으면 일부 서비스를 이용하지 못할 수도 있습니다. ',
		text16:'b) 분석 쿠키: 이 쿠키는 귀하가 방문한 페이지, 발생한 오류 등 귀하가 교환을 사용하는 방식에 대한 정보를 수집합니다. 우리는 이 정보를 사용하여 Exchange의 성능과 기능을 분석하고 개선합니다. ',
		text17:'c) 기능 쿠키: 이 쿠키를 통해 Exchange는 귀하의 선택(예: 언어 기본 설정)을 기억하고 보다 강화되고 개인화된 기능을 제공할 수 있습니다. ',
		text18:'d) 광고 쿠키: 이 쿠키는 귀하 및 귀하의 관심사와 관련된 광고를 제공하는 데 사용됩니다. 또한 귀하가 광고를 보는 횟수를 제한하고 광고 캠페인의 효과를 측정하는 데에도 사용될 수 있습니다. ',
		text19:'타사 쿠키',
		text20:'Exchange를 통해 제3자 서비스 제공업체는 분석 및 광고를 포함한 다양한 목적으로 귀하의 장치에 쿠키를 배치할 수 있습니다. 이러한 제3자는 자체 개인정보 보호정책을 갖고 있으며 다양한 웹사이트나 온라인 서비스에서 귀하의 온라인 활동에 대한 정보를 수집할 수 있습니다. ',
		text21:'쿠키 관리',
		text22:'귀하는 브라우저 설정을 통해 언제든지 쿠키를 관리하거나 삭제할 수 있습니다. 그러나 특정 쿠키를 비활성화하거나 삭제하면 교환 기능과 성능에 영향을 미칠 수 있다는 점에 유의하시기 바랍니다. ',
		text23:'동의',
		text24:'Exchange를 사용함으로써 귀하는 본 정책에 설명된 쿠키 사용에 동의하게 됩니다. 쿠키 사용에 동의하지 않는 경우 브라우저 설정을 통해 쿠키를 비활성화 또는 삭제하거나 Exchange를 이용하지 않을 수 있습니다. ',
		text25:'업데이트 정책',
		text26:'저희는 당사 관행의 변경 사항이나 해당 법률의 변경 사항을 반영하기 위해 이 쿠키 정책을 정기적으로 업데이트할 수 있습니다. 최신 정보를 확인하려면 이 정책을 정기적으로 검토하는 것이 좋습니다. ',
		text27:'저희가 쿠키를 사용하는 방식에 대해 질문이나 우려 사항이 있는 경우 고객 지원팀에 문의하세요. ',
		
		text28:'문제 해결 및 도움 받기',
		text29:'시작하기',
		text30:'교환 토큰',
		text31:'문제 해결',
		text32:'탈중앙화 거래소란 무엇인가요? ',
		text33: 'DEX는 분산형 블록체인 네트워크에서 실행되는 암호화폐 거래소이며 일반적으로 스마트 계약을 기반으로 구축됩니다. 기존 중앙 집중식 거래소와 달리 CEX는 중앙 기관에 의존하여 거래를 촉진하고 사용자 자금을 보유하며 DEX는 다음을 통해 양 당사자와 직접 거래할 수 있습니다. 스마트 계약. ',
		text34:'분산형 거래소의 보안',
		text35: 'DEX는 블록체인의 스마트 계약을 사용하여 작동하므로 구매자와 판매자 간의 직접 거래가 가능하므로 제3자 기관이 필요하지 않습니다. DEX는 퍼블릭 블록체인에서 실행되며 거래는 투명하고 감사 가능하며 누구나 DEX의 공정성과 무결성을 확인할 수 있습니다. ',
		text36:'탈중앙화 지갑이란 무엇인가요? ',
		text37: '분산형 지갑은 12개의 키로 구성된 암호화폐 지갑입니다. 사용자는 12개의 키를 사용하여 동일한 블록체인의 키를 사용하여 지갑에 로그인하여 디지털 자산에 액세스할 수 있습니다. 키가 유출되지 않는 한 누구도 분산형 지갑에 있는 디지털 자산을 얻을 수 없습니다.',
		text38:'지갑 주소가 무엇인가요? ',
		text39:'암호화폐 지갑 주소는 타원 곡선 서명 알고리즘을 통해 개인 키에서 얻은 공개 키입니다. 공개 키는 변환 과정에서 되돌릴 수 없는 해시 작업을 통해 얻은 지갑 주소입니다. 주소는 주로 암호화폐를 보내고 받는 데 사용됩니다. ',
		text40:'자금 교환을 위한 DEX 사용의 안전성',
		text41:'DEX의 운영 메커니즘은 제3자 기관을 거치지 않고 직접 교환을 위한 비수탁 시스템을 채택합니다. 모든 거래는 스마트 계약을 통해 실행됩니다. 투명성, 개인 정보 보호 및 보안의 실현을 진정으로 실현하는 블록체인입니다. ',
		text42: '거래소에서 동일한 토큰의 가격 차이가 왜 발생하나요? ',
		text43: '시장 공급 및 수요: 거래소는 자산의 구매 및 판매를 제공하지만 주요 거래소의 시장 공급 및 수요가 다르기 때문에 판매 및 구매를 위해 거래소에서 설정한 가격이 변경되고 특정 토큰에 대한 수요가 변경됩니다. 일반적으로 가격은 시장 가격보다 높으며 수요가 적은 거래소에서는 가격이 낮아집니다. ',
		text44: '거래량: 거래소에서 사고 파는 암호화폐의 양으로 가격에 영향을 미칠 수 있습니다. 거래량이 많다는 것은 일반적으로 시장 활동이 더 많다는 것을 의미하며, 이는 더 정확한 가격 발견을 촉진할 수 있습니다. 거래량이 적은 거래소는 입찰가 스프레드가 더 넓고 가격이 더 부정확할 수 있습니다. ',
		text45: '유동성: 상당한 가격 변화를 일으키지 않고 자산을 사고 파는 용이성과 어려움을 나타냅니다. 유동성이 높은 암호화폐는 거래소 전체에서 가격이 더 일관된 경향이 있습니다. 거래소의 유동성이 낮으면 가격 변동과 큰 가격 차이가 발생할 수 있습니다. ',
		text46: '토큰 선택: 모든 토큰이 동일한 수익 잠재력을 갖는 것은 아니며, 토큰마다 유동성, 거래량 및 가격 변동성이 다양할 수 있습니다.',
		text47:'환율 스프레드란 무엇인가요? ',
		text48: '환율 차이는 서로 다른 거래소 간의 암호화폐 가격 변화를 의미합니다. 암호화폐는 전 세계적으로 분산되어 다양한 플랫폼에서 거래되기 때문에 공급 및 수요 역학, 유동성, 거래량 및 시장 상황은 거래소마다 다를 수 있습니다. ',
		text49:'DEX 교환이란 무엇인가요? ',
		text50:'토큰 교환 또는 자산 교환이라고도 알려진 DEX 교환은 DEX에 대한 교환 계약을 통해 하나의 암호화폐 또는 토큰을 다른 암호화폐 또는 토큰으로 교환하는 프로세스를 의미합니다. 이러한 거래는 일반적으로 블록체인의 스마트 계약을 통해 촉진되므로 중개자가 필요하지 않습니다. ',
		text51: '주요 거래소에서 스프레드가 사라진 이유는 무엇입니까? ',
		text52:'다른 거래소에서 토큰 가격에 차이가 없다는 것은 시장이 상대적으로 안정적이라는 것을 의미합니다. 이러한 상황의 이유는 시장 거래자가 불균형 거래를 기반으로 자동으로 차익 거래를 하고 시장 거대 기업이 참여하기 때문입니다. 차익 거래를 위해 많은 자금을 활용하기 위한 거래, 시장 토큰 가격을 일정하게 유지, 거래량 및 유동성, 높은 거래량 및 충분한 유동성, 시장 통합은 모두 거래소에서 스프레드가 사라지는 원인입니다.',
		text53:'지정된 주소는 무엇입니까? ',
		text54: '지정된 주소는 교환이 성공한 후 사용자가 작성해야 하는 암호화폐 수신 주소입니다. 처음 교환하는 사용자는 공통 수신 주소를 바인딩해야 합니다.',
		text55:'가격 하락이란 무엇인가요? ',
		text56: '슬리피지라고도 알려진 가격 슬리피지는 암호화폐 거래의 예상 가격과 거래가 실제로 실행되는 가격 간의 차이입니다. 가격 하락은 일반적으로 시장 변동성, 유동성, 주문 규모, 주문 유형 등에 의해 발생합니다. 암호화폐와 같이 빠르게 움직이고 변동성이 큰 시장에서는 가격이 급격하게 변할 수 있습니다. ',
		text57:'교환 GAS란 무엇인가요? ',
		text58: 'GAS는 성공적인 교환 후 암호화폐를 인출할 때 사용자가 블록체인에 지불해야 하는 GAS 수수료입니다. GAS 수수료는 사용자 주문을 처리하는 동시에 블록체인에서 사용자 거래의 성공을 확인하는 데 사용됩니다. . 일반적으로 거래소 자산 인출에 대한 GAS 수수료는 0.1%입니다. ',
		text59:'대기 중인 주문이 왜 있나요? ',
		text60: '네트워크 정체: 수요가 많거나 활동이 증가하는 기간에는 블록체인 네트워크가 정체될 수 있습니다. 이러한 혼잡으로 인해 거래 처리 시간이 느려지고 수수료가 높아질 수 있습니다. 시장 변동이 크거나 거래 활동이 급증하면 네트워크 정체가 발생할 수 있습니다. ',
		text61: '확장성 문제: 일부 블록체인 네트워크는 확장성 문제에 직면해 있습니다. 이는 블록체인이 대량의 트랜잭션을 빠르고 효율적으로 처리하지 못할 수 있음을 의미합니다. 이로 인해 지연이 발생하고 거래 속도가 느려질 수 있습니다. ',
		text62: '유동성 제약: 시장에서 구매자와 판매자의 가용성. 일부 암호화폐 시장이나 특정 암호화폐에서는 유동성이 제한되어 있어 거래 속도가 느려질 수 있으며, 특정 가격에 구매하거나 판매하는 참가자가 적어서 주문 실행 속도가 느려질 수 있습니다. ',
		text63: '시장 주문 규모: 주문 규모는 거래 속도에 영향을 미치며, 특히 이용 가능한 유동성에 비해 대량 주문인 경우 더욱 그렇습니다. 주문이 시장에서 사용 가능한 공급 또는 수요와 상호 작용할 때 대량의 매수 또는 매도 주문을 하면 부분적으로 체결되거나 실행 속도가 느려져 주문이 보류될 수 있습니다. ',
		text64:'보류 주문의 자산은 안전한가요? ',
		text65: '보류 중인 주문의 자금은 암호화폐 시장에서 손실되지 않습니다. 거래 주문이 보류 중인 경우 블록체인이 주문과 일치할 때까지 기다려야 합니다. 주문이 일치하면 거래가 즉시 실행됩니다. ',
		text66:'가속 주문 처리란 무엇인가요? ',
		text67: '거래자가 주문을 시작할 때 공급과 수요 관계가 더 이상 존재하지 않기 때문에 해당 주문은 블록체인에서 일치할 수 없습니다. 사용자는 거래 자금을 늘려 새로운 공급과 수요 관계로 보류 중인 주문 거래를 완료할 수 있습니다. ',
	},
	login: {
		Get_Started: '시작하기',
		Best_Swap: '세계 최고의 스왑 플랫폼',
		Decentralized_cryptocurrency: '다양한 디지털 자산 간의 빠르고 원활한 교환을 가능하게 하는 분산형 암호화폐 거래 플랫폼!',
		Cookies_Privacy: '쿠키 개인정보 보호',
		We_use_cookies: '당사는 귀하의 브라우징 경험을 개선하고, 웹사이트 트래픽을 분석하고, 개인화된 콘텐츠를 제공하기 위해 쿠키를 사용합니다. 이 웹사이트를 계속 사용하면 귀하는 당사의 쿠키 사용에 동의하는 것입니다!',
		Accept: '수용하다',
		Sum: '합집합',
		Swap: '교환',
		Balance: '균형',
		Name: '이름',
		Price_Change: '가격 변경',
		Sort_Type: '정렬 유형',
		Add_Asset: '자산 추가',
		Add_the_cryptocurrency: '필요한 암호화폐 자산을 추가하세요',
		Current_Market_Conditions: '현재 시장 상황',
		Market: '시장',
		Overview: '개요',
		Coin_Information: '동전 정보',
		Market_Value: '시장 가치',
		Total_Supply: '총 공급량',
		In_Circulation: '유통 중',
		Trading_Volume: '거래량',
		Official_Website: '공식 웹사이트',
	}
}
