export default {
	"830753-0": "Пополнить",
	"830753-1": "Быстро пополнить активы",
	"830753-2": "Пожалуйста, загрузите подтверждение",
	"830753-3": "Подтвердить пополнение",
	"830753-4": "Данные ответа сервера неверны:",
	"830753-5": "Загрузка изображения не удалась:",
	account: {
		Start_making_money_plan: "План «Начать зарабатывать деньги»",
		Market: "Рынок",
		Migital_currency: "Миграционная валюта",
		Foreign_exchange: "Иностранная валюта",
		Noble_metal: "Благородный металл",
		Send_Cryop_Now: "Отправить криоп сейчас",
		Send: "Отправлять",
		Receive: "Получать",
		Buy: "Купить",
		Currency_account: "Валютный счет",
		Contract_account: "Контрактный счет",
		Transfer: "Передача",
		Select_a_wallet: "Выберите кошелек",
		Select_Coin: "Выбрать монету",
		Confirm: "Подтверждать",
		Wallet: "Кошелек",
		Availavle: "Доступный",
		Frozen: "Замороженный",
		Convert: "Конвертировать",
		Receiving_Address: "Адрес получения",
		Amount: "Количество",
		Max: "Макс",
		Send_Now: "Отправить сейчас",
		Please_check_if: "Пожалуйста, проверьте правильность вашего адреса доставки перед отправкой, чтобы избежать потери активов.",
		new1:'криптовалюта',
		new2:'индекс',
		new3:'Масса',
		new4:'Форекс',
		new5:'общий баланс',
	},
	"314962-0": "Google 2FA",
  "314962-1": "Загрузите Google Authenticator для привязки",
  "314962-2": "Скопировать код подтверждения",
  "314962-3": "Введите код подтверждения",
  "314962-4": "Запрос не удался:",
  "314962-5": "Код подтверждения скопирован",
  "314962-6": "Невозможно скопировать текст:",
  "314962-7": "Копирование не удалось, попробуйте еще раз",
  "199917-0": "Настройки",
  "199917-1": "Уведомления",
  "199917-2": "Google 2FA",
  "199917-3": "Электронная почта",
  "199917-4": "Язык",
  "199917-5": "Версия",
  "199917-6": "Выйти",
  "516529-0": "Настройки аккаунта",
  "516529-1": "Понимание информации об аккаунте",
  "516529-2": "Онлайн поддержка",
  "516529-3": "Поделиться приложением",
  "516529-4": "Основные настройки",
  "516529-5": "Настройки электронной почты",
  "516529-6": "Сменить язык",
  "516529-7": "Уведомление о ценах",
  "516529-8": "Юридическая информация",
  "516529-9": "Условия обслуживания",
  "516529-10": "Политика против отмывания денег",
  "516529-11": "Политика использования файлов cookie",
  "516529-12": "Получите ответы и помощь",
  "516529-13": "Начало работы",
  "516529-14": "Обмен токенами",
  "516529-15": "Устранение неполадок",
  "516529-16": "Рекомендовать друзьям",
  "516529-17": "Поделитесь с друзьями и получите комиссию за рекомендации",
  "516529-18": "Скопировать вашу ссылку",
  "516529-19": "Настройте свой адрес электронной почты для получения обновлений о рынке",
  "516529-20": "Подтвердить сейчас",
  "516529-21": "Введите адрес электронной почты...",
  "516529-22": "Введите код подтверждения...",
  "516529-23": "Ваш адрес электронной почты будет использоваться только для получения полезной информации или напоминаний, связанных с нашими услугами, и не будет злоупотребляться!",
  "516529-24": "Сохранить",
  "516529-25": "Выберите язык вашего веб-сайта",
  "516529-26": "Мы стремимся защитить безопасность и конфиденциальность ваших данных",
  "516529-27": "Традиционный китайский",
  "516529-28": "Японский",
  "516529-29": "Отправить код подтверждения",
  "516529-30": "Скопировано успешно!",
  "516529-31": "Отправить заново",
    home:{
        USDTAccount: 'USDT Account',
        USTD: 'USDT',
        USDT: 'USDT',
        USTC: 'USDC',
        open: 'Open',
        ETHTodayProfit: 'ETH Today\'s Profit',
        totalRevenue: 'Total Revenue',
        portfolio: 'Portfolio',
        reliableSecurity: 'Reliable Security Guarantee',
        stableReliable: 'Stable and Reliable Investment',
        convenienEasy: 'Convenien and Easy Operation',
        pleaseLookForward: 'Please look forward to...',
        rateOfReturn: 'Rate of Return',
		walletnot: 'Please install MetaMask or any Ethereum Extension Wallet',
		wallettip: 'Please bind your address in order not to affect the experience',
    },
	"ConnectW":"Connect Wallet",
	"dappbrowser":"This website only accepts access from decentralized wallet DAPP browsers.",
    "Coins POS": "CoinB Pos",
    "9dcf43fa47": "9dcf43fa47",
    "Earn Interest": "Earn Interest",
    "$": "$",
    "ETH Today's Profit": "ETH Today's Profit",
    "Wallet": "Wallet",
    "Record": "Record",
    "Support": "Support",
    "General": "General",
    "Notifications": "Notifications",
    "Invite Friends": "Invite Friends",
    "FAQ": "FAQ",
    "Privacy & Security": "Privacy & Security",
    "Legality": "Legality",
	"Authenticator":"Authenticator",
	"Settings":"Settings",
	"Change Language":"Change Language",
	"Daytime mode":"Daytime mode",
	"Black mode":"Black mode",
    "BTC": "BTC",
    "Bitcoin": "Bitcoin",
    "ETH": "ETH",
    "ethereum": "ethereum",
    "USDT": "USDT",
    "tether": "tether",
    "USDC": "USDC",
    "usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'Open',
    'High':'High',
    'Low':'Low',
    'Close':'Close',
    'Up':'Up',
    'Down':'Down',
    'Time':'Time',
    'TransactionFee':'Transaction Fee',
    'AvailableBalance':'Available Balance',
    'submit':'Subscribe',
    'Direction':'Direction',
    'Profit':'Profit',
    'Quantity':'Quantity',
    'Price':'Price',
    'different':'Click on different ratios to display the lowest limit',
    'lessthan':'At least cannot be less than',
    'least':'least：',
    'Transactionhistory':'Transaction history',
    'Transactionrecords':'Transaction records',
    'Number':'Number',
    'Profitloss':'Profit loss',
	'Ror':'Ror',
	'operate':'operate',





    //钱包
    TransactiónDetails:'Transactión Details',
	dealdetails:'deal details',
    ViewTransactionHistory:'View Transaction History',
    Deposit:'Deposit',
    Rechargeaddress:'Recharge address：',
    DepositAmount:'Deposit Amount：',
    EnterDepositAmount:'Enter Deposit Amount',
    EnterHash:'Enter Hash',
    Hash:'Hash：',
    Voucherimage:'Voucher image：',
    Continue:'Continue',
    Withdraw:'Withdraw',
    Withdrawal:'Withdrawal：',
    EnterAmounttoWithdraw:'Enter Amount to Withdraw',
    Available:"Available：",
    ReceiveAddress:'Receive Address：',
    EnterWalletAddress:'Enter Wallet Address',
    WithdrawalDesc:'Withdrawal in English requires a 1% handling fee, which needs to be confirmed by the network nodebefore it can be credited Please do not transfer cryptocurrency to strangers',
    Exchange:'Exchange',
    Send:'Send:',
    Max:'Max:',
    rechargeAmount:'Please input a recharge amount',
    hashcode:'hash code and screenshot cannot be empty at the same time',
    correcthashcode:'please input a correct hash code',
    maximumwithdrawalamount:'The maximum withdrawal amount is',
    Incorrectamountformat:'Incorrect amount format',
	copy:'copy',


	//tab2
	"Total Profit":"Total Profit",
	"Today's Profit":"Today's Profit",
	"What is Automated Trading?":"What is Automated Trading?",
	"Learn about earning":"Learn about earning",
	"Staking Period":"Staking Period",
	"Staking Amount":"Staking Amount",
	"Yield":"Yield",
	"Limit":"Limit",
	"Subscribe":"Subscribe",
	"Record":"Record",

	//tab3
	"Proof of Stake":"Proof of Stake",
	"ETH Today is Price":"ETH Today is Price",
	"ETH Today's Increase":"ETH Today's Increase",
	"Joint Staking":"Joint Staking",
	"Individual Staking":"Individual Staking",
	"tab3long1":"Joint staking is achieved by matching participants through smart contracts to reach a total staking amount of 32 ETH.",
	"tab3long2":"Independent staking does not require matching participants through smart contracts. By reaching a staking amount of 32 ETH, one can complete individual staking",


	//web_view
	"webviewlong1":"Cryptocurrency airdrops help emerging blockchain-based projects grow. Their roots connect community members with rewards and common goals. The outcome of a successful airdrop is a stronger community, better projects, and rewards for end users. Over the years, we have seen many airdrops delivered on proof-of-work (POW) blockchains. The proof-of-stake (POS) Ethereum chain now supports hundreds of project cryptocurrency airdrops across dozens of networks. With PoS becoming the dominant consensus paradigm, the number of airdrops on the Ethereum network shows no signs of slowing down.",
	"webviewlong2":"By staking your cryptocurrencies, you can be eligible to unlock valuable airdrop rewards based on the amount of cryptocurrencies you have staked.",
	"webviewlong3":"Reward equity is only available to stake owners, it comes from hundreds of different project airdrops, and its yield is much higher than any one independent airdrop.",
	"webviewlong4":"Airdrop rewards are often difficult for cryptocurrency owners depositing on exchanges, and some custodians do not support airdrops at all. This is why it is important to manage your own cryptocurrencies. Ethereum is a non-custodial entity that can be staked across all major networks",
	"Introduction":"Introduction",
	"webviewlong5":"Ethereum, a leading blockchain platform, is transitioning from a Proof of Work (PoW) consensus mechanism to a Proof of Stake (PoS) mechanism through its Ethereum 2.0 upgrade. PoS staking is an essential component of this transition, providing users with an opportunity to support network security and operations while potentially earning rewards. This article provides an overview of Ethereum PoS staking and its benefits.",
	"Ethereum 2.0 and Proof of Stake (PoS)":"Ethereum 2.0 and Proof of Stake (PoS)",
	"webviewlong6":'Ethereum 2.0 is a major network upgrade aimed at improving scalability, security, and sustainability. One of the key changes is the shift from the energy-intensive PoW consensus mechanism to the more environmentally friendly PoS mechanism. In PoS, validators are chosen to create new blocks and confirm transactions based on the amount of cryptocurrency they hold and are willing to "stake" as collateral.',
	"The Staking Process":"The Staking Process",
	"webviewlong7":"To participate in Ethereum PoS staking, users must stake a minimum of 32 ETH by depositing it into the Ethereum 2.0 deposit contract. Once staked, the ETH is locked for a certain period and serves as collateral to secure the network. Validators are chosen based on the amount of staked ETH and their online activity. They are responsible for proposing and validating new blocks, as well as confirming transactions on the network.",
	"Staking Rewards and Risks":"Staking Rewards and Risks",
	"webviewlong8":"By participating in PoS staking, users can earn rewards in the form of newly minted ETH and transaction fees. The rewards are based on the amount of ETH staked and the overall network activity. However, staking also comes with risks, such as the potential for penalties if a validator behaves maliciously or fails to stay online consistently. Additionally, the staked ETH is locked for a certain period, making it illiquid and subject to price fluctuations.",
	"Joint Staking and Individual Staking":"Joint Staking and Individual Staking",
	"webviewlong9":"For users who do not have the required 32 ETH to stake individually, joint staking offers an alternative. In joint staking, multiple users pool their ETH together to reach the required staking amount. This pooling is typically facilitated through smart contracts or staking services, allowing users to participate in Ethereum PoS staking with smaller amounts of ETH.",
	"Conclusion":"Conclusion",
	"webviewlong10":"Ethereum PoS staking is a significant aspect of the Ethereum 2.0 upgrade, offering users the opportunity to support network security, improve decentralization, and earn rewards. By understanding the staking process, its rewards, and risks, users can make informed decisions about participating in this crucial evolution of the Ethereum network.",


	//mywallet
	"Total":"Total",

	//record
	"Account":"Account",
	"interest-bearing":"interest-bearing",
	"POS Staking":"POS Staking",
	"convert":"convert",
	"transaction":"transaction",
	"pledge":"pledge",
	"recordno":"You don't have any news yet. The list is empty.",
	"Oops":"Oops",
	"recharge":"recharge",
	"payment":"payment",
	"staking":"staking",
	"income":"income",
	"Pledge quantity":"Pledge quantity",
	"cycle":"cycle",
	"Days Remaining":"Days Remaining",
	"cumulative gain":"cumulative gain",
	"Price increase":"Price increase",
	"Price decrease":"Price decrease",
	"Opening price":"Opening price",
	"Closing price":"Closing price",
	"loss":"loss",
	"pfofit":"pfofit",
	"amount":"amount",

	//notifiCations
	"notifiCationslong1":"Note: VIP registration is based on the recharge amount of the corresponding overall account to determine your level. The interval needs to deduct the total recharge amount after the withdrawal of the existing account to determine the membership level.",
	"notifiCationslong2":"The membership duration is also divided according to different levels. To maintain your membership status, you need to recharge at least 5,000 USDT each time within the specified time to enjoy the privileges of membership. After each recharge of the membership status, the cycle is recalculated and can be accumulated.",
	"notifiCationslong3":"Customer service is divided based on membership levels. Official customer service is provided by APP staff and offers unified service to the majority of members. The number of exclusive secretaries is limited, ensuring better service. Exclusive personal secretaries provide users with dedicated reception services and financial advice.",
	"notifiCationslong4":"Contact customer service in advance to make an appointment for recharge. Complete the recharge within the specified time to receive additional rewards for appointment recharge based on the corresponding membership level.",
	"notifiCationslong5":"VIP1: Based on the UID account, accumulate a total recharge of 10,000 USD and receive 177 USDT as a bonus. After reaching the VIP level and obtaining the General Member Medal, the points will be airdropped to the account.",
	"notifiCationslong6":"VIP2: Based on the UID account, cumulative recharge reaches 30,000 USD, and 777 USDT will be given away. The bonus amount will be credited to the account immediately after reaching VIP level and obtain the Bronze Member Medal.",
	"notifiCationslong7":"VIP3: Based on the UID account, accumulate a total of $70,000 in recharge and receive 1777 USDT as a gift. After reaching VIP level and obtaining the Silver Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 1.5% reward on the next booking recharge amount.",
	"notifiCationslong8":"VIP4: Based on the UID account, accumulate a total recharge of $150,000 and receive 2,777 USDT. After reaching VIP level and obtaining the Gold Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 2% reward on the next booking recharge amount.",
	"notifiCationslong9":"VIP5: Based on the UID account, cumulative recharge reaches 300,000 USD, and 4777 USDT will be given as a gift. After reaching VIP level and obtaining the Diamond VIP badge, points will be airdropped to the account. Additionally, the next booking recharge amount will receive an additional 2.5% bonus.",
	"notifiCationslong10":"VIP6: Based on the UID account, cumulative recharge reaches 800,000 USD, and 16,888 USDT will be given as a bonus. The bonus amount will be airdropped to the account immediately after reaching the VIP level and obtaining the Black Diamond membership badge, and an additional 3% of the next pre-registered recharge amount will be obtained. You will have a private exclusive secretary to provide any financial advice or handle tax issues.",
	"notifiCationslong11":"VIP7: Based on the UID account, the cumulative recharge amount reaches 3 million US dollars, and a 15-day luxury trip to Europe (with discounts) will be given. CoinBpos global lifetime honorary VIP will be awarded and the crown member medal will be obtained. Extra 3.5% bonus will be given for the next appointment recharge.",
	"notifiCationslong12":"VIP8: According to the UID account, if the cumulative recharge reaches 8 million US dollars, you can get one CoinBpos Morocco Annual Banquet voucher. Reward: 1 BTC, and obtain CoinBpos Global Lifetime Honor VIP. Next appointment recharge can receive an additional 4% bonus.",
	"notifiCationslong13":"VIP9: Based on the UID account, cumulative deposits reach 30 million US dollars, you can get 3% of CoinBpos shares. And enjoy a 3% annual profit dividend. Free gift of Cronix pure gold commemorative badge.",

	//invite
	"Refer and Earn":"Refer and Earn",
	"Copy Link":"Copy Link",

	//faq
	"Frequently Asked Questions":"Frequently Asked Questions",

	//authenticator
	"mailbox":"mailbox",
	"Front photo":"Front photo",
	"Back photo":"Back photo",
	"Handheld ID photo":"Handheld ID photo",
	"define":"define",

	//legality
	"legalitylong1":"Coinbpos, composed of multiple cryptocurrency companies, together they provide tools to help the encryption community create, grow, maintain community stability, power thousands of decentralized applications, promote the future of cryptocurrency as well as promote the digitization of traditional currencies and to promote global economic prosperity.",
	"legalitylong2":"Each company within Coinbpos acts as a data controller for the processing of personal data related to its services, the data controller for each service is:",
	"legalitylong3":"1. Blockchain Technology - Ethereum Blockchain.",
	"legalitylong4":"2.Decentralized Wallet - Trust Wallet, MetaMask and Crypto Wallet.",
	"legalitylong5":"3. Trading Technology-Coinbase, Crypto, linch and Binance.",
	"legalitylong6":"Privacy Policy details how Coinbpos processes personal data in accordance with its obligations under relevant data protection laws, including The European Union's General Data Protection Regulation (GDPR),",
	"legalitylong7":'The California Consumer Privacy Act (CCPA) and other General Laws (collectively referred to as "Data Protection Laws*).',

	//language
	"language":"language",
	"Confirm":"Confirm",

	"newtext1":"Sign out",
	"newtext2":"Cancel",
	"newtext3":"Are you sure you want to log out of this account?",

	"newtext4":"中文简体",
	"newtext5":"中文繁体",
	"newtext6":"English",
	"newtext7":"日本語",

	"newtext8":"Login",
	"newtext9":"username",
	"newtext10":"Mobile phone number",
	"newtext11":"Please enter user name",
	"newtext12":"Please enter your mobile phone number",
	"newtext13":"password",
	"newtext14":"Please enter password",
	"newtext15":"No account yet",
	"newtext16":"Register",
	"newtext17":"Country",
	"newtext18":"Select a country",
	"newtext19":"Mobile phone number verification code",
	"newtext20":"Please enter the verification code",
	"newtext21":"Email address",
	"newtext22":"Please enter your email address",
	"newtext23":"Confirm password",
	"newtext24":"Please enter the confirmation password",
	"newtext25":"Have an account",
	"newtext26":"Send verification code",
	"newtext27":"Mobile phone number is incorrect",
	"newtext28":"Sent successfully",
	"newtext29":"Registration successful",

	"newtext30":'currency',
	"newtext31":'Forex',
	"newtext32":'precious metals',

	"newtext33":"Обліковий запис",
	"newtext34":"Діяльність платформи",
	"newtext35":'Обслуговування клієнтів',
	"newtext36":'запрошення',
	"newtext37":'Перевірка KYC',
	"newtext38":'налаштування',
	wallet_p:{
		text1: "Копировать адрес",
		text2: 'Подтвердить',
		text3: 'Обмен от',
		text4: "Обмен на",
		text5: "МАКС",
		text6: "Пригласить",
		text7: 'Получить награды по рефералам',
		text8: "Когда ваши друзья участвуют в арбитраже ИИ и арендуют майнинговые машины, вы можете получить награды по рефералам",
		text9: "копировать",
		text10: 'Поделиться ссылку',
		text11: "Проверка KYC",
		text12: "Страна",
		text13: "Пожалуйста, выберите страну",
		text14: "Имя",
		text15: "Пожалуйста, введите ваше имя",
		text16: "Фамилия",
		text17: "Пожалуйста, введите вашу фамилия",
		text18: "Тип документа",
		text19: "Пожалуйста, выберите тип документа",
		text20: "Номер документа",
		text21: "Пожалуйста, введите номер документа",
		text22: "Пожалуйста, загрузите фото лицевой стороны вашего удостоверения личности",
		text23: "Пожалуйста, загрузите фото обратной стороны вашего удостоверения личности",
		text24: "Пожалуйста, загрузите фото лицевой стороны вашего паспорта",
		text25: "Пожалуйста, загрузите фото обратной стороны вашего паспорта",
		text26: "Электронная почта",
		text27: "Пожалуйста, введите вашу электронную почта",
		text28: "отправить",
		text29: "Подтверждающий код",
		text30: "Пожалуйста, введите ваш подтверждающий код",
		text31: "Удостоверение личности",
		text32: "паспорт",
		text33: "отменить",
		text34: "Страна/Регион",
		text35: "Пожалуйста, введите правильную информацию для отправки на аутентификацию",
		text36: "Успешно отправлено!",
		text37: "Ошибка формата электронной почты!",
		text38: "водительское удостоверение",
		text39: "Пожалуйста, загрузите фотографию лицевой стороны вашего водительского удостоверения.",
		text40: "Пожалуйста, загрузите фотографию обратной стороны вашего водительского удостоверения.",
		
		text41: "идентификационный номер",
		text42: "Пожалуйста, введите свой идентификационный номер",
		text43: "Номер паспорта",
		text44: "Пожалуйста, введите номер паспорта",
		text45: "номер водительского удостоверения",
		text46: "Пожалуйста, введите номер вашего водительского удостоверения",
	},
	jiaoyi:{
		text1:'Второй контракт',
		text2:'Бессрочный контракт',
		text3:'Спотовая транзакция',
		text4:'Делегировать немедленно',
		text5:'Тип учетной записи',
		text6:'время доставки',
		text7:'Ценовой диапазон',
		text8:'Цена покупки',
		text9:'стоимость',
		text10:'Баланс',
		text11:'по крайней мере',
		text12: 'ожидается',
		text13:'Сумма покупки',
		text14:'направление',
		text15:'Цена покупки',
		text16:'Цена в реальном времени',
		text17:'Обратный отсчет',
		text18:'Мой контракт',
		text19: 'Прибыль',
		text20:'Контракт',
		text21:'цена доставки',
		text22:'время доставки',
		text23:'Рыночная цена',
		text24:'восходящее',
		text25:'падение',
		text26:'Цена покупки',
		text27:'максимум',
		text28:'Прибыль',
		text29:'плата за обработку',
		text30:'Купить',
		text31:'Продать',
		text32:'Количество покупки',
		text33:'реальный счет',
		text34:'Виртуальный аккаунт',
		text35:'Данных пока нет',
		text36:'Текущая позиция',
		text37:'Историческая комиссия',
		text38:'Закрыть позицию',
		text39:'Маржа',
		text40:'Прибыль и убытки',
		text41: 'держать',
		text42:'Запись о покупке',
		text43:'Продаю запись',
		text44:'Доступно',
		text45:'преобразовано',
		text46:'Аутентификация прошла успешно',
		text47:'Время покупки',
		text48:'непрочитанный',
		text49:'Читать',
		text50:'Приказ хоста',
		text51:'Тотальный арбитраж',
		text58:'Сегодняшний доход',
		text52:'Как работают роботы с искусственным интеллектом',
		text53:'арбитражный продукт',
		text54:'день',
		text55:'Сумма',
		text56:'Ежедневный доход',
		text57:'Тип валюты',
		text59:'Присоединяйтесь к AI-арбитражу',
		text60:'Нулевой риск, быстрая прибыль',
		text61:'AI-арбитраж',
		text62:'Ожидаемый доход',
		text63:'Тип арбитражной монеты',
		text64:'Сумма условного депонирования',
		text65:'Принимайте хост сейчас',
		text66:'Ежедневный доход отправляется на ваш кошелек USDT',
		text67:'Инвестиционный фонд с нулевым риском',
		text68:'Вы можете вывести свои средства в любое время',
		text69:'Искусственный интеллект работает 24 часа в сутки',
		text70:'Совокупный доход',
		text71:'Срок годности',
		text72:'все',
		text73:'В процессе',
		text74:'Завершено',
		text75:'Подробности о хостинге',
		text76:'Выберите валюту',
		text77:'Техническая поддержка',
		text78:'Отправить письмо',
		text79:'Тип транзакции',
		
		text80:'Загрузка...',
		text81:'Больше нет',
		text82:'Белая книга',
	},
	
	swapadd:{
		text1:'Политика использования файлов cookie',
		text2:'Мы стремимся защитить безопасность и конфиденциальность ваших данных',
		text3:'Введение',
		text4:'Настоящая Политика в отношении файлов cookie объясняет, как наша децентрализованная биржа использует файлы cookie и аналогичные технологии отслеживания. Получая доступ к Бирже или используя ее, вы соглашаетесь на использование файлов cookie, как описано в настоящей Политике. ',
		text5:'Что такое файлы cookie? ',
		text6:'Файлы cookie — это небольшие текстовые файлы, которые сохраняются на вашем устройстве (компьютере, мобильном телефоне или планшете) при посещении веб-сайта. Они широко используются для улучшения вашего просмотра и предоставления функций персонализации на веб-сайтах. Файлы cookie могут быть сеансовыми (которые являются временными и удаляются при закрытии браузера) или постоянными файлами cookie (которые остаются на вашем устройстве в течение более длительного периода времени). ',
		text7:'Как мы используем файлы cookie',
		text8: 'Цели, для которых биржи используют файлы cookie, включают, помимо прочего:',
		text9:'Включить основные функции и возможности биржи',
		text10:'Анализ и улучшение производительности и функциональности биржи',
		text11:'Персонализируйте свой опыт и помните свои предпочтения',
		text12:'Отслеживайте и анализируйте свои модели использования на бирже',
		text13:'Предоставить соответствующую рекламу и маркетинговые коммуникации',
		text14:'Типы файлов cookie, которые мы используем',
		text15:'a) Основные файлы cookie: эти файлы cookie необходимы для работы биржи и позволяют вам получить доступ и использовать ее функции. Без этих файлов cookie некоторые услуги могут быть вам недоступны. ',
		text16:'b) Аналитические файлы cookie: эти файлы cookie собирают информацию о том, как вы используете биржу, например, о страницах, которые вы посещаете, и о любых ошибках, с которыми вы сталкиваетесь. Мы используем эту информацию для анализа и улучшения производительности и функциональности Биржи. ',
		text17:'c) Функциональные файлы cookie. Эти файлы cookie позволяют Бирже запоминать ваш выбор (например, языковые предпочтения) и обеспечивать расширенные, более персонализированные функциональные возможности. ',
		text18:'d) Рекламные файлы cookie: Эти файлы cookie используются для предоставления рекламы, соответствующей вам и вашим интересам. Их также можно использовать для ограничения количества просмотров рекламы и для измерения эффективности рекламных кампаний. ',
		text19: 'Сторонние файлы cookie',
		text20:'Биржи могут разрешать сторонним поставщикам услуг размещать файлы cookie на вашем устройстве для различных целей, включая аналитику и рекламу. Эти третьи лица имеют свою собственную политику конфиденциальности и могут собирать информацию о ваших действиях в Интернете на различных веб-сайтах или онлайн-сервисах. ',
		text21:'Управление файлами cookie',
		text22:'Вы можете управлять файлами cookie или удалять их в любое время через настройки браузера. Однако обратите внимание, что отключение или удаление определенных файлов cookie может повлиять на функциональность и производительность биржи. ',
		text23:'Согласен',
		text24:'Используя Exchange, вы соглашаетесь на использование файлов cookie, как описано в этой политике. Если вы не согласны на использование файлов cookie, вы можете отключить или удалить их через настройки браузера или не использовать Exchange. ',
		text25:'Политика обновления',
		text26: 'Мы можем периодически обновлять настоящую Политику в отношении файлов cookie, чтобы отражать изменения в нашей практике или изменения в применимом законодательстве. Мы рекомендуем вам регулярно просматривать эту политику для получения самой последней информации. ',
		text27:'Если у вас есть какие-либо вопросы или сомнения по поводу того, как мы используем файлы cookie, свяжитесь с нашей службой поддержки клиентов. ',
		
		text28:'Получите помощь и устранение неполадок',
		text29:'Начало работы',
		text30:'Обмен токенов',
		text31:'Устранение неполадок',
		text32:'Что такое децентрализованная биржа? ',
		text33: 'DEX — это криптовалютная биржа, которая работает в децентрализованной сети блокчейнов и обычно строится на смарт-контрактах. В отличие от традиционных централизованных бирж, CEX полагается на центральное агентство для облегчения транзакций и хранения средств пользователей, DEX может напрямую торговать с обеими сторонами через него. смарт-контракты. ',
		text34:'Безопасность децентрализованных бирж',
		text35: 'DEX работает с использованием смарт-контрактов на блокчейне, что позволяет осуществлять прямые транзакции между покупателями и продавцами, устраняя необходимость в сторонних учреждениях. DEX работает на публичном блокчейне, транзакции прозрачны и проверяются, и каждый может проверить честность и целостность DEX. ',
		text36:'Что такое децентрализованный кошелек? ',
		text37: 'Децентрализованный кошелек — это криптовалютный кошелек, состоящий из 12 ключей. Пользователи могут использовать 12 ключей для входа в кошелек, используя ключ в любом блокчейне для доступа к цифровым активам. Пока ключ не утек, никто не сможет получить цифровые активы в децентрализованном кошельке.',
		text38:'Что такое адрес кошелька? ',
		text39: 'Адрес кошелька криптовалюты — это открытый ключ, полученный из закрытого ключа с помощью алгоритма подписи эллиптической кривой. Открытый ключ — это адрес кошелька, полученный в результате серии преобразований. В процессе преобразования кошелька используется необратимая операция хэширования. Адрес в основном используется для отправки и получения криптовалюты. ',
		text40:'Безопасность использования DEX для обмена средств',
		text41: 'Механизм работы DEX построен на блокчейне. Он не проходит через какие-либо сторонние учреждения и использует некастодиальную систему прямого обмена. Все транзакции выполняются с использованием смарт-контрактов. блокчейн, действительно реализующий реализацию активов. Прозрачность, конфиденциальность и безопасность. ',
		text42: 'Почему на бирже существует разница в цене одного и того же токена?» ',
		text43: 'Рыночный спрос и предложение: биржа обеспечивает покупку и продажу активов, но из-за различного рыночного спроса и предложения основных бирж цены, установленные биржей для продажи и покупки, изменятся, и спрос на определенные токены Обычно цена будет выше рыночной, а на биржах с меньшим спросом цена будет ниже. ',
		text44: 'Объем торгов: количество криптовалюты, купленной и проданной на бирже, которое может повлиять на цену. Более высокий объем торгов обычно указывает на большую рыночную активность, что может способствовать более точному определению цен. Биржи с низкими объемами торгов могут иметь более широкие спреды спроса и предложения и более неточное ценообразование. ',
		text45: 'Ликвидность: относится к простоте и сложности покупки или продажи актива без существенного изменения цены. Криптовалюты с более высокой ликвидностью, как правило, имеют цены, которые более согласованы на разных биржах. Когда ликвидность на бирже низкая, это может привести к колебаниям цен и большим ценовым различиям. ',
		text46: 'Выбор токенов: не все токены имеют одинаковый потенциал прибыли, и разные токены могут иметь разную степень ликвидности, объема торгов и волатильности цен.',
		text47:'Что такое спред обменного курса? ',
		text48: 'Разницы в обменных курсах относятся к изменениям цен на криптовалюту между различными биржами. Поскольку криптовалюты рассредоточены по всему миру и торгуются на различных платформах, динамика спроса и предложения, ликвидность, объемы торгов и рыночные условия могут различаться на разных биржах. ',
		text49:'Что такое биржа DEX? ',
		text50:'Обмен DEX, также известный как обмен токенами или обмен активами, относится к процессу обмена одной криптовалюты или токена на другую криптовалюту или токен посредством соглашения об обмене на DEX. Эти транзакции обычно выполняются с помощью смарт-контрактов на блокчейне, что устраняет необходимость в посредниках. ',
		text51: 'Почему исчезли спреды на крупных биржах? ',
		text52:'Когда нет разницы в цене токенов на разных биржах, это означает, что рынок относительно стабилен. Причина такой ситуации в том, что трейдеры рынка торгуют в фиксированном режиме и автоматически используют арбитраж на основе несбалансированных транзакций, в котором участвуют рыночные гиганты. транзакций для использования большого количества финансирования для торгового арбитража, поддержания неизменных цен на рыночные токены, объема торгов и ликвидности, более высокого объема торгов и достаточной ликвидности, а также консолидации рынка — все это причины, которые приводят к исчезновению спредов на биржах',
		text53:'Какой указанный адрес? ',
		text54: 'Указанный адрес - это адрес получения криптовалюты, который пользователю необходимо заполнить после успешного обмена. Пользователям, совершающим обмен впервые, необходимо привязать общий адрес получения',
		text55:'Что такое проскальзывание цены? ',
		text56: 'Проскальзывание цены, также известное как проскальзывание, представляет собой разницу между ожидаемой ценой сделки с криптовалютой и ценой, по которой сделка фактически совершается. Проскальзывание цен обычно вызвано волатильностью рынка, ликвидностью, размером и типом ордера и т. д. На быстро меняющемся и волатильном рынке, таком как криптовалюта, цены могут быстро меняться. ',
		text57:'Что такое биржевой ГАЗ? ',
		text58: 'GAS — это комиссия GAS, которую пользователи должны платить в блокчейн при выводе криптовалюты после успешного обмена. Комиссия GAS используется для обработки пользовательских заказов и в то же время транслируется в блокчейн для подтверждения успеха транзакции пользователя. . Обычно комиссия GAS за вывод биржевых активов составляет 0,1%. ',
		text59:'Почему существуют отложенные ордера? ',
		text60: 'Перегрузка сети: в периоды высокого спроса или повышенной активности сети блокчейнов могут перегружаться. Такая перегрузка может привести к замедлению обработки транзакций и повышению комиссий. Большие колебания рынка или всплески торговой активности могут вызвать перегрузку сети. ',
		text61: 'Проблемы масштабируемости. Некоторые сети блокчейнов сталкиваются с проблемами масштабируемости, а это означает, что блокчейн может быть не в состоянии быстро и эффективно обрабатывать большие объемы транзакций. Это может привести к задержкам и снижению скорости транзакций. ',
		text62: 'Ограничения ликвидности: наличие покупателей и продавцов на рынке. На некоторых рынках криптовалют или конкретных криптовалют ликвидность ограничена. Отсутствие ликвидности может привести к замедлению транзакций. Может быть меньше участников, покупающих или продающих по определенной цене, что приводит к расширению спреда между ценой покупки и продажи. ',
		text63: 'Размер рыночного ордера: Размер ордера влияет на скорость торговли, особенно если это большой ордер относительно доступной ликвидности. Когда ордера взаимодействуют с доступным предложением или спросом на рынке, размещение большого количества ордеров на покупку или продажу может привести к частичному исполнению или более медленному исполнению, что приведет к появлению отложенных ордеров. ',
		text64:'Надежны ли активы в отложенных ордерах? ',
		text65: 'Средства в отложенных ордерах не будут потеряны на рынке криптовалют. Когда ордер на транзакцию находится в состоянии ожидания, вам нужно дождаться, пока блокчейн совпадет с ордером. Как только ордер будет сопоставлен, транзакция будет выполнена немедленно. ',
		text66:'Что такое ускоренная обработка заказов? ',
		text67: 'Поскольку отношения спроса и предложения больше не существуют, когда трейдер инициирует заказ, соответствующий заказ не может быть сопоставлен в блокчейне. Пользователи могут увеличить средства транзакции, чтобы завершить транзакцию отложенного ордера с новыми отношениями спроса и предложения. ',
	},
	login: {
		Get_Started: 'Начать',
		Best_Swap: 'Лучшая в мире платформа обмена',
		Decentralized_cryptocurrency: 'Децентрализованная платформа обмена криптовалютами, обеспечивающая быстрый и бесперебойный обмен между различными цифровыми активами!',
		Cookies_Privacy: 'Конфиденциальность файлов cookie',
		We_use_cookies: 'Мы используем файлы cookie для улучшения вашего опыта просмотра, анализа трафика веб-сайта и предоставления персонализированного контента. Продолжая использовать этот веб-сайт, вы соглашаетесь на использование нами файлов cookie!',
		Accept: 'Принимать',
		Sum: 'Сумма',
		Swap: 'Менять',
		Balance: 'Баланс',
		Name: 'Имя',
		Price_Change: 'Изменение цены',
		Sort_Type: 'Тип сортировки',
		Add_Asset: 'Добавить актив',
		Add_the_cryptocurrency: 'Добавьте необходимые вам криптовалютные активы',
		Current_Market_Conditions: 'Текущие рыночные условия',
		Market: 'Рынок',
		Overview: 'Обзор',
		Coin_Information: 'Информация о монете',
		Market_Value: 'Рыночная стоимость',
		Total_Supply: 'Общий объем поставок',
		In_Circulation: 'В обращении',
		Trading_Volume: 'Объем торговли',
		Official_Website: 'Официальный сайт',
	}
}
