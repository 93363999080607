<template>
    <div class="homePage" :class="isBlack != 'true'?'whiteBox':''">
		
		<Leftmenu ref="menu"></Leftmenu>  
		
      <div class="header-box">
        <div class="logo-box">
          <img src="../../static/tab1/logo.png" style="width: 146px;height: 38px">
        </div>
        <div class="other-box" style="display: flex;align-items: center;">
			<div style="position: relative;">
				<img src="../../static/tab1/icon-ring.png" @click="gotonotifiCations" style="width: 24px;height: 24px">
				<div v-if="tongzhinum != 0" class="hongdian"></div>
			</div>
          <img src="../../static/tab1/icon-menu.png" @click="leftmenu" style="width: 24px;height: 24px;margin-left: 10px">
        </div>
      </div>
		
      <div class="top-box">
        <div class="crpto" style="font-size: 40px;">RobinHood OTC</div>
        <div class="start">{{$t("account.Start_making_money_plan")}}</div>
        <div class="market-text">{{$t("account.Market")}}</div>
      </div>

		<div class="qiehuan">
			<div class="item" @click="quehuan(0)" :class="{action : query.type==0}">{{$t("account.new1")}}</div>
			<div class="item" @click="quehuan(1)" v-if="false" :class="{action : query.type==1}">{{$t("account.new2")}}</div>
			<div class="item" @click="quehuan(2)" :class="{action : query.type==2}">{{$t("account.new3")}}</div>
			<div class="item" @click="quehuan(3)" :class="{action : query.type==3}">{{$t("account.new4")}}</div>
		</div>

        
			
			  <van-list
			    v-model:loading="vantloading"
			    :finished="finished"
				:loading-text="$t('jiaoyi.text80')"
			    :finished-text="$t('jiaoyi.text81')"
			    @load="datasload"
			  >
			    
					<div class="list" v-for="(item, index) in list" :key="index" @click="toInfo(item[0],query.type)">
					    <div class="flexbox">
					        <img :src="'https://vanke-admin.h98.net' + item[10]" class="marketIcon" alt="">
					        <div class="textVal">
					            <div class="name">{{ item[0] }}</div>
					            <div class="desc">{{ item[1] }}</div>
					        </div>
					    </div>
					
					    <div class="earchs">
					        <div :id="'myChart' + index" :style="{ width: '66px', height: '75px' }"></div>
					    </div>
					    <div class="nowNum">
					        <div class="money">
					            {{ item[2] }}
					        </div>
					        <div class="desc">
					          <div class="hour">24Hr</div>
					          <div class="up-down" :class="item[8]<0 ?'red':'green'">{{ item[8] }}%</div>
					        </div>
					    </div>
					</div>
				
				
			  </van-list>

			
            
			
        
        <div style="height: 50px;"></div>
      <Loading :show="loading"></Loading>
    </div>
</template>


<script>
import {
    toRefs,
    reactive,
    onMounted,
    nextTick
} from 'vue'
import * as echarts from 'echarts'

import {
    useRouter
} from 'vue-router'
import {
  getallcoinList, kline, kline1, kxianbData, getmsgunread
} from '@/api/tab1'
import { userlogin } from '@/api/hometab'
import Loading from "@/components/Loading";
import { showToast } from 'vant';
import Leftmenu from '@/components/leftmenu.vue'
import { useI18n } from "vue-i18n";

export default {
    components: {
      Loading,
	  Leftmenu
    },
    setup() {
        let router = useRouter();

		const {
		  t,locale
		} = useI18n()
        // 数据
        const State = reactive({
            query: {
                id: 1, //交易区，默认传1
                type: localStorage.getItem('indextab') ? localStorage.getItem('indextab') : 0, //0 货币 1 股票 2贵金属 3外汇
				p:1,
			},
            list: [],
            isBlack: 'true',//默认黑色（缓存内的值是字符串）
			loading: false,
			tongzhinum:0,
			
			setlang:'en',
			
			dataloading:false,
			noMore:false,
			disabled:false,
			
			datap:0,
			alldatapage:0,
			
			refreshing:false,
			finished:false,
			vantloading:false,
        });

        // 方法
        const Function = reactive({
			datasload(){
				let xiayiye = State.datap + 1
				if(State.alldatapage != 0){
					if(xiayiye > State.alldatapage){
						State.finished = true
						return false
					}
				}
				Function.getData(xiayiye)
			},
            setTheme() {
                //console.log('主题模式', localStorage.getItem('isBlack'))
                State.isBlack = 'true';
                if (localStorage.getItem('isBlack')) {
                    State.isBlack = localStorage.getItem('isBlack')//true代表黑色主题，false代表白色主题。。。 缓存内的值是字符串
                }
				getmsgunread().then(res => {
					//console.log('通知数量',res.data)
					State.tongzhinum = res.data.url
				})
				/*
				userlogin().then(res => {
					//console.log(res.data)
					if(res.data.info==1){
						//localStorage.setItem('username', State.param.username)
						// router.push({
						//     path: "/tab1"
						// });
						if(localStorage.getItem('username')){
						} else {
							localStorage.setItem('username', res.data.url.username)
						}
					} else {
						router.push({
						    path: "/empty_wallet"
						});
					}
				}).catch((error) => {
					//console.log(error.message)
					showToast(error.message)
				})
				*/
            },
			quehuan(type){
				State.query.type = type
				localStorage.setItem('indextab', State.query.type)
				State.finished = false
				State.datap = 0,
				State.alldatapage = 0,
				Function.getData(1,1)
			},
            getData(datapages,isqiehuan) {
				//console.log(State.query)
        //       kline({symbol: 'btcusdt',period: '1min',size: 50})
              //State.loading = true
			  State.query.p = datapages
			  State.datap = datapages
              kline(State.query).then(res => {
                    const {
                        data
                    } = res;
                    //State.list = data.url
					if(isqiehuan==1){
						State.list = []
						data.url.forEach((item, index) => {
						    State.list.push(item)
						})
					} else {
						data.url.forEach((item, index) => {
						    State.list.push(item)
						})
					}
					State.alldatapage = data.info.pages
					State.vantloading = false
                    if (State.list.length > 0) {
                        nextTick(() => {
                            State.list.forEach((item, index) => {
                                Function.setEcharts(item, index)
                            })
                        })
                    }
					//State.loading = false
                })
            },
			leftmenu(){
				// 获取子组件实例
				//console.log(this.$refs.menu)
				const childInstance = this.$refs.menu;
				//console.log(childInstance)
				// 直接修改子组件中的 data 数据
				childInstance.showLeft = !childInstance.showLeft;
			},
			gotonotifiCations(){
				router.push({
				    path: '/notifiCations',
				})
			},
            toInfo(coin,type) {
                router.push({
                    path: '/marketInfo',
                    query: {
                        coin,
						type
                    }
                })
            },
            setEcharts(item, index) {
                var x = []
                var y = []
                if (item && item.k && item.k.length > 0) {
                    item.k.forEach(itemdata => {
                        x.push(itemdata.time)
                        y.push(itemdata.value)
                    })
                    //console.log(item)
                    let colors = '#5fc88f'
                    if (item[6] > 0) {
                        colors = '#ff6464'
                    }
                    let myChart = echarts.init(document.getElementById("myChart" + index));

                    // 绘制图表
                    let option = {
                        xAxis: {
                            data: x,
                            show: false
                        },
                        yAxis: {
                            show: false,
                            splitLine: {
                                show: false
                            },
                            axisLine: {
                                show: false
                            },
                            min: function (value) {
                                return value.min;
                            },
                          max:function (value) {
                            return value.max
                          }
                        },
                        tooltip: {
                            show: false
                        },
                        series: [{
                            type: 'line',//折线
                            symbolSize: 0,
                            smooth: false,//是否曲线显示
                            data: y,
                            lineStyle: {//线条的样式
                                width: 2,
                                color: colors
                            },
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0,
                                        // color: colors // 0% 处的颜色
                                        color: 'rgba(6,37,55,0)' // 0% 处的颜色
                                    }, {
                                        offset: 1,
                                        color: 'rgba(6,37,55,0)' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                }
                            }
                        }]
                    }



                    myChart.setOption(option);

                }

            }
        });
        // 接口
        const Interface = reactive({});

        onMounted(() => {
            //Function.getData(1)
            Function.setTheme()

        });
        return {
            ...toRefs(State),
            ...toRefs(Function),
            ...toRefs(Interface),
        }
    },
}
</script>

<style scoped lang="scss">
.homePage {
    background-color: #000;
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;

  .header-box{
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 0 20px;
	
	.hongdian{
		width: 7px;
		height: 7px;
		background: red;
		border-radius: 50px;
		position: absolute;
		right: 0px;
		bottom: 0px;
	}
  }
  .top-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(rgba(150, 234, 99, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
    .crpto{
      margin-top: 40px;
      font-family: Blanc Groove, Blanc Groove;
      font-weight: 900;
      // font-size: 48px;
	  font-size: 45px;
      color: #FFFFFF;
      line-height: 48px;
    }
    .start{
      margin-top: 24px;
      font-family: Manrope, Manrope;
      font-weight: 400;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 20px;
    }
    .market-text{
      margin-top: 43px;
      margin-bottom: 24px;
      font-family: Manrope, Manrope;
      font-weight: bold;
      font-size: 40px;
      color: #FFFFFF;
      line-height: 48px;
    }
  }
	.qiehuan{
		width: 90%;
    height: 40px;
		margin: 0 auto;
		color: #ffffff;
		backdrop-filter: blur(15px);
		display: flex;
		align-items: center;
		margin-bottom: 24px;
		padding: 2px;
    border-radius: 8px;
    border: 1px solid rgba(255,255,255,0.3);
    .item{
      height: 100%;
      flex: 1;
      font-weight: 400;
      font-size: 10px;
      color: #FFFFFF;
      line-height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

		.action {
      background: #96EA63;
      border-radius: 8px;
      font-weight: bold;
      color: #333333;
		}
	}
	.list {
	    height: 45px;
	    display: flex;
	    align-items: center;
	    justify-content: space-between;
		width: 90%;
		margin: 0 auto;
		margin-bottom: 24px;
	    
		.flexbox {
	        display: flex;
	        align-items: center;
	    }
	
	    .nowNum {
	        display: flex;
	        flex-direction: column;
	        justify-content: center;
	        color: #fff;
	        align-items: flex-end;
	
	        .money {
	          font-family: Manrope, Manrope;
	          font-weight: bold;
	          font-size: 16px;
	          color: #FFFFFF;
	          line-height: 19px;
	          margin-bottom: 3px;
	        }
	
	        .desc {
	            font-size: 14px;
	            font-family: Helvetica;
	            color: #FFFFFF;
	            display: flex;
	            align-items: center;
	          .hour{
	            width: 23px;
	            height: 12px;
	            margin-right: 4px;
	            display: flex;
	            justify-content: center;
	            align-items: center;
	            background: rgba(255,255,255,0.2);
	            border-radius: 4px;
	            padding: 2px;
	            font-weight: 400;
	            font-size: 8px;
	            color: #FFFFFF;
	          }
	          .up-down{
	            font-weight: bold;
	            font-size: 10px;
	            line-height: 12px;
	          }
	          .red{
	            color: #EA6363;
	          }
	          .green{
	            color: #96EA63;
	          }
	        }
	    }
	
	    .marketIcon {
	        width: 45px;
	        margin-right: 13px;
	        border-radius: 5px;
	    }
	
	    .textVal {
	        display: flex;
	        flex-direction: column;
	        justify-content: center;
	        color: #fff;
	        min-width: 45px;
	
	        .name {
	            margin-bottom: 4px;
	            font-weight: bold;
	            font-size: 16px;
	            color: #FFFFFF;
	            line-height: 19px;
	        }
	
	        .desc {
	            font-weight: 400;
	            font-size: 14px;
	            color: #6C757D;
	            line-height: 16px;
	        }
	    }
	
	    .graph {
	        height: 40px;
	        width: auto;
	    }
	}
    .market {
        width: 90%;
		height: 800px;
        backdrop-filter: blur(15px);
        margin: 0 auto;
        //overflow: hidden;
		overflow-y: auto;

        .list {
            height: 45px;
            display: flex;
            margin-bottom: 24px;
            align-items: center;
            justify-content: space-between;

            .flexbox {
                display: flex;
                align-items: center;
            }

            .nowNum {
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: #fff;
                align-items: flex-end;

                .money {
                  font-family: Manrope, Manrope;
                  font-weight: bold;
                  font-size: 16px;
                  color: #FFFFFF;
                  line-height: 19px;
                  margin-bottom: 3px;
                }

                .desc {
                    font-size: 14px;
                    font-family: Helvetica;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                  .hour{
                    width: 23px;
                    height: 12px;
                    margin-right: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: rgba(255,255,255,0.2);
                    border-radius: 4px;
                    padding: 2px;
                    font-weight: 400;
                    font-size: 8px;
                    color: #FFFFFF;
                  }
                  .up-down{
                    font-weight: bold;
                    font-size: 10px;
                    line-height: 12px;
                  }
                  .red{
                    color: #EA6363;
                  }
                  .green{
                    color: #96EA63;
                  }
                }
            }

            .marketIcon {
                width: 45px;
                margin-right: 13px;
                border-radius: 5px;
            }

            .textVal {
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: #fff;
                min-width: 45px;

                .name {
                    margin-bottom: 4px;
                    font-weight: bold;
                    font-size: 16px;
                    color: #FFFFFF;
                    line-height: 19px;
                }

                .desc {
                    font-weight: 400;
                    font-size: 14px;
                    color: #6C757D;
                    line-height: 16px;
                }
            }

            .graph {
                height: 40px;
                width: auto;
            }
        }
    }
}

.whiteBox {
    background-color: #fff;

    .market {
        .list {
            .nowNum {
                .money {
                    color: #000;
                }

                .desc {
                    color: #000;

                }
            }

            .textVal {
                .name {
                    color: #000;

                }
            }
        }

        .list:nth-child(1),
        .list:nth-child(2) {
            .nowNum {
                .money {
                    color: #fff;
                }

                .desc {
                    color: #fff;

                }
            }

            .textVal {
                .name {
                    color: #fff;
                }
            }
        }
    }
}
</style>
