export default {
	"830753-0": "Recargar",
	"830753-1": "Realizar recarga de activos rápidamente",
	"830753-2": "Por favor, suba el comprobante",
	"830753-3": "Confirmar recarga",
	"830753-4": "Datos de respuesta del servidor incorrectos:",
	"830753-5": "Error al cargar la imagen:",
	account: {
		Start_making_money_plan: "Empieza a hacer un plan de dinero",
		Market: "Mercado",
		Migital_currency: "Moneda digital",
		Foreign_exchange: "Divisas",
		Noble_metal: "metal noble",
		Send_Cryop_Now: "Envía Cryop ahora",
		Send: "Enviar",
		Receive: "Recibir",
		Buy: "Comprar",
		Currency_account: "Cuenta corriente",
		Contract_account: "Cuenta de contrato",
		Transfer: "Transferir",
		Select_a_wallet: "Seleccione una billetera",
		Select_Coin: "Seleccionar moneda",
		Confirm: "Confirmar",
		Wallet: "Billetera",
		Availavle: "Disponible",
		Frozen: "Congelado",
		Convert: "Convertir",
		Receiving_Address: "Dirección de recepción",
		Amount: "Cantidad",
		Max: "Máximo",
		Send_Now: "Enviar ahora",
		Please_check_if: "Verifique si su dirección de entrega es correcta antes de enviar para evitar la pérdida de activos.",
		new1:'criptomoneda',
		new2:'índice',
		new3:'A granel',
		new4:'Forex',
		new5:'saldo total',
	},
	"314962-0": "Google 2FA",
	"314962-1": "Descarga Google Authenticator para vincular",
	"314962-2": "Copiar código de verificación",
	"314962-3": "Introduce el código de verificación",
	"314962-4": "Solicitud fallida:",
	"314962-5": "Código de verificación copiado",
	"314962-6": "No se puede copiar el texto:",
	"314962-7": "Copia fallida, por favor inténtalo de nuevo",
	"199917-0": "Configuración",
	"199917-1": "Notificaciones",
	"199917-2": "Google 2FA",
	"199917-3": "Correo electrónico",
	"199917-4": "Idioma",
	"199917-5": "Versión",
	"199917-6": "Cerrar sesión",
	"516529-0": "Configuración de la cuenta",
	"516529-1": "Entender su información de cuenta",
	"516529-2": "Soporte en línea",
	"516529-3": "Compartir la aplicación",
	"516529-4": "Configuración básica",
	"516529-5": "Configuración del correo electrónico",
	"516529-6": "Cambiar idioma",
	"516529-7": "Alerta de precios",
	"516529-8": "Legal e información",
	"516529-9": "Términos de servicio",
	"516529-10": "Política contra el lavado de dinero",
	"516529-11": "Política de cookies",
	"516529-12": "Obtener respuestas y ayuda",
	"516529-13": "Empezando",
	"516529-14": "Intercambiar tokens",
	"516529-15": "Solución de problemas",
	"516529-16": "Recomendar a amigos",
	"516529-17": "Compartir con amigos y obtener comisiones por referencias",
	"516529-18": "Copiar su enlace",
	"516529-19": "Configure su correo electrónico para recibir actualizaciones del mercado",
	"516529-20": "Verificar ahora",
	"516529-21": "Ingrese su correo electrónico...",
	"516529-22": "Ingrese el código de verificación...",
	"516529-23": "Su correo electrónico de suscripción solo se utilizará para información útil o recordatorios relacionados con nuestros servicios y no será mal utilizado.",
	"516529-24": "Guardar",
	"516529-25": "Seleccione su idioma de sitio web",
	"516529-26": "Estamos comprometidos a proteger la seguridad y privacidad de sus datos",
	"516529-27": "Chino tradicional",
	"516529-28": "Japonés",
	"516529-29": "Enviar código de verificación",
	"516529-30": "¡Copiado con éxito!",
	"516529-31": "Reenviar",
    home:{
        USDTAccount: 'USDT Account',
        USTD: 'USDT',
        USDT: 'USDT',
        USTC: 'USDC',
        open: 'Open',
        ETHTodayProfit: 'ETH Today\'s Profit',
        totalRevenue: 'Total Revenue',
        portfolio: 'Portfolio',
        reliableSecurity: 'Reliable Security Guarantee',
        stableReliable: 'Stable and Reliable Investment',
        convenienEasy: 'Convenien and Easy Operation',
        pleaseLookForward: 'Please look forward to...',
        rateOfReturn: 'Rate of Return',
		walletnot: 'Please install MetaMask or any Ethereum Extension Wallet',
		wallettip: 'Please bind your address in order not to affect the experience',
    },
	"ConnectW":"Connect Wallet",
	"dappbrowser":"This website only accepts access from decentralized wallet DAPP browsers.",
    "Coins POS": "CoinB Pos",
    "9dcf43fa47": "9dcf43fa47",
    "Earn Interest": "Earn Interest",
    "$": "$",
    "ETH Today's Profit": "ETH Today's Profit",
    "Wallet": "Wallet",
    "Record": "Record",
    "Support": "Support",
    "General": "General",
    "Notifications": "Notifications",
    "Invite Friends": "Invite Friends",
    "FAQ": "FAQ",
    "Privacy & Security": "Privacy & Security",
    "Legality": "Legality",
	"Authenticator":"Authenticator",
	"Settings":"Settings",
	"Change Language":"Change Language",
	"Daytime mode":"Daytime mode",
	"Black mode":"Black mode",
    "BTC": "BTC",
    "Bitcoin": "Bitcoin",
    "ETH": "ETH",
    "ethereum": "ethereum",
    "USDT": "USDT",
    "tether": "tether",
    "USDC": "USDC",
    "usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'Open',
    'High':'High',
    'Low':'Low',
    'Close':'Close',
    'Up':'Up',
    'Down':'Down',
    'Time':'Time',
    'TransactionFee':'Transaction Fee',
    'AvailableBalance':'Available Balance',
    'submit':'Subscribe',
    'Direction':'Direction',
    'Profit':'Profit',
    'Quantity':'Quantity',
    'Price':'Price',
    'different':'Click on different ratios to display the lowest limit',
    'lessthan':'At least cannot be less than',
    'least':'least：',
    'Transactionhistory':'Transaction history',
    'Transactionrecords':'Transaction records',
    'Number':'Number',
    'Profitloss':'Profit loss',
	'Ror':'Ror',
	'operate':'operate',





    //钱包
    TransactiónDetails:'Transactión Details',
	dealdetails:'deal details',
    ViewTransactionHistory:'View Transaction History',
    Deposit:'Deposit',
    Rechargeaddress:'Recharge address：',
    DepositAmount:'Deposit Amount：',
    EnterDepositAmount:'Enter Deposit Amount',
    EnterHash:'Enter Hash',
    Hash:'Hash：',
    Voucherimage:'Voucher image：',
    Continue:'Continue',
    Withdraw:'Withdraw',
    Withdrawal:'Withdrawal：',
    EnterAmounttoWithdraw:'Enter Amount to Withdraw',
    Available:"Available：",
    ReceiveAddress:'Receive Address：',
    EnterWalletAddress:'Enter Wallet Address',
    WithdrawalDesc:'Withdrawal in English requires a 1% handling fee, which needs to be confirmed by the network nodebefore it can be credited Please do not transfer cryptocurrency to strangers',
    Exchange:'Exchange',
    Send:'Send:',
    Max:'Max:',
    rechargeAmount:'Please input a recharge amount',
    hashcode:'hash code and screenshot cannot be empty at the same time',
    correcthashcode:'please input a correct hash code',
    maximumwithdrawalamount:'The maximum withdrawal amount is',
    Incorrectamountformat:'Incorrect amount format',
	copy:'copy',


	//tab2
	"Total Profit":"Total Profit",
	"Today's Profit":"Today's Profit",
	"What is Automated Trading?":"What is Automated Trading?",
	"Learn about earning":"Learn about earning",
	"Staking Period":"Staking Period",
	"Staking Amount":"Staking Amount",
	"Yield":"Yield",
	"Limit":"Limit",
	"Subscribe":"Subscribe",
	"Record":"Record",

	//tab3
	"Proof of Stake":"Proof of Stake",
	"ETH Today is Price":"ETH Today is Price",
	"ETH Today's Increase":"ETH Today's Increase",
	"Joint Staking":"Joint Staking",
	"Individual Staking":"Individual Staking",
	"tab3long1":"Joint staking is achieved by matching participants through smart contracts to reach a total staking amount of 32 ETH.",
	"tab3long2":"Independent staking does not require matching participants through smart contracts. By reaching a staking amount of 32 ETH, one can complete individual staking",


	//web_view
	"webviewlong1":"Cryptocurrency airdrops help emerging blockchain-based projects grow. Their roots connect community members with rewards and common goals. The outcome of a successful airdrop is a stronger community, better projects, and rewards for end users. Over the years, we have seen many airdrops delivered on proof-of-work (POW) blockchains. The proof-of-stake (POS) Ethereum chain now supports hundreds of project cryptocurrency airdrops across dozens of networks. With PoS becoming the dominant consensus paradigm, the number of airdrops on the Ethereum network shows no signs of slowing down.",
	"webviewlong2":"By staking your cryptocurrencies, you can be eligible to unlock valuable airdrop rewards based on the amount of cryptocurrencies you have staked.",
	"webviewlong3":"Reward equity is only available to stake owners, it comes from hundreds of different project airdrops, and its yield is much higher than any one independent airdrop.",
	"webviewlong4":"Airdrop rewards are often difficult for cryptocurrency owners depositing on exchanges, and some custodians do not support airdrops at all. This is why it is important to manage your own cryptocurrencies. Ethereum is a non-custodial entity that can be staked across all major networks",
	"Introduction":"Introduction",
	"webviewlong5":"Ethereum, a leading blockchain platform, is transitioning from a Proof of Work (PoW) consensus mechanism to a Proof of Stake (PoS) mechanism through its Ethereum 2.0 upgrade. PoS staking is an essential component of this transition, providing users with an opportunity to support network security and operations while potentially earning rewards. This article provides an overview of Ethereum PoS staking and its benefits.",
	"Ethereum 2.0 and Proof of Stake (PoS)":"Ethereum 2.0 and Proof of Stake (PoS)",
	"webviewlong6":'Ethereum 2.0 is a major network upgrade aimed at improving scalability, security, and sustainability. One of the key changes is the shift from the energy-intensive PoW consensus mechanism to the more environmentally friendly PoS mechanism. In PoS, validators are chosen to create new blocks and confirm transactions based on the amount of cryptocurrency they hold and are willing to "stake" as collateral.',
	"The Staking Process":"The Staking Process",
	"webviewlong7":"To participate in Ethereum PoS staking, users must stake a minimum of 32 ETH by depositing it into the Ethereum 2.0 deposit contract. Once staked, the ETH is locked for a certain period and serves as collateral to secure the network. Validators are chosen based on the amount of staked ETH and their online activity. They are responsible for proposing and validating new blocks, as well as confirming transactions on the network.",
	"Staking Rewards and Risks":"Staking Rewards and Risks",
	"webviewlong8":"By participating in PoS staking, users can earn rewards in the form of newly minted ETH and transaction fees. The rewards are based on the amount of ETH staked and the overall network activity. However, staking also comes with risks, such as the potential for penalties if a validator behaves maliciously or fails to stay online consistently. Additionally, the staked ETH is locked for a certain period, making it illiquid and subject to price fluctuations.",
	"Joint Staking and Individual Staking":"Joint Staking and Individual Staking",
	"webviewlong9":"For users who do not have the required 32 ETH to stake individually, joint staking offers an alternative. In joint staking, multiple users pool their ETH together to reach the required staking amount. This pooling is typically facilitated through smart contracts or staking services, allowing users to participate in Ethereum PoS staking with smaller amounts of ETH.",
	"Conclusion":"Conclusion",
	"webviewlong10":"Ethereum PoS staking is a significant aspect of the Ethereum 2.0 upgrade, offering users the opportunity to support network security, improve decentralization, and earn rewards. By understanding the staking process, its rewards, and risks, users can make informed decisions about participating in this crucial evolution of the Ethereum network.",


	//mywallet
	"Total":"Total",

	//record
	"Account":"Account",
	"interest-bearing":"interest-bearing",
	"POS Staking":"POS Staking",
	"convert":"convert",
	"transaction":"transaction",
	"pledge":"pledge",
	"recordno":"You don't have any news yet. The list is empty.",
	"Oops":"Oops",
	"recharge":"recharge",
	"payment":"payment",
	"staking":"staking",
	"income":"income",
	"Pledge quantity":"Pledge quantity",
	"cycle":"cycle",
	"Days Remaining":"Days Remaining",
	"cumulative gain":"cumulative gain",
	"Price increase":"Price increase",
	"Price decrease":"Price decrease",
	"Opening price":"Opening price",
	"Closing price":"Closing price",
	"loss":"loss",
	"pfofit":"pfofit",
	"amount":"amount",

	//notifiCations
	"notifiCationslong1":"Note: VIP registration is based on the recharge amount of the corresponding overall account to determine your level. The interval needs to deduct the total recharge amount after the withdrawal of the existing account to determine the membership level.",
	"notifiCationslong2":"The membership duration is also divided according to different levels. To maintain your membership status, you need to recharge at least 5,000 USDT each time within the specified time to enjoy the privileges of membership. After each recharge of the membership status, the cycle is recalculated and can be accumulated.",
	"notifiCationslong3":"Customer service is divided based on membership levels. Official customer service is provided by APP staff and offers unified service to the majority of members. The number of exclusive secretaries is limited, ensuring better service. Exclusive personal secretaries provide users with dedicated reception services and financial advice.",
	"notifiCationslong4":"Contact customer service in advance to make an appointment for recharge. Complete the recharge within the specified time to receive additional rewards for appointment recharge based on the corresponding membership level.",
	"notifiCationslong5":"VIP1: Based on the UID account, accumulate a total recharge of 10,000 USD and receive 177 USDT as a bonus. After reaching the VIP level and obtaining the General Member Medal, the points will be airdropped to the account.",
	"notifiCationslong6":"VIP2: Based on the UID account, cumulative recharge reaches 30,000 USD, and 777 USDT will be given away. The bonus amount will be credited to the account immediately after reaching VIP level and obtain the Bronze Member Medal.",
	"notifiCationslong7":"VIP3: Based on the UID account, accumulate a total of $70,000 in recharge and receive 1777 USDT as a gift. After reaching VIP level and obtaining the Silver Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 1.5% reward on the next booking recharge amount.",
	"notifiCationslong8":"VIP4: Based on the UID account, accumulate a total recharge of $150,000 and receive 2,777 USDT. After reaching VIP level and obtaining the Gold Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 2% reward on the next booking recharge amount.",
	"notifiCationslong9":"VIP5: Based on the UID account, cumulative recharge reaches 300,000 USD, and 4777 USDT will be given as a gift. After reaching VIP level and obtaining the Diamond VIP badge, points will be airdropped to the account. Additionally, the next booking recharge amount will receive an additional 2.5% bonus.",
	"notifiCationslong10":"VIP6: Based on the UID account, cumulative recharge reaches 800,000 USD, and 16,888 USDT will be given as a bonus. The bonus amount will be airdropped to the account immediately after reaching the VIP level and obtaining the Black Diamond membership badge, and an additional 3% of the next pre-registered recharge amount will be obtained. You will have a private exclusive secretary to provide any financial advice or handle tax issues.",
	"notifiCationslong11":"VIP7: Based on the UID account, the cumulative recharge amount reaches 3 million US dollars, and a 15-day luxury trip to Europe (with discounts) will be given. CoinBpos global lifetime honorary VIP will be awarded and the crown member medal will be obtained. Extra 3.5% bonus will be given for the next appointment recharge.",
	"notifiCationslong12":"VIP8: According to the UID account, if the cumulative recharge reaches 8 million US dollars, you can get one CoinBpos Morocco Annual Banquet voucher. Reward: 1 BTC, and obtain CoinBpos Global Lifetime Honor VIP. Next appointment recharge can receive an additional 4% bonus.",
	"notifiCationslong13":"VIP9: Based on the UID account, cumulative deposits reach 30 million US dollars, you can get 3% of CoinBpos shares. And enjoy a 3% annual profit dividend. Free gift of Cronix pure gold commemorative badge.",

	//invite
	"Refer and Earn":"Refer and Earn",
	"Copy Link":"Copy Link",

	//faq
	"Frequently Asked Questions":"Frequently Asked Questions",

	//authenticator
	"mailbox":"mailbox",
	"Front photo":"Front photo",
	"Back photo":"Back photo",
	"Handheld ID photo":"Handheld ID photo",
	"define":"define",

	//legality
	"legalitylong1":"Coinbpos, composed of multiple cryptocurrency companies, together they provide tools to help the encryption community create, grow, maintain community stability, power thousands of decentralized applications, promote the future of cryptocurrency as well as promote the digitization of traditional currencies and to promote global economic prosperity.",
	"legalitylong2":"Each company within Coinbpos acts as a data controller for the processing of personal data related to its services, the data controller for each service is:",
	"legalitylong3":"1. Blockchain Technology - Ethereum Blockchain.",
	"legalitylong4":"2.Decentralized Wallet - Trust Wallet, MetaMask and Crypto Wallet.",
	"legalitylong5":"3. Trading Technology-Coinbase, Crypto, linch and Binance.",
	"legalitylong6":"Privacy Policy details how Coinbpos processes personal data in accordance with its obligations under relevant data protection laws, including The European Union's General Data Protection Regulation (GDPR),",
	"legalitylong7":'The California Consumer Privacy Act (CCPA) and other General Laws (collectively referred to as "Data Protection Laws*).',

	//language
	"language":"language",
	"Confirm":"Confirm",

	"newtext1":"Sign out",
	"newtext2":"Cancel",
	"newtext3":"Are you sure you want to log out of this account?",

	"newtext4":"中文简体",
	"newtext5":"中文繁体",
	"newtext6":"English",
	"newtext7":"日本語",

	"newtext8":"Login",
	"newtext9":"username",
	"newtext10":"Mobile phone number",
	"newtext11":"Please enter user name",
	"newtext12":"Please enter your mobile phone number",
	"newtext13":"password",
	"newtext14":"Please enter password",
	"newtext15":"No account yet",
	"newtext16":"Register",
	"newtext17":"Country",
	"newtext18":"Select a country",
	"newtext19":"Mobile phone number verification code",
	"newtext20":"Please enter the verification code",
	"newtext21":"Email address",
	"newtext22":"Please enter your email address",
	"newtext23":"Confirm password",
	"newtext24":"Please enter the confirmation password",
	"newtext25":"Have an account",
	"newtext26":"Send verification code",
	"newtext27":"Mobile phone number is incorrect",
	"newtext28":"Sent successfully",
	"newtext29":"Registration successful",

	"newtext30":'currency',
	"newtext31":'Forex',
	"newtext32":'precious metals',

	"newtext33":'Cuenta',
	"newtext34":'Actividades de la plataforma',
	"newtext35":'Servicio al Cliente',
	"newtext36":'invitación',
	"newtext37":'verificación KYC',
	"newtext38":'configuración',
	wallet_p:{
		text1: "Copiar dirección",
		text2: 'Confirmar',
		text3: 'Intercambiar desde',
		text4: "Intercambiar a",
		text5: "MAX",
		text6: "Invitar",
		text7: 'Obtener recompensas por referidos',
		text8: "Cuando tus amigos participan en arbitraje de IA y alquiler de máquinas mineras, puedes obtener recompensas por referidos",
		text9: "copiar",
		text10: 'Compartir enlace',
		text11: "Verificación KYC",
		text12: "País",
		text13: "Seleccione un país",
		text14: "Nombre",
		text15: "Ingrese su nombre",
		text16: "Apellido",
		text17: "Ingrese su apellido",
		text18: "Tipo de certificado",
		text19: "Seleccione el tipo de certificado",
		text20: "Número de certificado",
		text21: "Ingrese el número de certificado",
		text22: "Cargue una foto de la parte frontal de su documento de identidad",
		text23: "Cargue una foto de la parte posterior de su documento de identidad",
		text24: "Cargue una foto de la parte frontal de su pasaporte",
		text25: "Cargue una foto de la parte posterior de su pasaporte",
		text26: "Correo electrónico",
		text27: "Ingrese su correo electrónico",
		text28: "enviar",
		text29: "Código de verificación",
		text30: "Ingrese su código de verificación",
		text31: "DNI",
		text32: "pasaporte",
		text33: "cancelar",
		text34: "País/Región",
		text35: "Ingrese la información correcta para enviar la autenticación",
		text36: "¡Enviado correctamente!",
		text37: "¡Error en el formato de correo electrónico!",
		text38: "Licencia de conducir",
		text39: "Sube una foto del frente de tu licencia de conducir.",
		text40: "Sube una foto del reverso de tu licencia de conducir.",
		
		text41: "número de identificación",
		text42: "Por favor ingrese su número de identificación",
		text43: "Número de pasaporte",
		text44: "Por favor ingrese el número de pasaporte",
		text45: "número de licencia de conducir",
		text46: "Por favor ingrese su número de licencia de conducir",
	},
	jiaoyi:{
		text1:'Segundo Contrato',
		text2:'Contrato perpetuo',
		text3:'Transacción al contado',
		text4:'Delegar inmediatamente',
		text5:'Tipo de cuenta',
		text6:'tiempo de entrega',
		text7:'Rango de precios',
		text8:'Precio de compra',
		text9:'costo',
		text10:'Saldo',
		text11:'al menos',
		text12:'esperado',
		text13:'Monto de la compra',
		text14:'dirección',
		text15:'Precio de compra',
		text16:'Precio en tiempo real',
		text17:'Cuenta regresiva',
		text18:'Mi contrato',
		text19:'Beneficio',
		text20:'Contrato',
		text21:'precio de entrega',
		text22:'tiempo de entrega',
		text23:'Precio de mercado',
		text24:'en ascenso',
		text25:'caída',
		text26:'Precio de compra',
		text27:'máximo',
		text28:'Beneficio',
		text29:'tarifa de gestión',
		text30:'Comprar',
		text31:'Vender',
		text32:'Cantidad de compra',
		text33:'cuenta real',
		text34:'Cuenta Virtual',
		text35:'Aún no hay datos',
		text36:'Posición actual',
		text37:'Comisión histórica',
		text38:'Cerrar posición',
		text39:'Margen',
		text40:'Pérdidas y ganancias',
		text41: 'mantener',
		text42:'Registro de compra',
		text43:'Venta de récord',
		text44:'Disponible',
		text45:'convertido',
		text46:'Autenticación exitosa',
		text47:'tiempo de compra',
		text48:'no leído',
		text49:'Leer',
		text50:'Orden de anfitrión',
		text51:'arbitraje total',
		text58:'Ganancias de hoy',
		text52:'Cómo funcionan los robots con inteligencia artificial',
		text53:'producto de arbitraje',
		text54:'día',
		text55:'Cantidad',
		text56:'Ingreso diario',
		text57:'Tipo de moneda',
		text59:'Únete al arbitraje de IA',
		text60:'Riesgo cero, retornos rápidos',
		text61:'arbitraje de IA',
		text62:'Ganancias esperadas',
		text63:'Tipo de moneda de arbitraje',
		text64:'Monto del depósito en garantía',
		text65:'Anfitrión ahora',
		text66:'Ingreso diario enviado a su billetera USDT',
		text67:'Fondo de Inversión Riesgo Cero',
		text68:'Puedes retirar tus fondos en cualquier momento',
		text69:'La inteligencia artificial funciona las 24 horas del día',
		text70:'Ingresos acumulados',
		text71:'Tiempo de vencimiento',
		texto72:'todos',
		text73:'En progreso',
		text74:'Completo',
		text75:'Detalles de alojamiento',
		text76:'Seleccionar moneda',
		text77:'Apoyo técnico',
		text78:'Enviar correo electrónico',
		text79:'Tipo de transacción',
		
		text80:'Cargando...',
		text81:'No más',
		text82:'Libro Blanco',
	},

	swapadd:{
		text1:'Política de cookies',
		text2:'Estamos comprometidos a proteger la seguridad y privacidad de sus datos',
		text3:'Introducción',
		text4:'Esta Política de cookies explica cómo nuestro intercambio descentralizado utiliza cookies y tecnologías de seguimiento similares. Al acceder o utilizar Exchange, acepta el uso de Cookies como se describe en esta Política. ',
		text5:'¿Qué son las cookies? ',
		text6:'Las cookies son pequeños archivos de texto que se colocan en su dispositivo (ordenador, teléfono móvil o tableta) cuando visita un sitio web. Se utilizan ampliamente para mejorar su experiencia de navegación y proporcionar funciones de personalización en sitios web. Las cookies pueden ser cookies de sesión (que son temporales y se eliminan cuando cierra su navegador) o cookies persistentes (que permanecen en su dispositivo por un período de tiempo más largo). ',
		text7:'Cómo utilizamos las cookies',
		text8: 'Los fines para los cuales los intercambios utilizan cookies incluyen, entre otros:',
		text9:'Habilitar funciones y características básicas del intercambio',
		text10:'Analizar y mejorar el rendimiento y la funcionalidad del intercambio',
		text11:'Personaliza tu experiencia y recuerda tus preferencias',
		text12:'Rastree y comprenda sus patrones de uso en el intercambio',
		text13:'Proporcionar comunicaciones publicitarias y de marketing relevantes',
		text14:'Tipos de cookies que utilizamos',
		text15:'a) Cookies esenciales: estas cookies son necesarias para el funcionamiento del intercambio y le permiten acceder y utilizar sus funciones. Sin estas Cookies, es posible que algunos servicios no estén disponibles para usted. ',
		text16:'b) Cookies de análisis: estas cookies recopilan información sobre cómo utiliza el intercambio, como las páginas que visita y los errores que encuentra. Utilizamos esta información para analizar y mejorar el rendimiento y la funcionalidad de Exchange. ',
		text17:'c) Cookies de funcionalidad: estas cookies permiten que Exchange recuerde sus elecciones (como las preferencias de idioma) y proporcionen una funcionalidad mejorada y más personalizada. ',
		text18:'d) Cookies publicitarias: estas cookies se utilizan para proporcionar publicidad relevante para usted y sus intereses. También pueden utilizarse para limitar el número de veces que ve un anuncio y medir la eficacia de las campañas publicitarias. ',
		text19:'Cookies de terceros',
		text20:'Los intercambios pueden permitir que proveedores de servicios externos coloquen cookies en su dispositivo para diversos fines, incluidos análisis y publicidad. Estos terceros tienen sus propias políticas de privacidad y pueden recopilar información sobre sus actividades en línea en diferentes sitios web o servicios en línea. ',
		text21:'Gestión de cookies',
		text22:'Puedes administrar o eliminar las Cookies en cualquier momento a través de la configuración de tu navegador. Sin embargo, tenga en cuenta que deshabilitar o eliminar ciertas cookies puede afectar la funcionalidad y el rendimiento del intercambio. ',
		text23:'De acuerdo',
		text24:'Al utilizar Exchange, acepta el uso de cookies como se describe en esta política. Si no está de acuerdo con el uso de Cookies, puede desactivarlas o eliminarlas a través de la configuración de su navegador o no utilizar Exchange. ',
		text25:'Actualizar política',
		text26:'Podemos actualizar periódicamente esta Política de Cookies para reflejar cambios en nuestras prácticas o cambios en la ley aplicable. Le recomendamos que revise esta política periódicamente para obtener la información más reciente. ',
		text27:'Si tiene alguna pregunta o inquietud sobre la forma en que utilizamos las cookies, comuníquese con nuestro equipo de atención al cliente. ',

		text28:'Obtenga ayuda y solución de problemas',
		text29:'Comenzando',
		text30:'Tokens de intercambio',
		text31:'Solución de problemas',
		text32:'¿Qué es un intercambio descentralizado? ',
		text33: 'DEX es un intercambio de criptomonedas que se ejecuta en una red blockchain descentralizada y generalmente se basa en contratos inteligentes. A diferencia de los intercambios centralizados tradicionales, CEX depende de una agencia central para facilitar las transacciones y retener los fondos de los usuarios, DEX puede comerciar directamente con ambas partes a través de. contratos inteligentes. ',
		text34:'Seguridad de los intercambios descentralizados',
		text35: 'DEX opera utilizando contratos inteligentes en blockchain, lo que permite transacciones directas entre compradores y vendedores, eliminando la necesidad de instituciones de terceros. DEX se ejecuta en una cadena de bloques pública, las transacciones son transparentes y auditables, y cualquiera puede verificar la equidad e integridad del DEX. ',
		text36:'¿Qué es una billetera descentralizada? ',
		text37: 'Una billetera descentralizada es una billetera de criptomonedas que consta de 12 claves. Los usuarios pueden usar 12 claves para iniciar sesión en la billetera usando la clave en cualquier misma cadena de bloques para acceder a los activos digitales. Mientras la clave no se filtre, nadie puede obtener los activos digitales en la billetera descentralizada.',
		text38:'¿Qué es una dirección de billetera? ',
		text39: 'La dirección de la billetera de criptomonedas es una clave pública obtenida de una clave privada mediante el algoritmo de firma de curva elíptica. La clave pública es una dirección de billetera obtenida a través de una serie de conversiones. Se utiliza una operación hash irreversible durante el proceso de conversión. La dirección se utiliza principalmente para enviar y recibir criptomonedas. ',
		text40:'Seguridad al usar DEX para intercambiar fondos',
		text41: 'El mecanismo operativo de DEX se basa en la cadena de bloques. No pasa por instituciones de terceros y adopta un sistema sin custodia para el intercambio directo. Todas las transacciones se ejecutan mediante contratos inteligentes. Todas las transacciones se pueden consultar en el. blockchain, logrando verdaderamente la realización de activos Transparencia, privacidad y seguridad. ',
		text42: '¿Por qué hay una diferencia de precio para el mismo token en el intercambio? ',
		text43: 'Oferta y demanda del mercado: el intercambio proporciona la compra y venta de activos, pero debido a la diferente oferta y demanda del mercado de los principales intercambios, los precios fijados por el intercambio para la compra y venta cambiarán, y la demanda de ciertos tokens es más alto. Por lo general, el precio será más alto que el precio de mercado y el precio será más bajo en los intercambios con menor demanda. ',
		text44: 'Volumen de operaciones: la cantidad de criptomonedas compradas y vendidas en un intercambio, que puede afectar el precio. Un mayor volumen de operaciones generalmente indica una mayor actividad del mercado, lo que puede promover un descubrimiento de precios más preciso. Los intercambios con volúmenes de negociación bajos pueden tener diferenciales de oferta y demanda más amplios y precios más inexactos. ',
		text45: 'Liquidez: se refiere a la facilidad y dificultad de comprar o vender un activo sin causar un cambio significativo en el precio. Las criptomonedas con mayor liquidez tienden a tener precios más consistentes en todos los intercambios. Cuando la liquidez en una bolsa es baja, puede provocar fluctuaciones de precios y grandes diferencias de precios. ',
		text46: 'Selección de tokens: no todos los tokens tienen el mismo potencial de ganancias y diferentes tokens pueden tener distintos grados de liquidez, volumen de operaciones y volatilidad de precios.',
		text47:'¿Qué es un diferencial de tipo de cambio? ',
		text48: 'Los diferenciales de tipos de cambio se refieren a cambios en los precios de las criptomonedas entre diferentes intercambios. Debido a que las criptomonedas están dispersas a nivel mundial y se negocian en una variedad de plataformas, la dinámica de la oferta y la demanda, la liquidez, los volúmenes de negociación y las condiciones del mercado pueden diferir entre las bolsas. ',
		text49:'¿Qué es el intercambio DEX? ',
		text50:'El intercambio DEX, también conocido como intercambio de tokens o intercambio de activos, se refiere al proceso de intercambiar una criptomoneda o token por otra criptomoneda o token a través de un acuerdo de intercambio en DEX. Estas transacciones suelen verse facilitadas por contratos inteligentes en la cadena de bloques, lo que elimina la necesidad de intermediarios. ',
		text51: '¿Por qué han desaparecido los diferenciales en las principales bolsas? ',
		text52:'Cuando no hay diferencia en el precio de los tokens en diferentes intercambios, significa que el mercado es relativamente estable. La razón de esta situación es que los comerciantes del mercado negocian de forma plana y automáticamente arbitran basándose en transacciones desequilibradas, y los gigantes del mercado participan. transacciones para explotar una gran cantidad de financiación para el arbitraje comercial, mantener los precios de los tokens de mercado estables, el volumen y la liquidez de las operaciones, un mayor volumen de operaciones y suficiente liquidez, y la consolidación del mercado son todas razones que hacen que los diferenciales desaparezcan en las bolsas.',
		text53:'¿Cuál es la dirección designada? ',
		text54: 'La dirección especificada es la dirección de recepción de criptomonedas que el usuario debe completar después de que el intercambio sea exitoso. Los usuarios que realizan el intercambio por primera vez deben vincular la dirección de recepción común',
		text55:'¿Qué es el deslizamiento de precios? ',
		text56: 'El deslizamiento de precios, también conocido como deslizamiento, es la diferencia entre el precio esperado de una operación de criptomonedas y el precio al que realmente se ejecuta la operación. El deslizamiento de precios suele ser causado por la volatilidad del mercado, la liquidez, el tamaño de la orden, el tipo de orden, etc. En un mercado volátil y de rápido movimiento como el de las criptomonedas, los precios pueden cambiar rápidamente. ',
		text57:'¿Qué es el GAS de intercambio? ',
		text58: 'GAS es la tarifa de GAS que los usuarios deben pagar a la cadena de bloques al retirar criptomonedas después de un intercambio exitoso. La tarifa de GAS se utiliza para procesar los pedidos de los usuarios y, al mismo tiempo, se transmite en la cadena de bloques para confirmar el éxito de la transacción del usuario. . Normalmente, la comisión de GAS por retirar activos cambiarios es del 0,1%. ',
		text59:'¿Por qué hay órdenes pendientes? ',
		text60: 'Congestión de la red: durante períodos de alta demanda o mayor actividad, las redes blockchain pueden congestionarse. Esta congestión puede provocar tiempos de procesamiento de transacciones más lentos y tarifas más altas. Las grandes fluctuaciones del mercado o los aumentos repentinos de la actividad comercial pueden provocar congestión en la red. ',
		text61: 'Problemas de escalabilidad: algunas redes blockchain enfrentan desafíos de escalabilidad, lo que significa que es posible que blockchain no pueda manejar grandes volúmenes de transacciones de manera rápida y eficiente. Esto puede provocar retrasos y velocidades de transacción más lentas. ',
		text62: 'Restricciones de liquidez: La disponibilidad de compradores y vendedores en el mercado. En algunos mercados de criptomonedas o criptomonedas específicas, la liquidez es limitada. Esta falta de liquidez puede hacer que las transacciones se ralenticen. Puede haber menos participantes comprando o vendiendo a un precio específico, lo que hace que se amplíe el diferencial de oferta y demanda. ',
		text63: 'Tamaño de la orden de mercado: el tamaño de la orden afecta la velocidad de negociación, especialmente cuando se trata de una orden grande en relación con la liquidez disponible. Cuando las órdenes interactúan con la oferta o demanda disponible en el mercado, colocar una gran cantidad de órdenes de compra o venta puede resultar en ejecuciones parciales o una ejecución más lenta, lo que resulta en órdenes pendientes. ',
		text64:'¿Están seguros los activos de las órdenes pendientes? ',
		text65: 'Los fondos en órdenes pendientes no se perderán en el mercado de cifrado. Cuando una orden de transacción está pendiente, debe esperar a que la cadena de bloques coincida con la orden. Una vez que la orden coincida, la transacción se ejecutará de inmediato. ',
		text66:'¿Qué es el procesamiento acelerado de pedidos? ',
		text67: 'Dado que la relación de oferta y demanda ya no existe cuando el comerciante inicia la orden, la orden correspondiente no se puede igualar en la cadena de bloques. Los usuarios pueden optar por aumentar los fondos de la transacción para completar la transacción de orden pendiente con la nueva relación de oferta y demanda. ',
	},
	login: {
		Get_Started: 'Empezar',
		Best_Swap: 'La mejor plataforma de intercambio del mundo',
		Decentralized_cryptocurrency: '¡Plataforma de intercambio de criptomonedas descentralizada que permite un intercambio rápido y fluido entre diferentes activos digitales!',
		Cookies_Privacy: 'Privacidad de cookies',
		We_use_cookies: 'Utilizamos cookies para mejorar su experiencia de navegación, analizar el tráfico del sitio web y proporcionar contenido personalizado. Si continúa utilizando este sitio web, acepta nuestro uso de cookies.',
		Accept: 'Aceptar',
		Sum: 'Suma',
		Swap: 'Intercambio',
		Balance: 'Balance',
		Name: 'Nombre',
		Price_Change: 'Cambio de precio',
		Sort_Type: 'Tipo de ordenación',
		Add_Asset: 'Agregar activo',
		Add_the_cryptocurrency: 'Añade los activos de criptomonedas que necesitas',
		Current_Market_Conditions: 'Condiciones actuales del mercado',
		Market: 'Mercado',
		Overview: 'Descripción general',
		Coin_Information: 'Información de la moneda',
		Market_Value: 'Valor comercial',
		Total_Supply: 'Oferta total',
		In_Circulation: 'En circulación',
		Trading_Volume: 'Volumen de operaciones',
		Official_Website: 'Sitio web oficial',
	}
}
