/*阿拉伯*/
export default {
	"830753-0": "إعادة الشحن",
	"830753-1": "إعادة شحن الأصول بسرعة",
	"830753-2": "رجاءً قم بتحميل الوثيقة",
	"830753-3": "تأكيد إعادة الشحن",
	"830753-4": "بيانات الرد من الخادم غير صحيحة:",
	"830753-5": "فشل تحميل الصورة:",
	account: {
		Start_making_money_plan: "ابدأ خطة كسب المال",
		Market: "السوق",
		Migital_currency: "العملة الرقمية",
		Foreign_exchange: "الصرف الأجنبي",
		Noble_metal: "المعدن النفيس",
		Send_Cryop_Now: "أرسل Cryop الآن",
		Send: "أرسل",
		Receive: "استلم",
		Buy: "اشترِ",
		Currency_account: "حساب العملة",
		Contract_account: "حساب العقد",
		Transfer: "نقل",
		Select_a_wallet: "اختر محفظة",
		Select_Coin: "اختر العملة",
		Confirm: "تأكيد",
		Wallet: "محفظة",
		Availavle: "متاح",
		Frozen: "مجمّد",
		Convert: "تحويل",
		Receiving_Address: "عنوان الاستلام",
		Amount: "المبلغ",
		Max: "الحد الأقصى",
		Send_Now: "أرسل الآن",
		Please_check_if: "يرجى التحقق مما إذا كان عنوان التسليم الخاص بك صحيحًا قبل الإرسال لتجنب فقدان الأصول.",
		new1: "العملات المشفرة",
		new2: "المؤشر",
		new3: "الدفعة",
		new4: "الفوركس",
		new5: "إجمالي الأصول",
	},

	"314962-0": "Google 2FA",
	"314962-1": "تنزيل Google Authenticator للربط",
	"314962-2": "نسخ الرمز",
	"314962-3": "إدخال رمز التحقق",
	"314962-4": "فشل الطلب:",
	"314962-5": "تم نسخ رمز التحقق",
	"314962-6": "تعذر نسخ النص:",
	"314962-7": "فشل النسخ، يُرجى المحاولة مرة أخرى",
	"199917-0": "الإعدادات",
	"199917-1": "الإشعارات",
	"199917-2": "Google 2FA",
	"199917-3": "البريد الإلكتروني",
	"199917-4": "اللغة",
	"199917-5": "الإصدار",
	"199917-6": "تسجيل الخروج",
	"516529-0": "إعدادات الحساب",
  "516529-1": "تعرف على معلومات حسابك",
  "516529-2": "الدعم عبر الإنترنت",
  "516529-3": "مشاركة التطبيق",
  "516529-4": "إعدادات أساسية",
  "516529-5": "إعدادات البريد الإلكتروني",
  "516529-6": "تغيير اللغة",
  "516529-7": "تنبيه الأسعار",
  "516529-8": "القانوني و المعلومات",
  "516529-9": "شروط الخدمة",
  "516529-10": "سياسة منع غسيل الأموال",
  "516529-11": "سياسة ملفات تعريف الارتباط",
  "516529-12": "احصل على إجابات ومساعدة",
  "516529-13": "بدء العمل",
  "516529-14": "تبادل الرموز",
  "516529-15": "حل المشكلات",
  "516529-16": "توصية لأصدقائك",
  "516529-17": "شارك مع أصدقائك واحصل على عمولة التوصية",
  "516529-18": "نسخ رابطك",
  "516529-19": "قم بضبط بريدك الإلكتروني لتلقي تحديثات السوق",
  "516529-20": "تحقق الآن",
  "516529-21": "أدخل البريد الإلكتروني...",
  "516529-22": "أدخل كود التحقق...",
  "516529-23": "سيتم استخدام بريدك الإلكتروني المشترك فقط للحصول على معلومات مفيدة أو تذكيرات مرتبطة بخدماتنا ولن يتم استغلاله!",
  "516529-24": "حفظ",
  "516529-25": "اختر لغة موقع الويب الخاص بك",
  "516529-26": "نحن ملتزمون بحماية أمان بياناتك وخصوصيتها",
  "516529-27": "الصينية التقليدية",
  "516529-28": "اليابانية",
  "516529-29": "إرسال كود التحقق",
  "516529-30": "نسخ بنجاح!",
  "516529-31": "إعادة الإرسال",
    home:{
        USDTAccount: "حساب USDT",
        USTD: "USDT",
        USDT: "USDT",
        USTC: "USDC",
        open: "افتح",
        ETHTodayProfit: "أرباح ETH اليوم",
        totalRevenue: "إجمالي الإيرادات",
        portfolio: "محفظة",
        reliableSecurity: "ضمان أمان موثوق",
        stableReliable: "استثمار مستقر وموثوق",
        convenienEasy: "تشغيل سهل ومريح",
        pleaseLookForward: "يرجى التطلع إلى...",
        rateOfReturn: "معدل العائد",
        walletnot: "يرجى تثبيت MetaMask أو أي محفظة تمديد Ethereum",
        wallettip: "يرجى ربط عنوانك لتجنب التأثير على التجربة",
    },
	ConnectW: "اتصل بالمحفظة",
	dappbrowser: "يقبل هذا الموقع الوصول فقط من متصفحات DAPP لمحافظ لامركزية.",
	"Coins POS": "CoinB Pos",
	"9dcf43fa47": "9dcf43fa47",
	"Earn Interest": "كسب الفائدة",
	"$": "$",
	"ETH Today's Profit": "أرباح ETH اليوم",
	Wallet: "محفظة",
	Support: "الدعم",
	General: "عام",
	Notifications: "الإشعارات",
	"Invite Friends": "دعوة الأصدقاء",
	FAQ: "الأسئلة الشائعة",
	"Privacy & Security": "الخصوصية والأمان",
	Legality: "الشرعية",
	Authenticator: "التحقق",
	Settings: "الإعدادات",
	"Change Language": "تغيير اللغة",
	"Daytime mode": "وضع النهار",
	'Black mode': "الوضع الأسود",
	BTC: "BTC",
	Bitcoin: "بتكوين",
	ETH: "ETH",
	ethereum: "إيثريوم",
	USDT: "USDT",
	tether: "تيثر",
	USDC: "USDC",
	"usd-coin": "عملة الدولار الأمريكية",

    // marketInfo.vue
    Open: "فتح",
    High: "أعلى",
    Low: "أدنى",
    Close: "إغلاق",
    Up: "أعلى",
    Down: "أسفل",
    Time: "الوقت",
    TransactionFee: "رسوم المعاملة",
    AvailableBalance: "الرصيد المتاح",
    submit: "اشترك",
    Direction: "الاتجاه",
    Profit: "الربح",
    Quantity: "الكمية",
    Price: "السعر",
    different: "انقر على نسب مختلفة لعرض الحد الأدنى",
    lessthan: "على الأقل لا يمكن أن يكون أقل من",
    least: "الأدنى:",
    Transactionhistory: "سجل المعاملات",
    Transactionrecords: "سجلات المعاملات",
    Number: "العدد",
    Profitloss: "الربح والخسارة",
    Ror: "ROR",
    operate: "تشغيل",





    //钱包
    TransactiónDetails:'تفاصيل المعاملة',
	dealdetails:'تفاصيل الصفقة',
    ViewTransactionHistory:'عرض سجل المعاملات',
    Deposit:'إيداع',
    Rechargeaddress:'عنوان إعادة الشحن:',
    DepositAmount:'مبلغ الإيداع:',
    EnterDepositAmount:'أدخل مبلغ الإيداع',
    EnterHash:'أدخل التجزئة',
    Hash:'هاش:',
    Voucherimage:'صورة القسيمة:',
    Continue:'يكمل',
    Withdraw: "سحب",
    Withdrawal: "السحب:",
    EnterAmounttoWithdraw: "أدخل المبلغ للسحب",
    Available: "المتوفر:",
    ReceiveAddress: "عنوان الاستلام:",
    EnterWalletAddress: "أدخل عنوان المحفظة",
    WithdrawalDesc: "السحب باللغة الإنجليزية يتطلب رسوم معالجة بنسبة 1%، والتي تحتاج إلى تأكيد من عقد الشبكة قبل أن يتم تحويلها. يرجى عدم تحويل العملات المشفرة إلى الغرباء.",
    Exchange: "تبادل",
    Send: "أرسل:",
    Max: "الحد الأقصى:",
    rechargeAmount: "يرجى إدخال مبلغ الشحن",
    hashcode: "رمز التجزئة ولقطة الشاشة لا يمكن أن يكونا فارغين في نفس الوقت",
    correcthashcode: "يرجى إدخال رمز تجزئة صحيح",
    maximumwithdrawalamount: "أقصى مبلغ للسحب هو",
    Incorrectamountformat: "تنسيق المبلغ غير صحيح",
    copy: "نسخ",


	//tab2
	"Total Profit":"إجمالي الربح",
	"Today's Profit":"ربح اليوم",
	"What is Automated Trading?":"ما هو التداول الآلي؟",
	"Learn about earning":"تعرف على كيفية الكسب",
	"Staking Period":"فترة الرهان",
	"Staking Amount":"مبلغ الرهان",
	"Yield":"أَثْمَر",
	"Limit":"حد",
	"Subscribe":"يشترك",
	"Record":"سِجِلّ",

	//tab3
	"Proof of Stake": "إثبات الحصة",
	"ETH Today is Price": "سعر ETH اليوم",
	"ETH Today's Increase": "زيادة ETH اليوم",
	"Joint Staking": "التخزين المشترك",
	"Individual Staking": "التخزين الفردي",
	"tab3long1": "يتم تحقيق التخزين المشترك من خلال مطابقة المشاركين عبر العقود الذكية للوصول إلى إجمالي مبلغ تخزين قدره 32 ETH.",
	"tab3long2": "التخزين الفردي لا يتطلب مطابقة المشاركين عبر العقود الذكية. من خلال الوصول إلى مبلغ تخزين قدره 32 ETH، يمكن إكمال التخزين الفردي.",


	//web_view
	"webviewlong1":"العملة المشفرة إسقاط يساعد على تطوير مشاريع جديدة على أساس كتلة سلسلة . جذورها ربط أفراد المجتمع مع المكافآت والأهداف المشتركة . وكانت النتيجة الناجحة للهبوط الجوي مجتمع أقوى ، ومشاريع أفضل ، وحوافز للمستعملين النهائيين . على مر السنين ، شهدنا العديد من عمليات النقل الجوي التي تم تسليمها على عبء العمل إثبات ( المتفجرات من مخلفات الحرب ) كتلة سلسلة . شهادة حقوق الملكية ( نقاط البيع ) إيثرنت سلسلة مربع الآن يدعم العشرات من مئات المشاريع على شبكة الإنترنت لتشفير إسقاط العملة جوا . مع البوس أصبح النموذج السائد في توافق الآراء ، وعدد من عمليات الإخلاء على شبكة إيثرنت لا تظهر أي علامات على التباطؤ .",
	"webviewlong2":"من خلال ضمان العملة المشفرة الخاصة بك ، يمكنك فتح مكافأة قيمة إسقاط الهواء على أساس عدد من العملات المشفرة التي كنت رهن .",
	"webviewlong3":"المكافأة الإنصاف ينطبق فقط على أصحاب المصلحة الذين يأتون من مئات من مشاريع مختلفة ، مع عائدات أعلى بكثير من أي واحد مستقل .",
	"webviewlong4":"في كثير من الأحيان من الصعب على أصحاب العملة المشفرة التي تودع في البورصة ، مع بعض الأمناء لا تدعم على الإطلاق . هذا هو السبب في إدارة العملة المشفرة الخاصة بك مهم جداً . إيثرنت هو كيان غير المدارة التي يمكن رهنها على جميع الشبكات الرئيسية",
	"Introduction":"مقدمة",
	"webviewlong5":"كتلة الرائدة في سلسلة منصة إيثرنت هو الانتقال من عبء العمل إثبات ( بو ) إلى إثبات حقوق الملكية ( POS ) آلية من خلال تحسين إيثرنت 2.0 . جهاز الأمن الوقائي التعهد هو جزء مهم من هذا الانتقال ، ويوفر للمستخدمين فرصة لدعم أمن الشبكة وتشغيلها ، مع إمكانية العودة . وتلخص هذه الورقة POS التعهد و فوائده .",
	"Ethereum 2.0 and Proof of Stake (PoS)":"الأثير مربع 2.0 و شهادة حقوق الملكية ( POS )",
	"webviewlong6":'ورشة عمل إيثرنت 2.0 هو ترقية شبكة كبيرة تهدف إلى تحسين قابلية والأمن والاستدامة . واحدة من التغييرات الرئيسية هو التحول من الطاقة الكثيفة توافق آراء ساو باولو إلى أكثر صديقة للبيئة . في نقاط البيع ، يتم اختيار المحققين لإنشاء كتل جديدة ، والتحقق من المعاملات على أساس عدد من العملات المشفرة التي عقدت ، وعلى استعداد " تعهد " كضمان .',
	"The Staking Process":"عملية التعهد",
	"webviewlong7":"من أجل المشاركة في حلقة الأثير POS التعهد ، يجب على المستخدم أن تعهد ما لا يقل عن 32 ETH بإيداعها في حلقة الأثير 2.0 عقد الإيداع . مرة واحدة يتم التعهد ، ETH سوف تكون مؤمنة لفترة من الوقت ، وسوف تستخدم كضمان لحماية الشبكة . وهي مسؤولة عن تحديد والتحقق من القطع الجديدة ، فضلا عن التحقق من المعاملات على الشبكة .",
	"Staking Rewards and Risks":"المخاطر والمكافآت",
	"webviewlong8":"من خلال المشاركة في جهاز الأمن الوقائي التعهد ، يمكن للمستخدمين الحصول على مكافآت في شكل الزهر حديثا ETH ورسوم المعاملات . وتستند المكافآت على حجم الرهان في EtherFoundation وعلى مجمل أنشطة الشبكة . ومع ذلك ، فإن التعهد ينطوي أيضا على خطر ، على سبيل المثال ، إذا كان المحقق يتصرف بسوء نية أو لا يمكن أن تستمر على الانترنت ، يمكن أن يعاقب . وبالإضافة إلى ذلك ، تم تأمين الرهن العقاري ETH على مدى فترة من الزمن ، مما يجعلها تفتقر إلى السيولة و تتأثر بتقلبات الأسعار .",
	"Joint Staking and Individual Staking":"الجمع بين كومة كومة الفردية",
	"webviewlong9":"بالنسبة للمستخدمين الذين لا يشترط أن يكون 32 ETH الرهانات الفردية ، الرهانات المشتركة يوفر بديلا . في التعهد المشترك ، العديد من المستخدمين جمع ETH معا لتحقيق المبلغ المطلوب من التعهد . هذا التجميع عادة ما يتحقق من خلال عقد ذكي أو خدمة التعهد ، مما يسمح للمستخدمين استخدام أقل ETH للمشاركة في ورشة عمل إيثرنت POS التعهد .",
	"Conclusion":"خاتمة",
	"webviewlong10":"البوس التعهد هو جانب هام من جوانب تحسين 2.0 ، والتي توفر للمستخدمين مع فرص دعم أمن الشبكة ، وتحسين decentralization والمكافآت . من خلال فهم عملية التعهد ، والمكافآت والمخاطر ، يمكن للمستخدمين اتخاذ قرارات مستنيرة بشأن هذا التطور الحاسم في المشاركة في شبكة إيثرنت .",


	//mywallet
	"Total":"المجموع",

	//record
	"Account":"حساب",
	"interest-bearing":"التنفس",
	"POS Staking":"نقاط البيع تعهد",
	"convert":"تحويل",
	"transaction":"صفقة",
	"pledge":"نذر",
	"recordno":"ليس لديك أي أخبار حتى الآن . القائمة فارغة .",
	"Oops":"يا إلهي",
	"recharge":"شحن",
	"payment":"دفع",
	"staking":"تعهد",
	"income":"دخل",
	"Pledge quantity":"كمية التعهد",
	"cycle":"دورة",
	"Days Remaining":"الأيام المتبقية",
	"cumulative gain":"الدخل التراكمي",
	"Price increase":"ارتفاع الأسعار",
	"Price decrease":"انخفاض الأسعار",
	"Opening price":"سعر الافتتاح",
	"Closing price":"سعر الإغلاق",
	"loss":"خسارة",
	"pfofit":"بفوفيت",
	"amount":"الكمية",

	//notifiCations
	"notifiCationslong1":"ملاحظة : يتم تسجيل كبار الشخصيات على أساس مبلغ إعادة شحن الحساب الكلي المقابل لتحديد درجة الخاصة بك . هذه الفترة تحتاج إلى خصم مجموع رسوم السحب من الحساب الحالي لتحديد مستوى العضوية .",
	"notifiCationslong2":"العضوية هي أيضا مقسمة إلى مستويات مختلفة . من أجل الحفاظ على العضوية الخاصة بك ، تحتاج إلى إعادة شحن ما لا يقل عن 5000 usdt في وقت محدد من أجل التمتع بامتيازات العضوية . في كل مرة يتم شحن عضوية الدولة ، حلقة تحسب و يمكن المتراكمة .",
	"notifiCationslong3":"خدمة العملاء على أساس مستوى الأعضاء . خدمة العملاء الرسمية المقدمة من قبل موظفي التطبيق ، وتوفير خدمة موحدة لجميع الأعضاء . ويكفل العدد المحدود من الأمناء المخصصين خدمات أفضل . سكرتير خاص حصري يقدم خدمات الاستقبال المتخصصة والمشورة المالية للمستخدمين .",
	"notifiCationslong4":"الاتصال بخدمة العملاء قبل موعد الشحن . الانتهاء من شحن في الوقت المحدد ، يمكنك الحصول على مكافأة إضافية على أساس مستوى الأعضاء .",
	"notifiCationslong5":"VIP1 : استناداً إلى حساب UID ، شحن التراكمي 10 ، 000 دولار أمريكي ، والحصول على مكافأة USDT 177 . بعد الوصول إلى مستوى كبار الشخصيات و الحصول على وسام العضوية العادية ، سيتم إسقاط النقاط في الحساب .",
	"notifiCationslong6":"VIP2 : وفقا لحسابات رمز المستخدم ، شحن التراكمي يصل إلى 30 ، 000 دولار ، وسوف يتم التبرع 777 دولار . مبلغ المكافأة سوف تسجل في الحساب مباشرة بعد الوصول إلى مستوى كبار الشخصيات و الميدالية البرونزية .",
	"notifiCationslong7":"VIP3 : على أساس حساب رمز المستخدم ، يمكنك الحصول على 1777 USDT كهدية من خلال شحن 70 دولار . بعد الوصول إلى مستوى كبار الشخصيات والحصول على الميدالية الفضية ، سيتم إسقاط النقاط في الحساب . وبالإضافة إلى ذلك ، سوف تحصل على مكافأة إضافية من 1.5 في المائة في المرة القادمة يمكنك حجز تهمة .",
	"notifiCationslong8":"VIP4 : استناداً إلى حساب UID ، تم الحصول على شحن تراكمي قدره 000 150 دولار USDT 2777 . بعد الوصول إلى مستوى كبار الشخصيات والحصول على ميدالية ذهبية ، النقاط سوف تسقط في الحساب . وبالإضافة إلى ذلك ، سوف تحصل على مكافأة إضافية 2 ٪ في المرة القادمة يمكنك حجز تهمة .",
	"notifiCationslong9":"vip5 : رمز المستخدم هو حساب بقيمة 300 دولار ، 4777 USDT . بعد الوصول إلى مستوى كبار الشخصيات والحصول على الماس شارة كبار الشخصيات ، سيتم إسقاط النقاط في الحساب . وبالإضافة إلى ذلك ، سوف تحصل على 2.5 ٪ مكافأة إضافية في المرة القادمة .",
	"notifiCationslong10":"كبار الشخصيات 6 : وفقا لحسابات رمز المستخدم ، فإن المبلغ التراكمي يصل إلى 800 ، 000 دولار ، وسوف تتلقى 16888 USDT مكافأة . بعد الوصول إلى مستوى كبار الشخصيات والحصول على الماس الأسود شارة العضوية ، سيتم إسقاط مكافأة مباشرة إلى الحساب الخاص بك والحصول على 3 ٪ من المبلغ المدفوع مقدما في المرة القادمة . سيكون لديك سكرتير خاص حصري لتقديم أي مشورة مالية أو التعامل مع المسائل الضريبية .",
	"notifiCationslong11":"VIP7 : استناداً إلى حساب UID ، سيتم شحن المبلغ التراكمي يصل إلى 3 ملايين دولار ، وسوف تقدم 15 يوما رحلة فاخرة إلى أوروبا ( مع خصم ) . CoinBPOS العالمية يكرم مدى الحياة لكبار الشخصيات وسيتم منح وسام التاج . في المرة القادمة سوف تحصل على 3.5 في المائة مكافأة إضافية .",
	"notifiCationslong12":"VIP8 : وفقا لحساب رمز المستخدم ، يمكنك الحصول على قسيمة سنوية من كوين بوبوس المغرب مأدبة إذا كان المبلغ التراكمي يصل إلى 8 ملايين دولار . الجائزة : 1 BTC ، ويحصل على شرف كبار الشخصيات كوين BPOS مدى الحياة في جميع أنحاء العالم . يمكنك الحصول على مكافأة إضافية بنسبة 4 في المائة في المرة القادمة يمكنك جعل الحجز .",
	"notifiCationslong13":"vip9 : على أساس حساب رمز المستخدم ، يمكنك الحصول على 3 ٪ من أسهم كوين BPOS مع إيداع 30 مليون دولار . و 3 ٪ من الأرباح السنوية . كرونيكس الذهب الخالص شارة تذكارية مجانية .",

	//invite
	"Refer and Earn":"يوصي وكسب",
	"Copy Link":"نسخ الرابط",

	//faq
	"Frequently Asked Questions":"أسئلة وأجوبة",

	//authenticator
	"mailbox":"صندوق البريد",
	"Front photo":"صورة إيجابية",
	"Back photo":"الصورة الخلفية",
	"Handheld ID photo":"صورة الهوية المحمولة باليد",
	"define":"يُعرِّف",

	//legality
	legalitylong1: "Coinbpos، المكونة من عدة شركات للعملات المشفرة، توفر أدوات لمساعدة مجتمع التشفير على إنشاء وتنمية والحفاظ على استقرار المجتمع، وتدعم آلاف التطبيقات اللامركزية، وتعزز مستقبل العملات المشفرة وكذلك تعزز رقمنة العملات التقليدية وتعزيز الازدهار الاقتصادي العالمي.",
	legalitylong2: "كل شركة ضمن Coinbpos تعمل كمسؤول عن البيانات لمعالجة البيانات الشخصية المتعلقة بخدماتها، ومسؤول البيانات لكل خدمة هو:",
	legalitylong3: "1. تكنولوجيا البلوكشين - بلوكشين إيثريوم.",
	legalitylong4: "2. المحفظة اللامركزية - Trust Wallet، MetaMask وCrypto Wallet.",
	legalitylong5: "3. تكنولوجيا التداول - Coinbase، Crypto، linch وBinance.",
	legalitylong6: "سياسة الخصوصية توضح كيف تقوم Coinbpos بمعالجة البيانات الشخصية وفقًا لالتزاماتها بموجب قوانين حماية البيانات المعمول بها، بما في ذلك اللائحة العامة لحماية البيانات (GDPR) للاتحاد الأوروبي.",
	legalitylong7: "قانون حماية خصوصية المستهلك في كاليفورنيا (CCPA) وغيرها من القوانين العامة (ويُشار إليها مجتمعة باسم قوانين حماية البيانات).",

	//language
	"language":"لغة",
	"Confirm":"يتأكد",

	"newtext1":"تسجيل الخروج",
	"newtext2":"يلغي",
	"newtext3":"هل أنت متأكد أنك تريد تسجيل الخروج من هذا الحساب؟",

	"newtext4":"中文简体",
	"newtext5":"中文繁体",
	"newtext6":"English",
	"newtext7":"日本語",

	newtext8: "تسجيل الدخول",
	newtext9: "اسم المستخدم",
	newtext10: "رقم الهاتف المحمول",
	newtext11: "يرجى إدخال اسم المستخدم",
	newtext12: "يرجى إدخال رقم هاتفك المحمول",
	newtext13: "كلمة المرور",
	newtext14: "يرجى إدخال كلمة المرور",
	newtext15: "لا تملك حسابًا بعد",
	newtext16: "سجل",
	newtext17: "البلد",
	newtext18: "اختر بلداً",
	newtext19: "رمز التحقق من رقم الهاتف المحمول",
	newtext20: "يرجى إدخال رمز التحقق",
	newtext21: "عنوان البريد الإلكتروني",
	newtext22: "يرجى إدخال عنوان بريدك الإلكتروني",
	newtext23: "تأكيد كلمة المرور",
	newtext24: "يرجى إدخال كلمة المرور للتأكيد",
	newtext25: "لديك حساب بالفعل",
	newtext26: "إرسال رمز التحقق",
	newtext27: "رقم الهاتف المحمول غير صحيح",
	newtext28: "تم الإرسال بنجاح",
	newtext29: "تم التسجيل بنجاح",

	"newtext30":'عملة',
	"newtext31":'الفوركس',
	"newtext32":'المعادن الثمينة',

	"newtext33":'حساب',
	"newtext34":'أنشطة المنصة',
	"newtext35":'الخدمة عبر الإنترنت',
	"newtext36":'يدعو',
	"newtext37":'التحقق من هوية العميل',
	"newtext38":'جلسة',
	wallet_p:{
		text1: "نسخ العنوان",
		text2: "تأكيد",
		text3: "التبديل من",
		text4: "التبديل إلى",
		text5: "الحد الأقصى",
		text6: "دعوة",
		text7: "احصل على مكافآت الإحالة",
		text8: "عندما يشارك أصدقاؤك في التحكيم بالذكاء الاصطناعي واستئجار آلات التعدين، يمكنك الحصول على مكافآت الإحالة",
		text9: "نسخ",
		text10: "مشاركة الرابط",
		text11: "التحقق من الهوية (KYC)",
		text12: "البلد",
		text13: "يرجى اختيار بلد",
		text14: "الاسم الأول",
		text15: "يرجى إدخال الاسم الأول",
		text16: "الاسم الأخير",
		text17: "يرجى إدخال الاسم الأخير",
		text18: "نوع الشهادة",
		text19: "يرجى اختيار نوع الشهادة",
		text20: "رقم الشهادة",
		text21: "يرجى إدخال رقم الشهادة",
		text22: "يرجى تحميل صورة للجهة الأمامية من بطاقة الهوية",
		text23: "يرجى تحميل صورة للجهة الخلفية من بطاقة الهوية",
		text24: "يرجى تحميل صورة للجهة الأمامية من جواز السفر",
		text25: "يرجى تحميل صورة للجهة الخلفية من جواز السفر",
		text26: "البريد الإلكتروني",
		text27: "يرجى إدخال بريدك الإلكتروني",
		text28: "إرسال",
		text29: "رمز التحقق",
		text30: "يرجى إدخال رمز التحقق",
		text31: "بطاقة تعريف",
		text32: "جواز سفر",
		text33: "يلغي",
		text34: "البلد/المنطقة",
		text35: "الرجاء إدخال المعلومات الصحيحة لتقديمها للمصادقة",
		text36: "تم الإرسال بنجاح!",
		text37: "خطأ في تنسيق البريد الإلكتروني!",
		text38: "رخصة القيادة",
		text39: "يرجى تحميل صورة للجهة الأمامية من رخصة القيادة",
		text40: "يرجى تحميل صورة للجهة الخلفية من رخصة القيادة",
		
		text41: "رقم الهوية",
		text42: "يرجى إدخال رقم الهوية",
		text43: "رقم جواز السفر",
		text44: "يرجى إدخال رقم جواز السفر",
		text45: "رقم رخصة القيادة",
		text46: "يرجى إدخال رقم رخصة القيادة",
	},
	jiaoyi:{
		text1:'ثانية',
		text2:'دائم',
		text3:'بقعة',
		text4:'عهد الآن',
		text5:'نوع الحساب',
		text6: "مبلغ التخزين",
		text7: "نطاق السعر",
		text8: "سعر الشراء",
		text9: "مجاناً",
		text10: "الرصيد",
		text11: "على الأقل",
		text12: "المتوقع",
		text13: "مبلغ الشراء",
		text14: "الاتجاه",
		text15: "سعر الشراء",
		text16: "السعر المباشر",
		text17: "وقت التسليم",
		text18: "عقدي",
		text19: "الربح",
		text20: "العقد",
		text21: "سعر التسليم",
		text22: "وقت التسليم",
		text23: "سعر السوق",
		text24: "أعلى",
		text25: "أسفل",
		text26: "سعر الشراء",
		text27: "الحد الأقصى",
		text28: "الهامش",
		text29: "رسوم المعالجة",
		text30: "شراء",
		text31: "بيع",
		text32: "كمية الشراء",
		text33: "حساب حقيقي",
		text34: "مستخدمون افتراضيون",
		text35: "لا توجد بيانات حتى الآن",
		text36: "الموضع الحالي",
		text37: "العمولة التاريخية",
		text38: "إغلاق مركز",
		text39: "الهامش",
		text40: "الربح والخسارة",
		text41: "احتفاظ",
		text42: "سجل البيع",
		text43: "سجل البيع",
		text44: "المتوفر",
		text45: "تحويل",
		text46: "تم التحقق بنجاح",
		text47: "وقت الشراء",
		text48: "غير مقروء",
		text49: "مقروء",
		text50: "طلب الاستضافة",
		text51: "إجمالي المراجحة",
		text52: "كيف تعمل روبوتات الذكاء الاصطناعي",
		text53: "منتج المراجحة",
		text54: "يوم",
		text55: "المبلغ",
		text56: "الدخل اليومي",
		text57: "نوع العملة",
		text58: "أرباح اليوم",
		text59: "انضم إلى المراجحة بالذكاء الاصطناعي",
		text60: "خطر صفري، عوائد سريعة",
		text61: "المراجحة بالذكاء الاصطناعي",
		text62: "الأرباح المتوقعة",
		text63: "نوع عملة المراجحة",
		text64: "مبلغ الحجز",
		text65: "استضافة الآن",
		text66: "يتم إرسال الدخل اليومي إلى محفظة USDT الخاصة بك",
		text67: "صندوق استثماري بدون مخاطر",
		text68: "يمكنك سحب أموالك في أي وقت",
		text69: "يعمل الذكاء الاصطناعي على مدار 24 ساعة",
		text70: "الدخل التراكمي",
		text71: "وقت الانتهاء",
		text72: "الكل",
		text73: "قيد التقدم",
		text74: "مكتمل",
		text75: "تفاصيل الاستضافة",
		text76: "العملة",
		text77: "الدعم الفني",
		text78: "إرسال بريد إلكتروني",
		text79:'نوع المعاملة',
		
		text80:"جارٍ التحميل...",
		text81:"لا أكثر",
		text82:"ورقة بيضاء",
	},

	swapadd:{
		text1: "سياسة ملفات تعريف الارتباط",
		text2:'نحن ملتزمون بحماية أمن بياناتك وخصوصيتك',
		text3: "مقدمة",
		text4:'توضح سياسة ملفات تعريف الارتباط هذه كيف تستخدم البورصة اللامركزية لدينا ملفات تعريف الارتباط وتقنيات التتبع المماثلة. من خلال الوصول إلى البورصة أو استخدامها، فإنك توافق على استخدام ملفات تعريف الارتباط كما هو موضح في هذه السياسة. ',
		text5:"ما هي ملفات تعريف الارتباط؟",
		text6:'ملفات تعريف الارتباط هي ملفات نصية صغيرة يتم وضعها على جهازك (الكمبيوتر أو الهاتف المحمول أو الجهاز اللوحي) عند زيارتك لأحد مواقع الويب. يتم استخدامها على نطاق واسع لتحسين تجربة التصفح لديك وتوفير ميزات التخصيص على مواقع الويب. يمكن أن تكون ملفات تعريف الارتباط عبارة عن ملفات تعريف ارتباط للجلسة (وهي مؤقتة ويتم حذفها عند إغلاق المتصفح الخاص بك) أو ملفات تعريف ارتباط دائمة (والتي تظل على جهازك لفترة أطول من الوقت). ',
		text7:"كيف نستخدم ملفات تعريف الارتباط",
		text8: 'الأغراض التي تستخدم التبادلات من أجلها ملفات تعريف الارتباط تشمل على سبيل المثال لا الحصر:',
		text9:'تمكين الوظائف والميزات الأساسية للتبادل',
		text10:'تحليل وتحسين أداء ووظائف التبادل',
		text11:"خصص تجربتك وتذكر تفضيلاتك",
		text12:'تتبع وفهم أنماط الاستخدام الخاصة بك في البورصة',
		text13:'توفير الاتصالات الإعلانية والتسويقية ذات الصلة',
		text14:"أنواع ملفات تعريف الارتباط التي نستخدمها",
		text15:'a) ملفات تعريف الارتباط الأساسية: تعد ملفات تعريف الارتباط هذه ضرورية لتشغيل البورصة وتمكنك من الوصول إلى ميزاتها واستخدامها. وبدون ملفات تعريف الارتباط هذه، قد لا تكون بعض الخدمات متاحة لك. ',
		text16:'b) ملفات تعريف الارتباط التحليلية: تجمع ملفات تعريف الارتباط هذه معلومات حول كيفية استخدامك للتبادل، مثل الصفحات التي تزورها وأي أخطاء تواجهها. نحن نستخدم هذه المعلومات لتحليل وتحسين أداء ووظائف البورصة. ',
		text17:'c) ملفات تعريف الارتباط الوظيفية: تسمح ملفات تعريف الارتباط هذه لـ Exchange بتذكر اختياراتك (مثل تفضيلات اللغة) وتوفير وظائف محسنة وأكثر تخصيصًا. ',
		text18:'d) ملفات تعريف الارتباط الإعلانية: تُستخدم ملفات تعريف الارتباط هذه لتوفير الإعلانات ذات الصلة بك وباهتماماتك. ويمكن استخدامها أيضًا للحد من عدد المرات التي ترى فيها إعلانًا وقياس فعالية الحملات الإعلانية. ',
		text19:'ملفات تعريف الارتباط الخاصة بالطرف الثالث',
		text20:'قد تسمح التبادلات لمقدمي خدمات الطرف الثالث بوضع ملفات تعريف الارتباط على جهازك لمجموعة متنوعة من الأغراض، بما في ذلك التحليلات والإعلانات. لدى هذه الأطراف الثالثة سياسات الخصوصية الخاصة بها وقد تقوم بجمع معلومات حول أنشطتك عبر الإنترنت عبر مواقع الويب المختلفة أو الخدمات عبر الإنترنت. ',
		text21:"إدارة ملفات تعريف الارتباط",
		text22:'يمكنك إدارة أو حذف ملفات تعريف الارتباط في أي وقت من خلال إعدادات المتصفح الخاص بك. ومع ذلك، يرجى ملاحظة أن تعطيل أو حذف بعض ملفات تعريف الارتباط قد يؤثر على وظيفة التبادل وأدائه. ',
		text23: "أوافق",
		text24:'باستخدام البورصة، فإنك توافق على استخدام ملفات تعريف الارتباط كما هو موضح في هذه السياسة. إذا كنت لا توافق على استخدام ملفات تعريف الارتباط، فيمكنك تعطيلها أو حذفها من خلال إعدادات المتصفح الخاص بك أو عدم استخدام البورصة. ',
		text25:"سياسة التحديث",
		text26:'قد نقوم بتحديث سياسة ملفات تعريف الارتباط هذه بشكل دوري لتعكس التغييرات في ممارساتنا أو التغييرات في القانون المعمول به. نوصي بمراجعة هذه السياسة بانتظام للحصول على أحدث المعلومات. ',
		text27:'إذا كانت لديك أية أسئلة أو استفسارات بشأن الطريقة التي نستخدم بها ملفات تعريف الارتباط، فيرجى الاتصال بفريق دعم العملاء لدينا. ',

		text28:"الحصول على استكشاف الأخطاء وإصلاحها والمساعدة",
		text29:"البدء",
		text30:"رموز التبادل",
		text31:"استكشاف الأخطاء وإصلاحها",
		text32:"ما هي البورصة اللامركزية؟",
		text33: 'DEX عبارة عن بورصة عملات مشفرة تعمل على شبكة blockchain لا مركزية وعادة ما تكون مبنية على عقود ذكية. على عكس البورصات المركزية التقليدية، تعتمد CEX على وكالة مركزية لتسهيل المعاملات والاحتفاظ بأموال المستخدم، ويمكن لـ DEX التداول مباشرة مع كلا الطرفين من خلاله. العقود الذكية. ',
		text34:"أمن التبادلات اللامركزية",
		text35: 'تعمل DEX باستخدام العقود الذكية على blockchain، مما يسمح بالمعاملات المباشرة بين المشترين والبائعين، مما يلغي الحاجة إلى مؤسسات الطرف الثالث. تعمل DEX على شبكة blockchain عامة، والمعاملات شفافة وقابلة للتدقيق، ويمكن لأي شخص التحقق من عدالة ونزاهة DEX. ',
		text36:"ما هي المحفظة اللامركزية؟",
		text37: 'المحفظة اللامركزية هي محفظة عملات مشفرة تتكون من 12 مفتاحًا. يمكن للمستخدمين استخدام 12 مفتاحًا لتسجيل الدخول إلى المحفظة باستخدام المفتاح الموجود على أي blockchain نفسه للوصول إلى الأصول الرقمية طالما لم يتم تسريب المفتاح، فلن يتمكن أحد من الحصول على الأصول الرقمية في المحفظة اللامركزية.',
		text38:"ما هو عنوان المحفظة؟",
		text39:"عنوان محفظة العملة المشفرة هو مفتاح عام تم الحصول عليه من مفتاح خاص من خلال خوارزمية توقيع المنحنى البيضاوي. المفتاح العام هو عنوان المحفظة الذي تم الحصول عليه من خلال سلسلة من التحويلات ويتم استخدامه أثناء عملية تحويل المحفظة يستخدم العنوان بشكل أساسي لإرسال واستقبال العملات المشفرة. ",
		text40:'أمان استخدام DEX لتبادل الأموال',
		text41:'تم بناء آلية تشغيل DEX على blockchain، وهي لا تمر عبر أي مؤسسات تابعة لجهات خارجية وتعتمد نظامًا غير حراسة للتبادل المباشر blockchain، تحقيق الشفافية والخصوصية والأمن حقا. ',
		text42: 'لماذا يوجد فرق في السعر لنفس الرمز في البورصة؟ ',
		text43: 'العرض والطلب في السوق: توفر البورصة شراء وبيع الأصول، ولكن نظرًا لاختلاف العرض والطلب في السوق في البورصات الرئيسية، ستتغير الأسعار التي تحددها البورصة للبيع والشراء، وسيتغير الطلب على بعض الرموز المميزة عادة ما يكون السعر أعلى من سعر السوق، وسيكون السعر أقل في البورصات ذات الطلب المنخفض. ',
		text44: 'حجم التداول: مقدار العملة المشفرة التي يتم شراؤها وبيعها في البورصة، مما قد يؤثر على السعر. يشير حجم التداول الأعلى عمومًا إلى مزيد من نشاط السوق، مما قد يعزز اكتشاف الأسعار بشكل أكثر دقة. قد يكون للبورصات ذات أحجام التداول المنخفضة فروق أسعار أكبر بين العطاءات والطلب وتسعير غير دقيق. ',
		text45: 'السيولة: تشير إلى سهولة وصعوبة شراء أو بيع أصل ما دون التسبب في تغير كبير في السعر. تميل العملات المشفرة ذات السيولة العالية إلى أن تكون أسعارها أكثر اتساقًا عبر البورصات. عندما تكون السيولة في البورصة منخفضة، يمكن أن يؤدي ذلك إلى تقلبات الأسعار وفروقات كبيرة في الأسعار. ',
		text46: "اختيار الرموز المميزة: لا تتمتع جميع الرموز المميزة بنفس إمكانية الربح، وقد تتمتع الرموز المميزة المختلفة بدرجات متفاوتة من السيولة وحجم التداول وتقلب الأسعار.",
		text47:"ما هو انتشار سعر الصرف؟",
		text48: 'تشير فروق أسعار الصرف إلى التغيرات في أسعار العملات المشفرة بين البورصات المختلفة. نظرًا لأن العملات المشفرة منتشرة عالميًا ويتم تداولها على مجموعة متنوعة من المنصات، فقد تختلف ديناميكيات العرض والطلب والسيولة وأحجام التداول وظروف السوق بين البورصات. ',
		text49:"ما هو تبادل DEX؟",
		text50:'يشير تبادل DEX، المعروف أيضًا باسم تبادل العملات الرمزية أو تبادل الأصول، إلى عملية تبادل عملة مشفرة واحدة أو رمز مميز مقابل عملة مشفرة أو رمز مميز آخر من خلال اتفاقية تبادل على DEX. عادةً ما يتم تسهيل هذه المعاملات من خلال العقود الذكية على تقنية البلوكشين، مما يلغي الحاجة إلى الوسطاء. ',
		text51: 'لماذا اختفت فروق الأسعار في البورصات الرئيسية؟ ',
		text52:'عندما لا يكون هناك اختلاف في أسعار الرموز المميزة في البورصات المختلفة، فهذا يعني أن السوق مستقر نسبيًا. والسبب في هذا الموقف هو أن متداولي السوق يتداولون بشكل ثابت ويقومون بالمراجحة تلقائيًا بناءً على معاملات غير متوازنة، ويشارك عمالقة السوق في ذلك. المعاملات لاستغلال عدد كبير من التمويل للمراجحة التجارية، والحفاظ على أسعار العملات الرمزية في السوق ثابتة، وحجم التداول والسيولة، وارتفاع حجم التداول والسيولة الكافية، وتوحيد السوق، كلها أسباب تؤدي إلى اختفاء فروق الأسعار في البورصات.',
		text53:"ما هو العنوان المحدد؟",
		text54: 'العنوان المحدد هو عنوان استلام العملة المشفرة الذي يحتاج المستخدم إلى تعبئته بعد نجاح التبادل. يحتاج المستخدمون الذين يقومون بالتبادل لأول مرة إلى ربط عنوان الاستلام المشترك',
		text55:"ما هو انزلاق السعر؟",
		text56: 'انزلاق الأسعار، المعروف أيضًا باسم الانزلاق، هو الفرق بين السعر المتوقع لتداول العملة المشفرة والسعر الذي يتم به تنفيذ التداول فعليًا. عادةً ما يحدث انزلاق الأسعار بسبب تقلبات السوق والسيولة وحجم الطلب ونوع الطلب وما إلى ذلك. في سوق سريعة الحركة ومتقلبة مثل العملات المشفرة، يمكن أن تتغير الأسعار بسرعة. ',
		text57:"ما هو تبادل الغاز؟",
		text58: 'GAS هي رسوم GAS التي يتعين على المستخدمين دفعها إلى blockchain عند سحب العملة المشفرة بعد تبادل ناجح. يتم استخدام رسوم GAS لمعالجة طلبات المستخدم وفي نفس الوقت البث في blockchain لتأكيد نجاح معاملة المستخدم. . عادة ما تكون رسوم الغاز لسحب أصول الصرف 0.1٪. ',
		text59:"لماذا توجد أوامر معلقة؟",
		text60: 'ازدحام الشبكة: خلال فترات ارتفاع الطلب أو زيادة النشاط، يمكن أن تصبح شبكات blockchain مزدحمة. يمكن أن يؤدي هذا الازدحام إلى إبطاء أوقات معالجة المعاملات وارتفاع الرسوم. قد تؤدي التقلبات الكبيرة في السوق أو الارتفاعات المفاجئة في نشاط التداول إلى ازدحام الشبكة. ',
		text61: 'مشكلات قابلية التوسع: تواجه بعض شبكات blockchain تحديات قابلية التوسع، مما يعني أن blockchain قد لا يكون قادرًا على التعامل مع كميات كبيرة من المعاملات بسرعة وكفاءة. يمكن أن يؤدي هذا إلى تأخير وبطء سرعة المعاملات. ',
		text62: 'قيود السيولة: توفر المشترين والبائعين في السوق. في بعض أسواق العملات المشفرة أو عملات مشفرة معينة، تكون السيولة محدودة وقد يؤدي هذا النقص في السيولة إلى تباطؤ المعاملات. وقد يكون هناك عدد أقل من المشاركين الذين يشترون أو يبيعون بسعر محدد، مما يؤدي إلى اتساع نطاق تنفيذ الطلب. ',
		text63: 'حجم أمر السوق: يؤثر حجم الأمر على سرعة التداول، خاصة عندما يكون الأمر كبيراً مقارنة بالسيولة المتوفرة. عندما تتفاعل الأوامر مع العرض أو الطلب المتاح في السوق، فإن وضع عدد كبير من أوامر الشراء أو البيع قد يؤدي إلى تنفيذ جزئي أو تباطؤ في التنفيذ، مما يؤدي إلى أوامر معلقة. ',
		text64:"هل الأصول الموجودة في الأوامر المعلقة آمنة؟",
		text65: 'لن يتم فقدان الأموال الموجودة في الأوامر المعلقة في سوق العملات المشفرة عندما يكون أمر المعاملة معلقًا، يتعين عليك الانتظار حتى تتطابق blockchain مع الطلب. بمجرد مطابقة الطلب، سيتم تنفيذ المعاملة على الفور. ',
		text66:"ما هي المعالجة السريعة للطلب؟",
		text67: 'نظرًا لأن علاقة العرض والطلب لم تعد موجودة عندما يبدأ المتداول الأمر، فلا يمكن مطابقة الطلب المقابل في blockchain. يمكن للمستخدمين اختيار زيادة أموال المعاملات لإكمال معاملة الطلب المعلقة مع علاقة العرض والطلب الجديدة. ',



	},
	login: {
		Get_Started: 'البدء',
		Best_Swap: 'أفضل منصة تبادل في العالم',
		Decentralized_cryptocurrency: 'منصة تبادل العملات المشفرة اللامركزية، تتيح التبادل السريع والسلس بين الأصول الرقمية المختلفة!',
		Cookies_Privacy: 'خصوصية ملفات تعريف الارتباط',
		We_use_cookies: 'نحن نستخدم ملفات تعريف الارتباط لتحسين تجربة التصفح لديك، وتحليل حركة المرور على الموقع، وتقديم محتوى مخصص. من خلال الاستمرار في استخدام هذا الموقع، فإنك توافق على استخدامنا لملفات تعريف الارتباط!',
		Accept: 'يقبل',
		Sum: 'مجموع',
		Swap: 'تبديل',
		Balance: 'توازن',
		Name: 'اسم',
		Price_Change: 'تغير السعر',
		Sort_Type: 'نوع الفرز',
		Add_Asset: 'إضافة الأصول',
		Add_the_cryptocurrency: 'أضف أصول العملة المشفرة التي تحتاجها',
		Current_Market_Conditions: 'ظروف السوق الحالية',
		Market: 'سوق',
		Overview: 'ملخص',
		Coin_Information: 'معلومات عن العملة المعدنية',
		Market_Value: 'القيمة السوقية',
		Total_Supply: 'العرض الإجمالي',
		In_Circulation: 'في التداول',
		Trading_Volume: 'حجم التداول',
		Official_Website: 'الموقع الرسمي',
	}
}
