<template>
    <div class="homePage" :class="isBlack != 'true'?'whiteBox':''">
      <div class="header-box">
        <div class="logo-box">
          <img src="../../static/tab1/logo.png" style="width: 146px;height: 38px">
        </div>
        <div class="other-box">
          <img v-if="false" src="../../static/tab1/icon-ring.png" style="width: 24px;height: 24px">
          <img @click="goBack()" src="../../static/tab1/icon-close.png" style="width: 24px;height: 24px;margin-left: 20px">
        </div>
      </div>

      <div class="top-box">
        <div class="crpto" style="font-size: 20px;">
			US$ {{topinfo.money}}
		</div>
		<div class="crpto_order" @click="gotopledgehistory">
			{{$t('jiaoyi.text50')}}
		</div>
      </div>

		<div class="qiehuan">
			
			<div class="alr">
				<div class="alr_k">
					<div>{{$t('jiaoyi.text51')}}</div>
					<div>{{topinfo.shouyi}} USDT</div>
				</div>
				<div class="alr_k">
					<div>{{$t('jiaoyi.text58')}}</div>
					<div>{{topinfo.dayshouyi}} USDT</div>
				</div>
			</div>
			<div class="lr">
				<div class="introduction">
					<div class="title">
						{{$t('Introduction')}}
					</div>
					<div>
						{{$t('jiaoyi.text52')}}
					</div>
				</div>
				<img v-if="false" src="../../static/new/img_work.png" />
			</div>
		</div>


      <div class="list_name">
			{{$t('jiaoyi.text53')}}
      </div>
		 
        <div class="market">
            <div class="list" v-for="(item, index) in zhiyalist" :key="index" @click="toInfo(item.id)">
                <div class="flexbox">
                    <div class="texttian">
						{{item.tian}} {{$t('jiaoyi.text54')}}
					</div>
                    <div class="textVal">
                        {{item.name}}
                    </div>
                </div>
				<div style="height: 25px;"></div>
				<div class="flexboxs">
				    <div>{{$t('jiaoyi.text55')}}</div>
					<div>${{item.stnum}}-{{item.maxstrnum}}</div>
				</div>
				<div class="flexboxs">
				    <div>{{$t('jiaoyi.text56')}}</div>
					<div>{{item.dfee}}%</div>
				</div>
				<div class="flexboxs">
				    <div>{{$t('jiaoyi.text57')}}</div>
					<div>
						<img :src="'https://vanke-admin.h98.net' + item.img" style="width: 20px;height: 20px;border-radius: 50px;">
					</div>
				</div>
            </div>
        </div>
		
		
    </div>
</template>


<script>
import {
    toRefs,
    reactive,
    onMounted,
    nextTick
} from 'vue'

import {
    useRouter
} from 'vue-router'

import {financinlist,logall} from "@/api/tab2";


export default {
    components: {},
    setup() {
        let router = useRouter();


        // 数据
        const State = reactive({
			isBlack: 'true',//默认黑色（缓存内的值是字符串）
            zhiyalist:[],
			topinfo:[]
        });

        // 方法
        const Function = reactive({
          goBack(){
            router.back()
          },
            setTheme() {
                //console.log('主题模式', localStorage.getItem('isBlack'))
                State.isBlack = 'true';
                if (localStorage.getItem('isBlack')) {
                    State.isBlack = localStorage.getItem('isBlack')//true代表黑色主题，false代表白色主题。。。 缓存内的值是字符串
                }
            },
            getData() {
				financinlist({type:0}).then(res => {
					State.zhiyalist = res.data.info
					console.log(res.data)
				})
				
            },
          //质押消息
          balance() {
			logall().then(res => {
				console.log('顶部信息',res.data)
				State.topinfo = res.data.info
			})
          },
		  toInfo(id){
			router.push({
			    path: "/buyStep",
				query:{
					id
				}
			}); 
		  },
		  gotopledgehistory(){
			router.push({
			    path: "/pledgehistory",
			}); 
		  }
        });
        // 接口
        const Interface = reactive({});

        onMounted(() => {
            Function.getData()
            Function.setTheme()
			Function.balance()
        });
        return {
            ...toRefs(State),
            ...toRefs(Function),
            ...toRefs(Interface),
        }
    },
}
</script>

<style scoped lang="scss">
.homePage {
    background-color: #000;
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;

  .header-box{
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 0 20px;
  }
  .top-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(rgba(150, 234, 99, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
    .crpto{
      margin-top: 40px;
      text-align: center;
      font-family: Blanc Groove, Blanc Groove;
      font-weight: 900;
      font-size: 48px;
      color: #FFFFFF;
      line-height: 48px;
    }
	.crpto_order{
		border: 1px solid rgba(255, 255, 255, 0.3);
		padding: 5px 20px;
		border-radius: 6px;
		color: #fff;
		font-size: 15px;
		margin: 0 auto;
	}
  }
  .account-box-border{
    margin: 0 20px;
    border-radius: 12px;
    padding: .6px;
    background: linear-gradient(175deg, rgba(166, 203, 144, 1) 0, rgba(39, 58, 27, 0.1) 100%);
    .account-box{
      padding: 22px 20px 20px 20px;
      background: #07070A;
      border-radius: 12px;
      .account-wrapper{
        display: flex;
        .account{
          flex: 1;
          .name{
            font-weight: 400;
            font-size: 10px;
            color: rgba(255, 255, 255, .5);
            line-height: 10px;
          }
          .value{
            margin-top: 20px;
            font-weight: bold;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 14px;
          }
        }
      }
      .btn{
        height: 40px;
        margin: 20px 20px 0 20px;
        background: #96EA63;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 16px;
        color: #1E2F14;
        line-height: 20px;
      }
  }

  }
  .search-box{
    height: 40px;
    margin: 20px 20px 23px 20px;
    background: rgba(255,255,255,0.05);
    border-radius: 8px;
    border: 1px solid rgba(255,255,255,0.15);
    display: flex;
    align-items: center;
    padding: 0 20px;
    .search-input{
      flex: 1;
      background-color: transparent;
      font-weight: 400;
      font-size: 10px;
      color: #FFFFFF;
      line-height: 10px;
    }
  }
  
  .list_name{
	width: 90%;
	margin: 0 auto;
	margin-top: 20px;
	color: #ffffff;
  }
  
	.qiehuan{
		width: 90%;
		height: 219px;
		margin: 0 auto;
		color: #ffffff;
		backdrop-filter: blur(15px);
		// display: flex;
		// align-items: center;
		margin-bottom: 24px;
		border-radius: 8px;
		border: 1px solid rgba(255,255,255,0.3);
		padding: 10px 0;
		margin-top: 40px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		
		.alr{
			padding-top: 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.alr_k{
			flex: 1;
			text-align: center;
		}
		.lr{
			display: flex;
			justify-content: space-between;
			padding-bottom: 50px;
			
			.introduction {
			    margin-left: 20px;
			    font-family: PingFang SC;
			    font-weight: 400;
			    font-size: 12px;
				
				.title {
				    font-size: 14px;
				    color: #7b6cf6;
				    margin-bottom: 14px;
				}
			}
		}
	}

    .market {
        width: 90%;
        backdrop-filter: blur(15px);
        margin: 0 auto;
        overflow: hidden;

        .list {
            height: auto;
			// display: flex;
			margin-bottom: 24px;
			align-items: center;
			justify-content: space-between;
			border: 1px solid rgba(255, 255, 255, 0.3);
			padding: 20px;
			font-size: 15px;
			margin-top: 20px;
			border-radius: 6px;

            .flexbox {
                display: flex;
                align-items: center;
            }
			.flexboxs{
				display: flex;
				align-items: center;
				justify-content: space-between;
				color:#ffffff;
				margin-top: 10px;
			}
			.texttian{
				min-width: 63px;
				padding: 0 5px;
				height: 30px;
				color: #ffffff;
				text-align: center;
				line-height: 30px;
				background: #96EA63;
				border-radius: 6px;
				margin-right: 15px;
				font-size: 14px;
			}
			
            .nowNum {
              font-weight: bold;
              font-size: 16px;
              color: #FFFFFF;
              line-height: 19px;

            }

            .marketIcon {
                width: 45px;
                margin-right: 13px;
                border-radius: 5px;
            }

            .textVal {
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: #fff;
                min-width: 45px;

                .name {
                    margin-bottom: 4px;
                    font-weight: bold;
                    font-size: 16px;
                    color: #FFFFFF;
                    line-height: 19px;
                }

                .desc {
                    font-weight: 400;
                    font-size: 14px;
                    color: #6C757D;
                    line-height: 16px;
                }
            }

            .graph {
                height: 40px;
                width: auto;
            }
        }
    }
}
.pop-box{
  width: 335px;
  background-color: #0D0E14;
  border: 1px solid rgba(255, 255, 255, 0.30);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 20px 20px 20px;
  box-sizing: border-box;
  position: relative;
  .title{
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 16px;
  }
  .close-icon{
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .type-box{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    .type{
      flex: 1;
      font-weight: 400;
      font-size: 16px;
      color: rgba(255,255,255,0.3);
      line-height: 16px;
      height: 40px;
      border-radius: 99px;
      border: 1px solid rgba(255,255,255,0.15);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .active{
      background: #FFFFFF;
      font-weight: bold;
      color: #333333;
    }
  }
  .btn{
    width: 100%;
    height: 40px;
    margin-top: 30px;
    background: #96EA63;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    color: #1E2F14;
    line-height: 20px;
  }
}

.whiteBox {
    background-color: #fff;

    .market {
        .list {
            .nowNum {
                .money {
                    color: #000;
                }

                .desc {
                    color: #000;

                }
            }

            .textVal {
                .name {
                    color: #000;

                }
            }
        }

        .list:nth-child(1),
        .list:nth-child(2) {
            .nowNum {
                .money {
                    color: #fff;
                }

                .desc {
                    color: #fff;

                }
            }

            .textVal {
                .name {
                    color: #fff;
                }
            }
        }
    }
}</style>
