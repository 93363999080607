export default {
	"830753-0": "チャージ",
	"830753-1": "資産を迅速にチャージ",
	"830753-2": "証拠書類をアップロードしてください",
	"830753-3": "チャージを確認",
	"830753-4": "サーバーの応答データが正しくありません:",
	"830753-5": "画像アップロードに失敗しました:",
	account: {
		"Start_making_money_plan": "お金を稼ぐ計画",
		"Market": "市場",
		"Migital_currency": "デジタル通貨",
		"Foreign_exchange": "外国為替",
		"Noble_metal": "貴金属",
		"Send_Cryop_Now": "今すぐ凍結を送る",
		"Send": "送信",
		"Receive": "受け取る",
		"Buy": "購入",
		"Currency_account": "通貨アカウント",
		"Contract_account": "契約アカウント",
		"Transfer": "転送",
		"Select_a_wallet": "ウォレットを選択",
		"Select_Coin": "コインを選択",
		"Confirm": "確認",
		"Wallet": "ウォレット",
		"Availavle": "利用可能",
		"Frozen": "凍結中",
		"Convert": "変換",
		"Receiving_Address": "受け取りアドレス",
		"Amount": "数量",
		"Max": "最大",
		"Send_Now": "今すぐ送信",
		"Please_check_if": "資産の損失を避けるために、送信する前に配送先住所が正しいかどうかを確認してください。",
		"new1": "暗号通貨",
		"new2": "指数",
		"new3": "コモディティ",
		"new4": "外国為替",
		"new5": "総資産"
	},
	"314962-0": "Google 2FA",
	"314962-1": "バインドする Google Authenticator をダウンロード",
	"314962-2": "確認コードをコピー",
	"314962-3": "確認コードを入力してください",
	"314962-4": "リクエストが失敗しました:",
	"314962-5": "検証コードがコピーされました",
	"314962-6": "テキストをコピーできません:",
	"314962-7": "コピーに失敗しました。もう一度お試しください。",
	"199917-0": "設定",
	"199917-1": "通知",
	"199917-2": "Google 2FA",
	"199917-3": "電子メール",
	"199917-4": "言語",
	"199917-5": "バージョン",
	"199917-6": "ログアウト",
	"516529-0": "アカウント設定",
	"516529-1": "アカウント情報の理解",
	"516529-2": "オンラインサポート",
	"516529-3": "アプリケーションを共有する",
	"516529-4": "基本設定",
	"516529-5": "メール設定",
	"516529-6": "言語変更",
	"516529-7": "価格通知",
	"516529-8": "法律と情報",
	"516529-9": "サービス利用規約",
	"516529-10": "マネーロンダリング防止ポリシー",
	"516529-11": "クッキー政策",
	"516529-12": "ヘルプと答えを得る",
	"516529-13": "始めます",
	"516529-14": "トークン交換",
	"516529-15": "トラブルシューティング",
	"516529-16": "友達に推薦する",
	"516529-17": "友達と共有し、推薦手数料を得る",
	"516529-18": "リンクをコピーする",
	"516529-19": "市場の更新情報を受けるためにメールを設定する",
	"516529-20": "今すぐ確認する",
	"516529-21": "メールアドレスを入力してください…",
	"516529-22": "確認コードを入力してください…",
	"516529-23": "あなたのメールは、私たちのサービスに関連する有用な情報やリマインダーを受け取るためにだけ使用され、悪用されることはありません！",
	"516529-24": "保存",
	"516529-25": "ウェブサイトの言語を選択する",
	"516529-26": "データのセキュリティとプライバシーを保護することに全力を尽くしています",
	"516529-27": "中国語（伝統的）",
	"516529-28": "日本語",
	"516529-29": "確認コードを送信する",
	"516529-30": "成功してコピーされました！",
	"516529-31": "再送信",
	home: {
		USDTAccount: 'USDTアカウント',
		USTD: 'USDT',
		USDT: 'USDT',
		USTC: 'USDC',
		open: '承認する',
		ETHTodayProfit: '今日のETH利益',
		totalRevenue: '総収益',
		portfolio: '投資する',
		"reliableSecurity": "信頼できるセキュリティ",
		"stableReliable": "投資は安定して信頼できます",
		"convenienEasy": "操作が簡単で便利",
		"pleaseLookForward": "お楽しみに...",
		"rateOfReturn": "利回り",
		"walletnot": "MetaMask または任意のイーサリアム拡張ウォレットをインストールしてください",
		"wallettip": "体験に影響が出ないように、アドレスを紐づけてください"
	},
	"ConnectW": "ウォレットを接続",
	"dappbrowser": "このサイトは分散型ウォレットDAPPブラウザのみでアクセスできます。",
	"Coins POS": "CoinB Pos",
	"9dcf43fa47": "9dcf43fa47",
	"Earn Interest": "利息を稼ぐ",
	"$": "$",
	"ETH Today's Profit": "ETH本日の利益",
	"Wallet": "ウォレット",
	"Record": "注文",
	"Support": "サポート",
	"General": "総合",
	"Notifications": "通知",
	"Invite Friends": "友達を招待",
	"FAQ": "よくある質問",
	"Privacy & Security": "プライバシーとセキュリティ",
	"Legality": "プライバシーポリシー",
	"Authenticator": "本人確認",
	"Settings": "設定",
	"Change Language": "言語を変更",
	"Daytime mode": "昼間モード",
	"Black mode": "夜間モード",
	"BTC": "BTC",
	"Bitcoin": "ビットコイン",
	"ETH": "ETH",
	"ethereum": "イーサリアム",
	"USDT": "USDT",
	"tether": "テザー",
	"USDC": "USDC",
	"usd-coin": "USDコイン",

	// marketInfo.vue
	'Open': 'オープニング',
	'High': '最高',
	'Low': '最低',
	'Close': '閉盤',
	'Up': '買い占め',
	'Down': '買うか売るか',
	'Time': '時間',
	'TransactionFee': '手数料',
	'AvailableBalance': '利用可能残高',
	"submit": "サブスクリプション",
	"Direction": "方向",
	"Profit": "利益",
	"Quantity": "数量",
	"Price": "価格",
	"different": "異なる割合をクリックすると最小制限が表示されます",
	"lessthan": "少なくとも以下より小さくできません",
	"least": "最小：",
	"Transactionhistory": "取引履歴",
	"Transactionrecords": "取引記録",
	"Number": "数量",
	"Profitloss": "利益損失",
	"Ror": "リターン",
	"operate": "操作",





	//钱包
	"TransactiónDetails": "取引明細",
	"dealdetails": "取引詳細",
	"ViewTransactionHistory": "取引履歴を表示",
	"Deposit": "入金",
	"Rechargeaddress": "入金アドレス：",
	"DepositAmount": "入金額：",
	"EnterDepositAmount": "入金額を入力",
	"EnterHash": "ハッシュ値を入力",
	"Hash": "ハッシュ値：",
	"Voucherimage": "証拠画像：",
	"Continue": "続行",
	"Withdraw": "出金",
	"Withdrawal": "引き出し：",
	"EnterAmounttoWithdraw": "引き出し金額を入力",
	"Available": "利用可能：",
	"ReceiveAddress": "受取アドレス：",
	"EnterWalletAddress": "ウォレットアドレスを入力",
	"WithdrawalDesc": "英語での引き出しには1％の手数料が必要で、ネットワークノードの確認後に反映されます。暗号通貨を他人に送らないでください",
	"Exchange": "交換",
	"Send": "送信：",
	"Max": "最大：",
	"rechargeAmount": "入金額を入力してください",
	"hashcode": "ハッシュ値とスクリーンショットの両方を空にすることはできません",
	"correcthashcode": "正しいハッシュ値を入力してください",
	"maximumwithdrawalamount": "最大引き出し金額は",
	"Incorrectamountformat": "金額形式が正しくありません",
	"copy": "コピー",


	//tab2
	"Total Profit": "総利益",
	"Today's Profit": "本日の利益",
	"What is Automated Trading?": "自動取引とは？",
	"Learn about earning": "収益について学ぶ",
	"Staking Period": "ステーキング期間",
	"Staking Amount": "ステーキング数量",
	"Yield": "利回り",
	"Limit": "制限",
	"Subscribe": "サブスクリプション",

	//tab3
	"Proof of Stake": "ステーク証明",
	"ETH Today is Price": "ETH 本日の価格",
	"ETH Today's Increase": "ETH本日の上昇率",
	"Joint Staking": "共同ステーキング",
	"Individual Staking": "個別ステーキング",
	"tab3long1": "スマートコントラクトを通じて参加者をマッチングし、共同ベッティングを実現し、合計ベット額が32 ETHに達します。",
	"tab3long2": "個別ベッティングは、スマートコントラクトを介して参加者をマッチングする必要がありません。 32 ETHのベット額に達すると、個別ベッティングが完了します。",


	//web_view
	"webviewlong1": "暗号通貨のエアドロップは、新興のブロックチェーンベースのプロジェクトの発展を促進します。 エアドロップは、報酬と共通の目標を通じてコミュニティメンバーをつなぎます。 成功したエアドロップの結果として、より強力なコミュニティ、より良いプロジェクト、最終ユーザーへの報酬が生まれます。 長年にわたり、多くのエアドロップがプルーフ・オブ・ワーク（PoW）ブロックチェーン上で行われてきました。 プルーフ・オブ・ステーク（PoS）イーサリアムチェーンは、現在、数十のネットワーク上で何百ものプロジェクトによる暗号通貨のエアドロップをサポートしています。 PoS が主流のコンセンサスパラダイムとなる中、イーサリアムネットワーク上のエアドロップの数は減速の兆しを見せていません。",
	"webviewlong2": "暗号通貨をステーキングすることで、ステーキングした暗号通貨の量に応じて価値のあるエアドロップ報酬を獲得する資格を得られます。",
	"webviewlong3": "ステーキング報酬はステークホルダーにのみ提供され、これは何百もの異なるプロジェクトのエアドロップからのもので、個別のエアドロップよりもはるかに高い収益を生み出します。",
	"webviewlong4": "取引所に暗号通貨を預けている所有者にとって、エアドロップ報酬を受け取るのは難しいことが多く、一部のカストディアンはエアドロップをサポートしていません。 そのため、自分の暗号通貨を管理することが重要です。 イーサリアムはカストディアンではない存在であり、すべての主要ネットワークでステーキングを行うことができます。",
	"Introduction": "紹介",
	"webviewlong5": "主要なブロックチェーンプラットフォームであるイーサリアムは、イーサリアム2.0のアップグレードを通じて、プルーフ・オブ・ワーク（PoW）からプルーフ・オブ・ステーク（PoS）コンセンサスメカニズムへと移行しています。 PoSステーキングはこの移行の重要な要素であり、ユーザーにネットワークのセキュリティと運営をサポートする機会を提供し、同時に報酬を獲得する可能性を提供します。 本文では、イーサリアム PoS ステーキングとその利点について概説します。",
	"Ethereum 2.0 and Proof of Stake (PoS)": "イーサリアム 2.0 とプルーフ・オブ・ステーク (PoS)",
	"webviewlong6": "イーサリアム 2.0 は、スケーラビリティ、セキュリティ、持続可能性を向上させることを目的とした大規模なネットワークアップグレードです。 主要な変更点の1つは、エネルギー集約型の PoW コンセンサスメカニズムから、より環境に優しい PoS メカニズムへの移行です。 PoS では、検証者は保有する暗号通貨の量に応じて選ばれ、その暗号通貨を担保として「ステーク」する意志を持ち、トランザクションを検証します。",
	"The Staking Process": "ステーキングのプロセス",
	"webviewlong7": "イーサリアム PoS ステーキングに参加するには、ユーザーは少なくとも 32 ETH をイーサリアム 2.0 の預金コントラクトにステーキングする必要があります。 一度ステーキングされると、ETH は一定期間ロックされ、ネットワークのセキュリティを確保する担保として機能します。 検証者は、ステーキングした ETH の量とオンライン活動に基づいて選ばれます。 彼らは、新しいブロックの提案と検証、およびネットワーク上のトランザクションの確認を担当します。",
	"Staking Rewards and Risks": "ステーキングの報酬とリスク",
	"webviewlong8": "PoS ステーキングに参加することで、ユーザーは新たに鋳造された ETH や取引手数料の形で報酬を得ることができます。 報酬は、ステーキングした ETH の量とネットワーク全体の活動に基づいて決定されます。 しかし、ステーキングにはリスクも伴い、検証者が悪意のある行動を取ったり、オンライン状態を維持できなかった場合、罰則を受ける可能性があります。 さらに、ステーキングされた ETH は一定期間ロックされ、流動性が失われ、価格変動の影響を受けやすくなります。",
	"Joint Staking and Individual Staking": "共同ステーキングと個別ステーキング",
	"webviewlong9": "32 ETH を単独でステーキングするのに十分な資金がないユーザーには、共同ステーキングという別の選択肢があります。 共同ステーキングでは、複数のユーザーが ETH をプールして必要なステーキング額に達します。 このプールは通常、スマートコントラクトまたはステーキングサービスによって促進され、少量の ETH でイーサリアム PoS ステーキングに参加することができます。",
	"Conclusion": "結論",
	"webviewlong10": "イーサリアム PoS ステーキングは、イーサリアム 2.0 アップグレードの重要な側面であり、ユーザーにネットワークセキュリティのサポート、分散化の改善、および報酬を獲得する機会を提供します。 ステーキングのプロセス、その報酬とリスクを理解することで、ユーザーはこの重要な進化に参加するための賢明な判断を下すことができます。",


	//mywallet
	"Total": "一時金",

	//record
	"Account": "アカウント",
	"interest-bearing": "興味",
	"POS Staking": "POS誓約書",
	"convert": "交換",
	"transaction": "貿易",
	"pledge": "モーゲージ",
	"recordno": "まだ何も聞いていませんね。 リストは空です。",
	"Oops": "おっと",
	"recharge": "補充する",
	"payment": "支払う",
	"staking": "誓約",
	"income": "所得",
	"Pledge quantity": "誓約数量",
	"cycle": "サイクル",
	"Days Remaining": "残り日数",
	"cumulative gain": "累積ゲイン",
	"Price increase": "値上げ",
	"Price decrease": "値下げ",
	"Opening price": "始値",
	"Closing price": "終値",
	"loss": "損失",
	"pfofit": "利益",
	"amount": "量",

	//notifiCations
	"notifiCationslong1": "注：VIP登録は、対応する全体アカウントのリチャージ金額に基づいてランクが決まります。この範囲は、現在のアカウントからの出金後の総リチャージ金額を差し引いて会員ランクを決定します。",
	"notifiCationslong2": "会員期限もランクに応じて分けられています。会員資格を維持するためには、指定された期間内に毎回少なくとも5,000 USDTをリチャージする必要があります。リチャージごとに会員資格が更新され、期間が再計算されます。",
	"notifiCationslong3": "カスタマーサービスは会員ランクによって分けられています。公式のカスタマーサポートは、アプリスタッフが提供し、すべての会員に統一されたサービスを提供します。専属の秘書は限られており、より良いサービスを保証します。専属のプライベート秘書は、ユーザーに個別のサポートと資産管理のアドバイスを提供します。",
	"notifiCationslong4": "リチャージの前にカスタマーサポートに連絡して予約してください。指定された期間内にリチャージを完了すると、会員ランクに応じて追加のリチャージボーナスが獲得できます。",
	"notifiCationslong5": "VIP1：UIDアカウントに基づき、累計リチャージ額が10,000 USDに達すると、177 USDTのボーナスが獲得できます。VIPランクに到達し、一般会員メダルを獲得すると、ポイントがアカウントにエアドロップされます。",
	"notifiCationslong6": "VIP2：UIDアカウントに基づき、累計リチャージ額が30,000 USDに達すると、777 USDTがプレゼントされます。ボーナスはVIPランクに到達し、銅メンバーメダルを獲得した後、アカウントに即座に入金されます。",
	"notifiCationslong7": "VIP3：UIDアカウントに基づき、累計リチャージ額が70,000 USDに達すると、1,777 USDTがプレゼントされます。VIPランクに到達し、銀メンバーメダルを獲得した後、ポイントがアカウントにエアドロップされます。さらに、次回のリチャージ予約額に基づいて1.5％の追加ボーナスが付与されます。",
	"notifiCationslong8": "VIP4：UIDアカウントに基づき、累計リチャージ額が150,000 USDに達すると、2,777 USDTがプレゼントされます。VIPランクに到達し、金メンバーメダルを獲得した後、ポイントがアカウントにエアドロップされます。さらに、次回のリチャージ予約額に基づいて2％の追加ボーナスが付与されます。",
	"notifiCationslong9": "VIP5：UIDアカウントに基づき、累計リチャージ額が300,000 USDに達すると、4,777 USDTがプレゼントされます。VIPランクに到達し、ダイヤモンドVIPメダルを獲得した後、ポイントがアカウントにエアドロップされます。さらに、次回のリチャージ予約額に基づいて2.5％の追加ボーナスが付与されます。",
	"notifiCationslong10": "VIP6：UIDアカウントに基づき、累計リチャージ額が800,000 USDに達すると、16,888 USDTがプレゼントされます。VIPランクに到達し、ブラックダイヤモンドメンバーメダルを獲得した後、ボーナスが即座にエアドロップされ、次回のリチャージ予約額に基づいて3％の追加ボーナスが付与されます。さらに、専属のプライベート秘書が資産管理のアドバイスや税務問題の処理を支援します。",
	"notifiCationslong11": "VIP7：UIDアカウントに基づき、累計リチャージ額が300万USDに達すると、15日間のヨーロッパ豪華旅行（割引付き）がプレゼントされます。さらに、CoinBposの終身栄誉VIP資格が授与され、クラウンメンバーメダルが獲得されます。次回のリチャージ予約額に基づいて3.5％の追加ボーナスが付与されます。",
	"notifiCationslong12": "VIP8：UIDアカウントに基づき、累計リチャージ額が800万USDに達すると、CoinBposモロッコ年次宴会のバウチャーが1枚プレゼントされます。報酬として1 BTCが贈られ、CoinBposの終身栄誉VIP資格が授与されます。次回のリチャージ予約額に基づいて4％の追加ボーナスが付与されます。",
	"notifiCationslong13": "VIP9：UIDアカウントに基づき、累計リチャージ額が3,000万USDに達すると、CoinBposの3％の株式が付与されます。さらに、年間3％の利益配分が受けられます。無料でCronix純金記念メダルも贈られます。",

	//invite
	"Refer and Earn": "紹介して獲得する",
	"Copy Link": "リンクをコピー",

	//faq
	"Frequently Asked Questions": "よくある質問",

	//authenticator
	"mailbox": "メールボックス",
	"Front photo": "正面写真",
	"Back photo": "背面写真",
	"Handheld ID photo": "証明写真を持っている",
	"define": "もちろん",

	//legality
	"legalitylong1": "Coinbposは複数の暗号通貨会社で構成されており、これらの会社が共同でツールを提供し、暗号コミュニティが安定したコミュニティを構築、発展、維持するのを支援します。これにより、数千の分散型アプリケーションに電力を供給し、暗号通貨の未来を推進し、従来の通貨のデジタル化と世界経済の繁栄を促進しています。",
	"legalitylong2": "Coinbpos内の各会社は、それぞれのサービスに関連する個人データのデータ管理者として機能します。各サービスのデータ管理者は以下の通りです：",
	"legalitylong3": "1. ブロックチェーン技術 - イーサリアムブロックチェーン。",
	"legalitylong4": "2. 分散型ウォレット - Trustウォレット、MetaMask、および暗号ウォレット。",
	"legalitylong5": "3. 取引技術 - Coinbase、Crypto、linch、Binance。",
	"legalitylong6": "プライバシーポリシーは、Coinbposが関連するデータ保護法に基づく義務に従って個人データをどのように処理するかを詳述しています。これには、欧州連合の「一般データ保護規則」（GDPR）、",
	"legalitylong7": "「カリフォルニア州消費者プライバシー法」（CCPA）およびその他の一般法（総称して「データ保護法」）が含まれます。",

	//language
	"language": "言語",
	"Confirm": "確認する",

	"newtext1": "終了",
	"newtext2": "キャンセル",
	"newtext3": "このアカウントからログアウトしてもよろしいですか?",

	"newtext4": "中文简体",
	"newtext5": "中文繁体",
	"newtext6": "English",
	"newtext7": "日本語",

	"newtext8": "ログイン",
	"newtext9": "ユーザー名",
	"newtext10": "携帯電話番号",
	"newtext11": "ユーザー名を入力してください",
	"newtext12": "携帯電話番号を入力してください",
	"newtext13": "パスワード",
	"newtext14": "パスワードを入力してください",
	"newtext15": "まだアカウントがありません",
	"newtext16": "登録",
	"newtext17": "国",
	"newtext18": "国を選択してください",
	"newtext19": "携帯電話番号認証コード",
	"newtext20": "確認コードを入力してください",
	"newtext21": "メールアドレス",
	"newtext22": "メールアドレスを入力してください",
	"newtext23": "パスワードの確認",
	"newtext24": "確認用のパスワードを入力してください",
	"newtext25": "アカウントをお持ちです",
	"newtext26": "確認コードを送信",
	"newtext27": "携帯電話番号が間違っています",
	"newtext28": "正常に送信されました",
	"newtext29": "登録成功",

	"newtext30": '通貨',
	"newtext31": '外国為替',
	"newtext32": '貴金属',

	"newtext33": 'アカウント',
	"newtext34": 'プラットフォームアクティビティ',
	"newtext35": 'オンライン顧客サービス',
	"newtext36": '招待',
	"newtext37": 'KYC 検証',
	"newtext38": '設定',
	wallet_p: {
		text1: "アドレスをコピー",
		text2: '確認',
		text3: '交換元...',
		text4: "交換先",
		text5: "マックス",
		text6: "招待状",
		text7: '推薦報酬を獲得',
		text8: "友達が AI アービトラージに参加してマイニング マシンをレンタルすると、紹介報酬を得ることができます",
		text9: "コピー",
		text10: 'リンクを共有',
		text11: "KYC 検証",
		text12: "国",
		text13: "国を選択してください",
		text14: "名前",
		text15: "あなたの名前を入力してください",
		text16: "姓",
		text17: "姓を入力してください",
		text18: "証明書の種類",
		text19: "証明書の種類を選択してください",
		text20: "証明書番号",
		text21: "証明書番号を入力してください",
		text22: "ID カードの正面の写真をアップロードしてください",
		text23: "IDカードの裏面の写真をアップロードしてください",
		text24: "パスポートの正面の写真をアップロードしてください",
		text25: "パスポートの裏面の写真をアップロードしてください",
		text26: "電子メール",
		text27: "メールアドレスを入力してください",
		text28: "送信",
		text29: "検証コード",
		text30: "確認コードを入力してください",
		text31: "IDカード",
		text32: "パスポート",
		text33: "キャンセル",
		text34: "国/地域",
		text35: "確認送信のため正しい情報を入力してください",
		text36: "正常に送信されました!",
		text37: "電子メール形式エラー!",
		text38: "運転免許証",
		text39: "運転免許証の正面の写真をアップロードしてください",
		text40: "運転免許証の裏面の写真をアップロードしてください",

		text41: "IDカード番号",
		text42: "ID 番号を入力してください",
		text43: "パスポート番号",
		text44: "パスポート番号を入力してください",
		text45: "運転免許証番号",
		text46: "運転免許証番号を入力してください",
	},
	jiaoyi: {
		text1: '2 番目の契約',
		text2: '永久契約',
		text3: 'スポット取引',
		text4: '今すぐ委任してください',
		text5: 'アカウントの種類',
		text6: '納期',
		text7: '価格帯',
		text8: '購入価格',
		text9: 'コスト',
		text10: 'バランス',
		text11: '少なくとも',
		text12: '期待される',
		text13: '購入金額',
		text14: '方向',
		text15: '購入価格',
		text16: 'リアルタイム価格',
		text17: 'カウントダウン',
		text18: '私の契約',
		text19: '利益',
		text20: '契約',
		text21: '配送価格',
		text22: '配達時間',
		text23: '市場価格',
		text24: '上昇',
		text25: '秋',
		text26: '購入価格',
		text27: '最大',
		text28: '利益',
		text29: '手数料',
		text30: '購入',
		text31: '販売',
		text32: '購入数量',
		text33: 'リアルアカウント',
		text34: '仮想アカウント',
		text35: 'まだデータがありません',
		text36: '現在の位置',
		text37: '歴史委員会',
		text38: 'クローズポジション',
		text39: 'マージン',
		text40: '損益',
		text41: 'ホールド',
		text42: '購入記録',
		text43: '販売記録',
		text44: '利用可能',
		text45: '変換されました',
		text46: '認証成功',
		text47: '購入時間',
		text48: '未読',
		text49: '読み取り',
		text50: 'ホストの順序',
		text51: '合計アービトラージ',
		text58: '今日の収益',
		text52: '人工知能ロボットの仕組み',
		text53: '裁定取引商品',
		text54: '日',
		text55: '金額',
		text56: '毎日の収入',
		text57: '通貨の種類',
		text59: 'AI アービトラージに参加',
		text60: 'リスクゼロ、迅速なリターン',
		text61: 'AI アービトラージ',
		text62: '期待収益',
		text63: 'アービトラージコインタイプ',
		text64: 'エスクロー金額',
		text65: '今すぐホスト',
		text66: 'USDT ウォレットに送られる毎日の収入',
		text67: '投資ファンドゼロリスク',
		text68: '資金はいつでも引き出す​​ことができます',
		text69: '人工知能は 24 時間稼働します',
		text70: '累計収入',
		text71: '有効期限',
		text72: 'すべて',
		text73: '進行中',
		text74: '完了',
		text75: 'ホストの詳細',
		text76: '通貨を選択',
		text77: 'テクニカルサポート',
		text78: 'メールを送信',
		text79: '取引タイプ',

		text80: '読み込み中...',
		text81: 'もうだめです',
		text82: 'ホワイトペーパー',
	},
	
	swapadd:{
			text1:'Cookie ポリシー',
			text2:'私たちはあなたのデータのセキュリティとプライバシーの保護に全力で取り組んでいます',
			text3:'はじめに',
			text4:'この Cookie ポリシーでは、当社の分散型取引所が Cookie および同様の追跡技術をどのように使用するかについて説明します。 Exchange にアクセスまたは使用すると、このポリシーに記載されている Cookie の使用に同意したことになります。 ',
			text5:'クッキーとは何ですか?',
			text6:'Cookie は、Web サイトにアクセスしたときにデバイス (コンピューター、携帯電話、またはタブレット) に配置される小さなテキスト ファイルです。これらは、ブラウジング エクスペリエンスを向上させ、Web サイトにパーソナライズ機能を提供するために広く使用されています。 Cookie には、セッション Cookie (一時的なもので、ブラウザを閉じると削除される) または永続的な Cookie (デバイス上に長期間残る) があります。 ',
			text7:'Cookie の使用方法',
			text8: '取引所が Cookie を使用する目的には以下が含まれますが、これらに限定されません:',
			text9:'交換の基本機能と機能を有効にする',
			text10:'交換のパフォーマンスと機能を分析して改善する',
			text11:'エクスペリエンスをパーソナライズし、好みを記憶してください',
			text12:'取引所での使用パターンを追跡して理解する',
			text13:'関連する広告およびマーケティングコミュニケーションを提供する',
			text14:'使用する Cookie の種類',
			text15:'a) 必須 Cookie: これらの Cookie は取引所の運営に必要であり、お客様が取引所の機能にアクセスして使用できるようにします。これらの Cookie がないと、一部のサービスが利用できない場合があります。 ',
			text16:'b) 分析 Cookie: これらの Cookie は、アクセスしたページや発生したエラーなど、取引所の使用方法に関する情報を収集します。当社はこの情報を使用して、Exchange のパフォーマンスと機能を分析および改善します。 ',
			text17:'c) 機能性 Cookie: これらの Cookie により、Exchange はユーザーの選択 (言語設定など) を記憶し、強化された、よりパーソナライズされた機能を提供できるようになります。 ',
			text18:'d) 広告 Cookie: これらの Cookie は、お客様およびお客様の興味に関連した広告を提供するために使用されます。また、広告の表示回数を制限したり、広告キャンペーンの効果を測定したりするために使用される場合もあります。 ',
			text19:'サードパーティ Cookie',
			text20:'Exchange では、分析や広告などのさまざまな目的で、サードパーティのサービス プロバイダーがデバイスに Cookie を配置することを許可する場合があります。これらの第三者は独自のプライバシー ポリシーを持っており、さまざまな Web サイトやオンライン サービスにわたるお客様のオンライン活動に関する情報を収集する場合があります。 ',
			text21:'Cookie 管理',
			text22:'ブラウザの設定でいつでも Cookie を管理または削除できます。ただし、特定の Cookie を無効にしたり削除すると、交換の機能やパフォーマンスに影響を与える可能性があることに注意してください。 ',
			text23:'同意する',
			text24:'Exchange を使用すると、このポリシーに記載されている Cookie の使用に同意したことになります。 Cookie の使用に同意しない場合は、ブラウザの設定を通じて無効にするか削除するか、Exchange を使用しないことができます。 ',
			text25:'ポリシーの更新',
			text26:'当社は、当社の慣行の変更や適用される法律の変更を反映するために、この Cookie ポリシーを定期的に更新することがあります。最新情報については、このポリシーを定期的に確認することをお勧めします。 ',
			text27:'Cookie の使用方法についてご質問やご不明な点がございましたら、カスタマー サポート チームまでお問い合わせください。 ',
			
			text28:'トラブルシューティングとヘルプを入手',
			text29:'はじめに',
			text30:'トークンを交換',
			text31:'トラブルシューティング',
			text32:'分散型取引所とは何ですか? ',
			text33: 'DEX は、分散型ブロックチェーン ネットワーク上で実行される暗号通貨取引所であり、通常はスマート コントラクトに基づいて構築されています。従来の集中型取引所とは異なり、CEX は中央機関に依存して取引を促進し、ユーザー資金を保持します。DEX は、DEX を通じて両当事者と直接取引できます。スマートコントラクト。 ',
			text34:'分散型取引所のセキュリティ',
			text35: 'DEX はブロックチェーン上のスマート コントラクトを使用して動作し、買い手と売り手の間の直接取引を可能にし、サードパーティ機関の必要性を排除します。 DEX はパブリック ブロックチェーン上で実行され、トランザクションは透明性と監査可能であり、誰でも DEX の公平性と整合性を確認することができます。 ',
			text36:'分散型ウォレットとは何ですか? ',
			text37: '分散型ウォレットは、12 個のキーで構成される暗号通貨ウォレットです。ユーザーは 12 個のキーを使用して同じブロックチェーン上のキーを使用してウォレットにログインし、キーが漏洩しない限り、誰も分散型ウォレット内のデジタル資産を取得できません。',
			text38:'ウォレットアドレスとは何ですか? ',
			text39:'暗号通貨のウォレット アドレスは、楕円曲線署名アルゴリズムを通じて秘密キーから取得された公開キーです。ウォレットの変換プロセスでは、不可逆的なハッシュ操作が使用されます。アドレスは主に暗号通貨の送受信に使用されます。 ',
			text40:'資金交換に DEX を使用することの安全性',
			text41:'DEX の動作メカニズムはブロックチェーン上に構築されており、第三者機関を介さず、直接交換のための非保管システムを採用しています。すべてのトランザクションはスマート コントラクトを使用して実行されます。ブロックチェーンにより、資産の透明性、プライバシー、セキュリティを真に実現します。 ',
			text42: '取引所で同じトークンに価格差があるのはなぜですか? ',
			text43: '市場の需要と供給: 取引所は資産の売買を提供しますが、主要な取引所の市場の需要と供給が異なるため、取引所が販売と購入のために設定する価格が変化し、特定のトークンの需要が変化します。通常、価格は市場価格よりも高く、需要が低い取引所では価格が低くなります。 ',
			text44: '取引量: 取引所で売買される暗号通貨の量。価格に影響を与える可能性があります。一般に、取引量が多いほど市場活動が活発であることを示しており、より正確な価格発見が促進されます。取引量が少ない取引所では、買値と売値のスプレッドが広くなり、価格設定がより不正確になる可能性があります。 ',
			text45: '流動性: 大幅な価格変動を引き起こすことなく資産を売買することの容易さと難しさを指します。流動性が高い暗号通貨は、取引所間で価格がより一貫している傾向があります。取引所の流動性が低いと、価格変動や大きな価格差が生じる可能性があります。 ',
			text46: 'トークンの選択: すべてのトークンが同じ利益の可能性を持っているわけではなく、トークンが異なれば、流動性、取引量、価格のボラティリティの程度が異なる場合があります。',
			text47:'為替レートスプレッドとは何ですか? ',
			text48: '為替レート差とは、異なる取引所間の暗号通貨価格の変化を指します。暗号通貨は世界中に分散し、さまざまなプラットフォームで取引されるため、需要と供給のダイナミクス、流動性、取引量、市場状況は取引所ごとに異なる場合があります。 ',
			text49:'DEX交換とは何ですか? ',
			text50:'DEX 交換は、トークン交換または資産交換としても知られ、DEX の交換契約を通じて、ある暗号通貨またはトークンを別の暗号通貨またはトークンと交換するプロセスを指します。 これらのトランザクションは通常、ブロックチェーン上のスマート コントラクトによって促進され、仲介者の必要性が排除されます。 ',
			text51: 'なぜ主要取引所でスプレッドがなくなったのですか?」 ',
			text52:'異なる取引所でトークンの価格に差がない場合、それは市場が比較的安定していることを意味します。この状況の理由は、市場のトレーダーが不均衡な取引に基づいてフラットかつ自動的に裁定取引を行っており、市場の巨人が市場に参加しているためです。」取引裁定取引のための大量の資金を利用する取引、市場トークン価格の平坦維持、取引量と流動性、より高い取引量と十分な流動性、市場の統合はすべて、取引所でスプレッドを消滅させる理由です。',
			text53:'指定されたアドレスは何ですか？ ',
			text54: '指定されたアドレスは、交換が成功した後にユーザーが入力する必要がある暗号通貨受信アドレスです。初めて交換を行うユーザーは、共通の受信アドレスをバインドする必要があります。',
			text55:'価格スリッページとは何ですか? ',
			text56: '価格スリッページ (スリッページとも呼ばれる) は、仮想通貨取引の予想価格と取引が実際に実行される価格の差です。価格のスリッページは通常、市場のボラティリティ、流動性、注文サイズ、注文の種類などによって引き起こされます。暗号通貨のような動きの速い不安定な市場では、価格が急速に変化する可能性があります。 ',
			text57:'交換GASとは何ですか? ',
			text58: 'GAS は、交換が成功した後に暗号通貨を引き出すときにユーザーがブロックチェーンに支払う必要がある GAS 料金です。GAS 料金はユーザーの注文を処理するために使用され、同時にユーザーのトランザクションの成功を確認するためにブロックチェーン内でブロードキャストされます。 。通常、取引所資産を引き出すためのGAS手数料は0.1％です。 ',
			text59:'保留中の注文があるのはなぜですか? ',
			text60: 'ネットワークの輻輳: 需要が高まったりアクティビティが増加したりする期間には、ブロックチェーン ネットワークが輻輳する可能性があります。この混雑により、トランザクション処理時間が遅くなり、手数料が高くなる可能性があります。市場の大きな変動や取引活動の急増により、ネットワークの混雑が発生する可能性があります。 ',
			text61: 'スケーラビリティの問題: 一部のブロックチェーン ネットワークはスケーラビリティの課題に直面しています。これは、ブロックチェーンが大量のトランザクションを迅速かつ効率的に処理できない可能性があることを意味します。これにより、遅延やトランザクション速度の低下が発生する可能性があります。 ',
			text62: '流動性の制約: 市場における買い手と売り手の利用可能性。一部の暗号通貨市場または特定の暗号通貨では、流動性の欠如により取引が遅くなり、買値と売値のスプレッドが拡大する可能性があります。 ',
			text63: '市場注文のサイズ: 注文のサイズは、特に利用可能な流動性に比べて注文が大きい場合、取引の速度に影響します。注文が市場の利用可能な供給または需要と相互作用する場合、大量の買い注文または売り注文を発行すると、部分的に約定したり約定が遅くなり、保留中の注文が発生する可能性があります。 ',
			text64:'未決注文の資産は安全ですか? ',
			text65: '保留中の注文の資金は暗号市場で失われることはありません。トランザクション注文が保留中の場合、注文が一致すると、トランザクションはすぐに実行されます。 ',
			text66:'高速注文処理とは何ですか? ',
			text67: 'トレーダーが注文を開始した時点では需要と供給の関係は存在しないため、ユーザーは取引資金を増やして新しい需要と供給の関係で未決注文の取引を完了することを選択できます。 ',
		},
	login: {
		Get_Started: '始める',
		Best_Swap: '世界最高のスワッププラットフォーム',
		Decentralized_cryptocurrency: '分散型暗号通貨交換プラットフォーム。さまざまなデジタル資産間の高速かつシームレスな交換を可能にします。',
		Cookies_Privacy: 'クッキー プライバシー',
		We_use_cookies: '当社は、お客様のブラウジング体験の向上、ウェブサイトのトラフィックの分析、パーソナライズされたコンテンツの提供のためにクッキーを使用します。このウェブサイトを引き続きご利用いただくことで、お客様は当社のクッキーの使用に同意したことになります。',
		Accept: '受け入れる',
		Sum: '和',
		Swap: 'スワップ',
		Balance: 'バランス',
		Name: '名前',
		Price_Change: '価格変更',
		Sort_Type: '並べ替えの種類',
		Add_Asset: 'アセットを追加',
		Add_the_cryptocurrency: '必要な暗号通貨資産を追加する',
		Current_Market_Conditions: '現在の市場状況',
		Market: '市場',
		Overview: '概要',
		Coin_Information: 'コイン情報',
		Market_Value: '市場価値',
		Total_Supply: '総供給量',
		In_Circulation: '流通中',
		Trading_Volume: '取引量',
		Official_Website: '公式ウェブサイト',
	}
}
