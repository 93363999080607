<template>
  <div class="home">
    <div class="top_section">
      <div class="nav_header">
        <div class="logo_content">
          <span class="color">{{ $t("login.Sum") }}</span>
          <span>{{ $t("login.Swap") }}</span>
        </div>
        <div class="link_content">
          <span>{{ userName }}</span>
          <img src="../../static/login/icon_link.svg" alt="" />
        </div>
      </div>
      <div class="balance_container">
        <div class="value">${{ balance }}</div>
        <div class="change">≈0.000000BTC</div>
      </div>
      <div class="pro_list_header">
        <div class="label" @click="showSortType = true">
          <!----><span> {{ sortTypeList[sortTypeIndex] }} </span
          ><!---->
          <img src="../../static/login/icon_arrow_down.svg" alt="" />
        </div>
        <div class="action" @click="showAddAsset = true">
          <img src="../../static/login/icon_set.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="pro_list_container">
      <van-list
        v-model:loading="vantloading"
        :finished="finished"
        :loading-text="$t('jiaoyi.text80')"
        :finished-text="$t('jiaoyi.text81')"
        @load="datasload"
      >
        <div class="pro_item" v-for="(item, index) in coinList" :key="index">
          <div
            class="item_header"
            @click="
              showSwapIndex === index
                ? (showSwapIndex = -1)
                : (showSwapIndex = index)
            "
          >
            <div class="info">
              <img :src="'https://vanke-admin.h98.net' + item[10]" alt="" />
              <div class="info_content">
                <div class="coin">
                  <span>{{ item[0] }}</span>
                </div>
                <div class="change">
                  <span>{{ item[2] }}</span>
                  <span class="color" :class="item[8] < 0 ? 'down' : ''"
                    >{{ item[8] }}%</span
                  >
                </div>
              </div>
            </div>
            <div class="price">
              <div class="value">0.000000</div>
              <div class="change">$0.000000</div>
            </div>
          </div>

          <div class="actions_content fade-in" v-show="showSwapIndex === index">
            <div class="line"></div>
            <div class="swap_action" @click="getCoinType(item[0], item[10], 1)">
              Swap
            </div>
            <div class="normal_action" @click="expandRecharge(item[0])">
              <img
                src="../../static/login/icon_coin_action1.svg"
                style="width: 24px; height: 24px"
                alt=""
              />
            </div>
            <div
              class="normal_action"
              @click="getCoinType(item[0], item[10], 3)"
            >
              <img
                src="../../static/login/icon_coin_action2.svg"
                style="width: 24px; height: 24px"
                alt=""
              />
            </div>
            <div class="normal_action" @click="showKline = true">
              <img
                src="../../static/login/icon_coin_action3.svg"
                style="width: 24px; height: 24px"
                alt=""
              />
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <van-popup v-model:show="showSortType" position="center">
      <div class="st_popup_container">
        <div class="st_popup_content">
          <div class="sort_header">{{ $t("login.Sort_Type") }}</div>
          <div class="sort_body">
            <div
              class="sort_item"
              :class="sortTypeIndex === index ? 'active' : ''"
              v-for="(item, index) in sortTypeList"
              :key="index"
              @click="changeSortType(index)"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model:show="showAddAsset"
      position="bottom"
      style="height: 85%"
    >
      <div class="aa_popup_container">
        <div class="aa_popup_content">
          <div class="aa_popup_header">
            <div class="title">
              <div data-v-e60b757e="">{{ $t("login.Add_Asset") }}</div>
              <div class="subtitle">
                {{ $t("login.Add_the_cryptocurrency") }}
              </div>
            </div>
            <img
              @click="showAddAsset = false"
              src="../../static/login/icon_close.svg"
              alt=""
              class="close"
            />
          </div>
          <div class="aa_popup_body">
            <div
              class="pro_item"
              v-for="(item, index) in coinList"
              :key="index"
            >
              <div class="coin_info">
                <img :src="'https://vanke-admin.h98.net' + item[10]" alt="" />
                <div class="info">
                  <div class="short_name">
                    <span>{{ item[0] }}</span>
                  </div>
                  <div class="name">{{ item[1] }}</div>
                </div>
              </div>
              <div class="action">
                <van-switch
                  v-model="item.check"
                  size="20px"
                  active-color="#fcd23f"
                  inactive-color="#cdcdcd"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <kline-pop
      :show="showKline"
      @onClose="showKline = false"
      :item="coinList[showSwapIndex]"
    />

    <van-popup
      v-model:show="rechargeExpand"
      position="bottom"
      style="height: 85%"
    >
      <div class="popup_container">
        <div class="popup_content">
          <div class="popup_header">
            <div class="header_top">
              <div>{{ $t("830753-0") }} {{ currencyDisplay }}</div>
              <img
                @click="rechargeExpand = false"
                src="../../static/new/icon_close.0f8cb496.svg"
                alt=""
                class="close"
              />
            </div>
            <div class="header_content">
              <div class="intro">{{ $t("830753-1") }}</div>
            </div>
          </div>
          <div class="popup_body">
            <div class="body_content">
              <div class="body_content_item">
                <div class="item-header">
                  <div class="item-header-left">
                    <!-- <img :src="'https://vanke-admin.h98.net' + imgUrl" alt=""> -->
                    <!-- <span>{{ $t('830753-0') }}</span> -->
                  </div>
                  <!-- <div class="item-header-right">
                  <span>{{coin}}</span>
                  <img data-v-02f2bd1e="" src="../../static/img/icon_arrow_down.18cfe723.svg" alt="">
                </div> -->
                </div>
                <div class="item-body">
                  <!-- <div class="item-body-top">
                    <input
                      @input="enterConvertNum"
                      placeholder="Enter receiving address..."
                      v-model="payValue"
                    />
                    <div
                      @click="payValue = null"
                      style="
                        position: absolute;
                        right: 10px;
                        top: 20px;
                        color: #eeb74c;
                      "
                    >
                      Paste
                    </div>
                  </div> -->
                  <div class="item-body-bottom">
                    <input :placeholder="$t('rechargeAmount')" v-model="nums" />
                    <!-- <div
                      @click="dollarValue = Number(topcoininfo?.y).toFixed(2)"
                      style="
                        position: absolute;
                        right: 10px;
                        top: 20px;
                        color: #eeb74c;
                      "
                    >
                      Max
                    </div> -->
                  </div>
                </div>
                <!-- <div class="item-footer">
                  <span>Balance</span>
                  <span
                    >{{ Number(topcoininfo?.y).toFixed(2) }} {{ coin }}</span
                  >
                </div> -->
              </div>
              <div
                style="
                  padding: 8px;
                  color: #6e6d6d;
                  margin-top: 10px;
                  margin-bottom: 10px;
                "
              >
                <p class="voucher">{{ $t("830753-2") }}</p>
                <van-uploader
                  :after-read="uploadImage"
                  v-model="fileList"
                  multiple
                  :max-count="1"
                />
              </div>

              <div class="confirm-btn" @click="clickToRecharge()">
                {{ $t("830753-3") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { getbanlance, kline, uploadjk, recharge } from "@/api/tab1";
import { router } from "@/router";
import KlinePop from "@/components/klinePop.vue";
import { useI18n } from "vue-i18n";
import { Uploader, showToast } from "vant";
import axios from "axios";

const { t } = useI18n();
const vantloading = ref(false);
const finished = ref(false);
const pageNo = ref(1);
const pages = ref(1);
const showSortType = ref(false);
const showAddAsset = ref(false);
const showKline = ref(false);
const showSwapIndex = ref(-1);
const coinList = ref([]);
const nums = ref();
const sortTypeList = ref([
  t("login.Balance"),
  t("login.Name"),
  t("login.Price_Change"),
]);
const sortTypeIndex = ref(1);
const balance = ref(0);
const userName = ref("");
const getBalance = () => {
  getbanlance().then((res) => {
    const { data } = res;
    balance.value = data.url;
  });
};
const getList = () => {
  kline({ id: 1, type: 0, p: pageNo.value }).then((res) => {
    const { data } = res;
    pages.value = data.info.pages;
    if (pageNo.value === 1) {
      coinList.value = data.url;
    } else {
      coinList.value = coinList.value.concat(data.url);
    }

    console.log(pages.value);
    if (pageNo.value === pages.value) {
      finished.value = true;
    }
    vantloading.value = false;
  });
};
const datasload = () => {
  if (pageNo.value < pages.value) {
    pageNo.value = pageNo.value + 1;
    getList();
  }
};

// Vue 组件状态
const imageUrl = ref("");

// 处理文件上传
const uploadImage = async (file) => {
  const formData = new FormData();
  formData.append("images", file.file);

  try {
    const response = await uploadjk(formData);
    if (response && response.data && response.data.url) {
      imageUrl.value = response.data.url;
      showToast("ok");
    } else {
      showToast("Fail");
    }
  } catch (error) {
    showToast("Fail");
  }
};

const changeSortType = (index) => {
  sortTypeIndex.value = index;
  showSortType.value = false;
};
const getCoinType = (coin, coinImg, type) => {
  if (type === 1) {
    router.push({ path: "/swapChange", query: { coin: coin } });
    localStorage.setItem("coinImg", coinImg);
  } else if (type === 3) {
    router.push({ path: "/send", query: { coin: coin } });
  }
};
const rechargeExpand = ref(false);
const currencyDisplay = ref();
const expandRecharge = (val) => {
  currencyDisplay.value = val;
  rechargeExpand.value = true;
};

const clickToRecharge = () => {
  let obj = {
    type: currencyDisplay.value,
    nums: nums.value,
    pic: imageUrl.value,
  };

  recharge(obj).then((res) => {
    const { data } = res;
    console.log(data);
    // balance.value = data.url;
  });
};
onMounted(() => {
  userName.value = localStorage.getItem("username").slice(0, 7);
  getBalance();
  getList();
});
</script>

<style scoped lang="scss">
.home {
  padding: 234.615px 15.3846px 0 15.3846px;
  color: #fff;
  font-size: 14px;
  width: 100vw;
  min-height: 100vh;
}

.top_section {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  background: #000;

  .nav_header {
    height: 43.2679px;
    padding: 19.2308px 15.3846px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo_content {
      font-weight: 700;
      font-size: 30px;

      .color {
        color: #fcd23f;
      }
    }

    .link_content {
      padding: 0 11.5385px;
      height: 34.6071px;
      line-height: 34.6071px;
      border-radius: 17.3077px;
      background: #151515;
      display: flex;
      align-items: center;

      img {
        margin-left: 7.69231px;
      }
    }
  }

  .balance_container {
    padding: 0 15.3846px;
    color: #8a8a8a;

    .value {
      font-size: 30px;
      font-weight: 500;
    }

    .change {
      font-weight: 400;
      font-size: 18px;
    }
  }

  .pro_list_header {
    padding: 15.3846px;
    margin-top: 30.7692px;
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);
    display: flex;
    justify-content: space-between;

    .label {
      display: flex;
      align-items: center;

      img {
        margin-left: 3.84615px;
      }
    }

    .action {
      width: 26.9196px;
      height: 26.9196px;
      background: hsla(0, 0%, 54.1%, 0.3);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.pro_list_container {
  width: calc(100% - 15.3846px - 15.3846px);
  position: relative;
  height: calc(100% - 234.615px);
  overflow: auto;

  .pro_item {
    margin-bottom: 7.69231px;
    padding: 11.5385px 15.3846px;
    background: #151515;
    border-radius: 15.3846px;

    .item_header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info {
        display: flex;
        align-items: center;

        img {
          width: 23.0714px;
          height: 23.0714px;
          border-radius: 50%;
        }

        .info_content {
          margin-left: 15.3846px;

          .coin {
            font-weight: 600;
            font-size: 16px;
            display: flex;
            align-items: center;
          }

          .change {
            margin-top: 3.84615px;
            color: #8a8a8a;
            font-weight: 500;
            font-size: 12px;
          }

          .down {
            color: #eb514d !important;
          }

          .color {
            margin-left: 3.84615px;
            color: #69b37d;
          }
        }
      }

      .price {
        font-weight: 600;
        font-size: 16px;
        text-align: right;

        .change {
          margin-top: 3.84615px;
          color: #8a8a8a;
          font-weight: 500;
          font-size: 12px;
        }
      }
    }
    .actions_content {
      position: relative;
      margin-top: 11.5385px;
      padding-top: 9.61539px;
      display: flex;
      justify-content: space-between;
      .line {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(
          90deg,
          rgba(227, 228, 232, 0.1),
          rgba(227, 228, 232, 0.505208) 50%,
          rgba(227, 228, 232, 0.1)
        );
      }
      .swap_action {
        flex: 1;
        height: 48.0714px;
        line-height: 48.0714px;
        text-align: center;
        background: #fcd23f;
        border-radius: 28.8462px;
        color: #0b0b0b;
        font-weight: 600;
        font-size: 16px;
      }
      .normal_action {
        margin-left: 7.69231px;
        width: 48.0714px;
        height: 48.0714px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .fade-in {
      animation: 0.5s ease-in-out forwards;
    }
  }
}

.st_popup_container {
  padding: 0 15.3846px;
  width: 100vw;
  box-sizing: border-box;

  .st_popup_content {
    background: #151515;
    border-radius: 15.3846px;
    font-size: 16px;
    font-weight: 400;

    .sort_header {
      height: 46.1518px;
      line-height: 46.1518px;
      text-align: center;
      font-weight: 500;
    }

    .sort_body {
      color: #8a8a8a;

      .sort_item {
        height: 46.1518px;
        line-height: 46.1518px;
        text-align: center;
        border-top: 1px solid hsla(0, 0%, 100%, 0.05);
      }

      .active {
        color: #fcd23f;
      }
    }
  }
}

.aa_popup_container {
  //position: absolute;
  //top: 76.9231px;
  //left: 0;
  //bottom: 0;
  width: 100%;

  .aa_popup_content {
    width: 100%;
    height: 100%;
    background: #151515;
    padding: 23.0769px 15.3846px;
    border-radius: 15.3846px 15.3846px 0 0;
    box-sizing: border-box;

    .aa_popup_header {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      padding: 23.0769px 15.3846px;
      border-radius: 15.3846px 15.3846px 0 0;
      background: #151515;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: self-start;

      .title {
        font-weight: 700;
        font-size: 30px;

        .subtitle {
          margin-top: 7.69231px;
          color: #8a8a8a;
          font-weight: 400;
          font-size: 12px;
        }
      }

      .close {
        margin-top: 9.61539px;
      }
    }

    .aa_popup_body {
      position: absolute;
      top: 92.3077px;
      left: 0;
      bottom: 0;
      width: 100%;
      overflow: auto;
      background: #151515;
      padding: 23.0769px 15.3846px;
      box-sizing: border-box;

      .pro_item {
        height: 57.6875px;
        border-top: 1px solid hsla(0, 0%, 100%, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .coin_info {
          display: flex;
          align-items: center;

          img {
            width: 23px;
            height: 23px;
          }

          .info {
            margin-left: 15.3846px;
            font-weight: 400;

            .short_name {
              color: #fff;
              font-size: 16px;
              display: flex;
              align-items: center;
            }

            .name {
              margin-top: 3.84615px;
              font-size: 12px;
              color: #8a8a8a;
            }
          }
        }
      }
    }
  }
}

:deep(.van-popup--center) {
  max-width: 100vw;
}

:deep(.van-popup) {
  background: transparent;
}
.action_btn {
  margin-top: 24px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #fcd23f;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  color: #0b0b0b;
}

/**充值 */

.container .router-view {
  height: 100% !important;
  color: #ffffff;
  font-size: 14px;
}
.popup_container {
  position: absolute;
  top: 80px;
  left: 0;
  bottom: 0;
  width: 100%;
}
.popup_container .popup_content {
  width: 100%;
  height: 100%;
  background: #151515;
  padding: 24px 16px;
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;
}
.popup_container .popup_content .popup_header {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  padding: 24px 16px 1px 16px;
  border-radius: 16px 16px 0 0;
  box-sizing: border-box;
  background: #151515;
}
.popup_container .popup_content .popup_header .header_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
}
.pup_container .popup_content .popup_header .header_content {
  margin-top: 8px;
}
.popup_container .popup_content .popup_header .header_content .intro {
  color: #8a8a8a;
  font-weight: 400;
  font-size: 12px;
}
.popup_container .popup_content .popup_body {
  //border-top: 1px solid hsla(0, 0%, 100%, .1);
  position: absolute;
  top: 98px;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: auto;
}
.popup_container .popup_content .popup_body .body_content {
  padding: 16px;
}
.body_content_item {
  border-radius: 10%;
  background: #0c0c0c;
  padding: 16px 18px 10px 18px;
  .item-header {
    display: flex;
    justify-content: space-between;
    .item-header-left {
      display: flex;
      justify-content: start;
      color: #fff;
      font-size: 16px;
      img {
        width: 23px;
        height: 23px;
        margin-right: 10px;
      }
    }
    .item-header-right {
      display: flex;
      justify-content: start;
      color: #fff;
      font-size: 16px;
      img {
        width: 23px;
        height: 23px;
        margin-right: 6px;
      }
    }
  }
  .item-body {
    border-radius: 10px;

    height: 40px;
    margin-top: 10px;
    .item-body-top {
      border-bottom: 1px solid #3b3b3b;
      position: relative;
      height: 48%;
      input {
        width: 95%;
        height: 100%;
        background: #0c0c0c;
        padding-left: 5%;
        border-radius: 10%;
      }
    }
    .item-body-bottom {
      height: 48%;
      position: relative;
      input {
        //width: 100%;
        height: 100%;
        background: #0c0c0c;
        padding-left: 10px;
      }
      .up-down {
        width: 20px;
        display: flex;
        justify-items: center;
        flex-wrap: wrap;
        position: absolute;
        right: 10px;
        top: 25%;
        background: #3b3b3b;
        text-align: center;
        justify-content: center;
      }
    }
  }
  .item-footer {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #a9a8a8;
    margin-top: 10px;
  }
}
.body_content_item2 {
  border-radius: 10%;
  background: #0c0c0c;
  padding: 16px 18px 10px 18px;
  .item-header {
    display: flex;
    justify-content: space-between;
    .item-header-left {
      display: flex;
      justify-content: start;
      color: #fff;
      font-size: 16px;
      img {
        width: 23px;
        height: 23px;
        margin-right: 10px;
      }
    }
    .item-header-right {
      display: flex;
      justify-content: start;
      color: #fff;
      font-size: 16px;
      img {
        width: 23px;
        height: 23px;
        margin-right: 6px;
      }
    }
  }
  .item-body {
    border-radius: 10px;
    border: 1px solid #3b3b3b;

    margin-top: 10px;
    .item-body-top {
      border-bottom: 1px solid #3b3b3b;
      position: relative;
      height: 48%;
      input {
        width: 95%;
        height: 100%;
        background: #0c0c0c;
        padding-left: 5%;
        border-radius: 10%;
      }
      .up-down {
        width: 20px;
        display: flex;
        justify-items: center;
        flex-wrap: wrap;
        position: absolute;
        right: 10px;
        top: 25%;
        background: #3b3b3b;
        text-align: center;
        justify-content: center;
      }
    }
    .item-body-bottom {
      height: 48%;
      input {
        //width: 100%;
        height: 100%;
        background: #0c0c0c;
        padding-left: 10px;
      }
    }
  }
  .item-footer {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #a9a8a8;
    margin-top: 10px;
  }
}
.change-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    width: 40px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.confirm-btn {
  border-radius: 20px;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  line-height: 50px;
  background-color: #eeb74c;
  font-size: 18px;
  color: #0c0c0c;
  margin-top: 10px;
  font-weight: 600;
}
.voucher {
  margin-bottom: 15px;
}
</style>
